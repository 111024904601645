import { useMutation } from '@apollo/client'
import { darkColors, lightColors } from '@chaine/keychaine'
import React, { useEffect } from 'react'

import { tokenService } from '../auth/data'
import { UPDATE_USER } from '../auth/data/queries-mutations'
import { useAuth } from '../auth/data/use-auth'
import { createGenericContext } from './create-generic-context'

/**
 * Context to manage the state of multiple color theme objects
 */
type IColorModeContext = {
  changeColorMode: () => void
  colorMode: string
  currentThemeObject: Record<string, string | Record<string, string>>
  useMode: (lightToken: string, darkToken: string) => string
}

/**
 * Generate the custom color context
 */
const [useColorModeContext, ColorModeContextProvider] = createGenericContext<IColorModeContext>(
  'useColorModeContext must be used within the ColorModeContextProvider'
)

interface ColorModeProviderProps {
  children: React.ReactNode
}

/**
 * Color mode provider to manage the color mode application state as well as multiple color theme objects
 */
const ColorModeProvider: React.FC<ColorModeProviderProps> = ({ children }) => {
  const localStorageColorMode = tokenService.getColorMode()
  const { userDetails, refetchUserDetails } = useAuth()

  //set and access the color mode
  const [colorMode, setColorMode] = React.useState<'light' | 'dark'>('light')
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      refetchUserDetails()
    }
  })

  //can be used similar to chakra's "useColorModeValue", where you pass in a light theme color and a dark theme color and the correct value is returned to your component.  this function is not currently in use but is here for future use, particularly if we decide to manage multiple themes or need to remove chakra's management of mode
  const returnColorToken = (lightToken: string, darkToken: string) => {
    return colorMode === 'light' ? lightToken : darkToken
  }

  //determines the correct color mode upon the application loading
  useEffect(() => {
    //first, prioritize the color mode set in local storage
    if (localStorageColorMode) {
      setColorMode(localStorageColorMode as 'light' | 'dark')
    }
    //if that is not available, use the color mode returned from the user details query
    else if (userDetails?.colorModePreference) {
      setColorMode(userDetails.colorModePreference as 'light' | 'dark')
      tokenService.setColorMode(userDetails.colorModePreference)
    }
    //if that is not available, use the color mode set in the user's browser (the system's color mode)
    else {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      setColorMode(mediaQuery.matches ? 'dark' : 'light')
      tokenService.setColorMode(mediaQuery.matches ? 'dark' : 'light')
    }
    //ignoring lint warning as I only want this use effect to run once on first renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //if the user changes their color mode preference, update the color mode value in the state as well as local storage
  useEffect(() => {
    if (userDetails?.colorModePreference) {
      setColorMode(userDetails.colorModePreference as 'light' | 'dark')
      tokenService.setColorMode(userDetails.colorModePreference)
    }
  }, [userDetails?.colorModePreference])

  //the available color theme objects.  more themes can be added later, but we will have to remove our reliance on chakra's color mode management
  const themes = {
    dark: darkColors,
    light: lightColors
  }

  //determines the appropriate theme object to use based on the current color mode
  const currentTheme = themes[colorMode]

  //function to change the color mode.  if the user is logged in, this will also update the user's color mode preference in the database
  function changeColorMode() {
    const newColorMode = colorMode === 'light' ? 'dark' : 'light'
    if (userDetails && userDetails.status === 'Active') {
      const input = { colorModePreference: newColorMode, name: userDetails?.name }
      updateUser({ variables: input })
    }
    tokenService.setColorMode(newColorMode)
    setColorMode(newColorMode)
  }

  return (
    <ColorModeContextProvider
      value={{ changeColorMode, colorMode, currentThemeObject: currentTheme, useMode: returnColorToken }}
    >
      {children}
    </ColorModeContextProvider>
  )
}

export { ColorModeProvider, useColorModeContext }
