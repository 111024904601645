import { BellIcon, Box, Circle } from '@chaine/keychaine'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../../../_routes/routes-list'
import { useChat } from '../../../../chat/context/chat-context'

/**
 * Displays the bell icon to show unread notification
 */
export const NotificationButton = memo(() => {
  const { unreadCount } = useChat()

  return (
    <Link aria-label="inbox-link" to={ROUTES.INBOX}>
      <Box
        as="button"
        role={'button'}
        aria-label="notification button"
        position={'relative'}
        color={'gray.100'}
        _hover={{ cursor: 'pointer' }}
      >
        <BellIcon aria-label="bell-icon" />

        {unreadCount > 0 && (
          <Circle
            aria-label="dot-icon"
            role={'icon'}
            position="absolute"
            top={'-2px'}
            left={2}
            size={3.5}
            bg={'blue.500'}
            borderWidth={2}
            borderColor={'gray.800'}
            borderStyle={'solid'}
          />
        )}
      </Box>
    </Link>
  )
})

NotificationButton.displayName = 'NotificationButton'
