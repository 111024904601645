import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, Button, FormLabel, HStack, Radio, RadioGroup, Tag, VStack } from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'

import { Toast } from '../../components/toast'
import {
  INITIATE_WORKSPACE_VERIFICATION,
  InitiateWorkspaceVerificationInput,
  InitiateWorkspaceVerificationReturnType
} from './data/mutations'
import {
  CompanyReturnType,
  CompanyVerificationMethodsInput,
  CompanyVerificationMethodsReturnType,
  GET_COMPANY_VERIFICATION_METHODS
} from './data/queries'
import { CompanyIdentifierType, CompanyType, ModalStepProps } from './types'
import { Wrapper } from './wrapper'

interface CompanyConfirmationProps extends ModalStepProps {
  /**
   * The type of identifier the company wants to use to verify (MC or DOT)
   */
  company: CompanyReturnType | null
  /**
   * DOT or MC of type {@link CompanyIdentifierType}
   */
  companyIdentifierType: CompanyIdentifierType

  /**
   * The value of the company's DOT or MC
   */
  companyIdentifierValue: string
  /**
   * The type of the company wants to use to verify (MC or DOT)
   */
  companyType: CompanyType

  /**
   * Sets the MC or DOT number
   */
  setAllowableVerificationMethods: (allowableMethods: CompanyVerificationMethodsReturnType) => void
  /**
   * Sets the company type
   */
  setCompanyType: (companyType: CompanyType) => void

  /**
   * Sets the step of the modal
   */
  setStep: (step: number) => void

  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * The user confirms their company
 */
const UnmemoizedCompanyConfirmation = (props: CompanyConfirmationProps) => {
  const {
    step,
    setStep,
    handleNext,
    company,
    setAllowableVerificationMethods,
    workspaceID,
    companyIdentifierType,
    companyIdentifierValue,
    previousStepOrSkipStep,
    companyType,
    setCompanyType
  } = props
  const { showToast } = Toast()

  /**
   * Get the company verification methods. This will be called if the workspace is not available into the chaine system
   */
  const [getCompanyVerificationMethods, { loading: gettingCompanyVerificationMethods }] = useLazyQuery<
    { companyVerificationMethods: CompanyVerificationMethodsReturnType },
    CompanyVerificationMethodsInput
  >(GET_COMPANY_VERIFICATION_METHODS, {
    onCompleted: ({ companyVerificationMethods }) => {
      setAllowableVerificationMethods(companyVerificationMethods)
      handleNext()
    },
    onError: (error) => {
      showToast({
        status: 'error',
        title: error.message
      })
    }
  })

  /**
   * This mutation initiates workspace verification. We then validate if the user is auto-verified. If auto-verified, the user is automatically create a workspace and add as a member of the workspace through an internal process.
   */
  const [initiateWorkspaceVerification, { loading: initiatingWorkspaceVerification }] = useMutation<
    { initiateWorkspaceVerification: InitiateWorkspaceVerificationReturnType },
    InitiateWorkspaceVerificationInput
  >(INITIATE_WORKSPACE_VERIFICATION, {
    onCompleted: (res) => {
      if (res.initiateWorkspaceVerification?.isVerified) {
        /**
         * If the user is automatically validated, they will go to the final step.
         */
        setStep(10)
      } else {
        /**
         * If the user is not auto-verified, we get the company verification methods list and move to the next step
         */
        const input = {
          companyIdentifier: {
            type: companyIdentifierType,
            value: companyIdentifierValue
          },
          workspaceID: workspaceID
        }

        getCompanyVerificationMethods({
          variables: input
        })
      }
    },
    onError: (error) => {
      showToast({
        status: 'error',
        title: error.message
      })
    }
  })

  const onSubmit = () => {
    const input = {
      companyIdentifier: {
        type: companyIdentifierType,
        value: companyIdentifierValue
      },
      workspaceID: workspaceID
    }

    initiateWorkspaceVerification({
      variables: input
    })
  }

  const [selectionEnabled, setSelectionEnabled] = useState(false)

  useEffect(() => {
    if (company) {
      const isCarrier =
        company.allowedToOperate &&
        (company.commonAuthorityStatus === 'Active' || company.contractAuthorityStatus === 'Active')

      const isBroker = company.authorizedForBroker && company.brokerAuthorityStatus === 'Active'

      setCompanyType(isBroker ? CompanyType.Broker : CompanyType.Carrier)

      if (isCarrier && isBroker) {
        setSelectionEnabled(true)
      }
    }
  }, [company, setCompanyType])

  return (
    <>
      <Wrapper
        title={'We found a company that matches!'}
        activeStep={step}
        backButtonProps={{ displayBackArrow: true, onSubmit: () => previousStepOrSkipStep(3), text: 'No, go back' }}
      >
        <FormLabel mt={4} htmlFor="dotOrMC">
          Is this your company?
        </FormLabel>
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Box p={4}>
            <Box display="flex" alignItems="baseline">
              <HStack>
                {company?.allowedToOperate ? (
                  <Tag colorScheme="green" size={{ base: 'xs', lg: 'sm' }}>
                    Active
                  </Tag>
                ) : (
                  <Tag colorScheme="red" size={{ base: 'xs', lg: 'sm' }}>
                    Inactive
                  </Tag>
                )}
                {company?.authorizedForProperty && (
                  <Tag colorScheme="teal" size={{ base: 'xs', lg: 'sm' }}>
                    Property
                  </Tag>
                )}
                {company?.authorizedForBroker && company.brokerAuthorityStatus === 'Active' && (
                  <Tag colorScheme="purple" size={{ base: 'xs', lg: 'sm' }}>
                    Broker
                  </Tag>
                )}

                {company?.allowedToOperate &&
                  (company.commonAuthorityStatus === 'Active' || company.contractAuthorityStatus === 'Active') && (
                    <Tag colorScheme="blue" size={{ base: 'xs', lg: 'sm' }}>
                      Carrier
                    </Tag>
                  )}
              </HStack>
            </Box>
            <Box mt={2} fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
              {company?.companyName}
            </Box>
            <Box mt={1}>
              {company?.physicalAddress?.city}, {company?.physicalAddress?.state}
            </Box>
          </Box>
        </Box>

        {selectionEnabled && (
          <>
            <FormLabel mt={4} htmlFor="dotOrMC">
              What kind of workspace will this be
            </FormLabel>
            <RadioGroup value={companyType} onChange={setCompanyType} id="brokerOrCarrier" name="brokerOrCarrier">
              <VStack width="100%" alignItems="flex-start">
                <Radio value={CompanyType.Broker}>Broker</Radio>
                <Radio value={CompanyType.Carrier}>Carrier</Radio>
              </VStack>
            </RadioGroup>
          </>
        )}

        <Button
          width="100%"
          onClick={onSubmit}
          mt={4}
          variant="primary"
          isLoading={gettingCompanyVerificationMethods || initiatingWorkspaceVerification}
          loadingText="Hang tight"
        >
          Yes
        </Button>
      </Wrapper>
    </>
  )
}

export const CompanyConfirmation = React.memo(UnmemoizedCompanyConfirmation)
