import { BrowserNotificationService } from './browser-notification'
import { ChatClientService } from './chat-client'
import { searchClient } from './search-client'
import { TokenService } from './token-service'

/* Token service */
const tokenService = new TokenService()

/* Browser notification service */
const browserNotificationService = new BrowserNotificationService()

/* Chat client service */
const chatClientService = new ChatClientService(tokenService, browserNotificationService)

export { browserNotificationService, chatClientService, searchClient, tokenService }
