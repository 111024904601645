import { Alert as AlertKey } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AlertDescription, AlertIcon, AlertTitle, Box, Center, CloseButton, Icon, useColorModeValue as mode } from '../..';
export { AlertDescription, AlertTitle } from '@chakra-ui/react';
export { AlertKey };
export function BaseAlert({ ...props }) {
    return (React.createElement(AlertKey, { ...props, borderRadius: props?.borderRadius || 8, width: props?.width || 'fit-content', position: props?.position || 'absolute' }));
}
export const Alert = (props) => {
    const { title, alertIcon, closeButton, centered = true, status = 'info', autoFade = true, icon, ...rest } = props;
    const [description, setDescription] = useState(props.description);
    useEffect(() => {
        setDescription(props.description);
    }, [props.description]);
    useEffect(() => {
        if (autoFade) {
            const timer = setTimeout(() => {
                setDescription('');
            }, 10000);
            return () => clearTimeout(timer);
        }
        else
            return;
    }, [autoFade, description]);
    const isValidVariant = ['success', 'warning', 'info', 'error'].includes(status);
    const variant = isValidVariant ? status : 'info';
    const closeButtonColor = {
        success: mode('green.600', 'rgba(90, 213, 121, 0.75)'),
        warning: mode('yellow.600', 'rgba(255, 217, 51, 0.75)'),
        info: mode('blue.600', 'rgba(51, 148, 255, 0.75)'),
        error: mode('red.600', 'rgba(255, 95, 85, 0.75)')
    };
    const alertCard = centered ? (React.createElement(Center, null,
        React.createElement(AlertKey, { variant: variant, ...rest },
            alertIcon && React.createElement(AlertIcon, null),
            icon && React.createElement(Icon, { as: icon }),
            React.createElement(Box, null,
                title ? React.createElement(AlertTitle, null, title) : React.createElement(React.Fragment, null),
                React.createElement(AlertDescription, { display: "block" }, description)),
            closeButton && (React.createElement(CloseButton, { onClick: () => setDescription(''), size: 'sm', color: closeButtonColor[variant], right: 2, ml: 10 }))))) : (React.createElement(AlertKey, { variant: variant, ...rest },
        alertIcon && React.createElement(AlertIcon, null),
        icon && React.createElement(Icon, { as: icon }),
        React.createElement(AlertTitle, null, title),
        React.createElement(AlertDescription, null, description),
        closeButton && (React.createElement(CloseButton, { onClick: () => setDescription(''), size: 'sm', color: closeButtonColor[variant], right: 2, ml: 10 }))));
    return description ? React.createElement(React.Fragment, null, alertCard) : null;
};
