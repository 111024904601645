import { useEffect, useRef } from 'react'

export function DocumentTitle(title: string, prevailOnUnmount = false) {
  let defaultTitle = useRef('')

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    defaultTitle = useRef(document.title)
  }

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current
      }
    },
    // we have added the below comment to by pass Eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export default DocumentTitle
