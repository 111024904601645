import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { useAuth } from '../../auth/data/use-auth'
import { ROUTES } from '../routes-list'

/**
 *
 * RequireWorkspaceValidation - use this function to wrap private routes specific to workspaces to validate the workspace.
 * @returns children if a valid workspace, Home page if not a valid workspace
 *
 * By valid workspace, here it means the workspace name that is present in the URL to access details of a particular workspace belongs to the user and the user is an active member of that workspace
 */
export function RequireWorkspaceValidation({ children }: { children: JSX.Element }) {
  const { onValidWorkspace } = useAuth()
  const location = useLocation()
  const { workspace_name: workspacename } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isValidWorkspace, setIsValidWorkspace] = useState<boolean>(false)

  const validateWorkspace = async () => {
    if (workspacename) {
      const isWorkspaceValid = await onValidWorkspace(workspacename)
      setIsValidWorkspace(isWorkspaceValid)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    validateWorkspace()
    // we have added the below comment to by pass Eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return null

  return isValidWorkspace ? children : <Navigate to={ROUTES.HOME} replace state={{ path: location.pathname }} />
}
