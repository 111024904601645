import { alertBaseStyle, alertErrorStyle, alertSuccessStyle, alertWarningStyle } from '../../feedback/alert/styles';
const baseStyle = (props) => ({
    ...alertBaseStyle(props)
});
export const Alert = {
    parts: ['container', 'title', 'icon', 'description'],
    baseStyle,
    variants: {
        base: alertBaseStyle,
        error: alertErrorStyle,
        warning: alertWarningStyle,
        info: alertBaseStyle,
        success: alertSuccessStyle
    },
    defaultProps: {
        variant: 'base'
    }
};
