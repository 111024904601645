import { HTMLKeychaineProps, keychaine, useColorModeValue, useToken } from '@chaine/keychaine'
import * as React from 'react'

interface Props extends HTMLKeychaineProps<'svg'> {
  color?: string
  size?: 'sm' | 'md'
}

/**
 *
 * @param props {@link Props size, color}
 * @returns
 */
export const Logo = ({ size = 'sm', color }: Props) => {
  const [white, black] = useToken('colors', ['white', 'gray.800'])
  const logoColor = useColorModeValue(black, white)
  return (
    <keychaine.svg
      width={size === 'sm' ? 6 : 10}
      height={size === 'sm' ? 6 : 10}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2110_2015)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.1623 6.3522C0.178812 7.04085 -0.0602053 8.39638 0.628441 9.37987L6.11483 17.2152C6.80347 18.1987 8.15901 18.4377 9.1425 17.7491L10.6478 16.6951L7.58553 12.3218C6.89689 11.3383 7.1359 9.98274 8.11939 9.29409L11.6003 6.85676L9.17612 3.39472C8.48747 2.41123 7.13194 2.17222 6.14845 2.86086L1.1623 6.3522Z"
            fill={color || logoColor}
          />
          <rect
            x="8.07587"
            y="10.9758"
            width="10.4348"
            height="13.913"
            rx="2.17391"
            transform="rotate(-35 8.07587 10.9758)"
            fill={color || logoColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_2110_2015">
            <rect width="24" height="24" fill={color || logoColor} />
          </clipPath>
        </defs>
      </svg>
    </keychaine.svg>
  )
}

export const MediumLogo = (props: HTMLKeychaineProps<'svg'>) => {
  const [white, black] = useToken('colors', ['white', 'gray.800'])
  const logoColor = useColorModeValue(black, white)
  return (
    <keychaine.svg viewBox="0 0 43 43" {...props} color={props.color || logoColor} w="1.5rem" h="1.5rem">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11994 14.179C5.79838 15.1044 5.4772 16.9259 6.40257 18.2474L13.7749 28.7762C14.7003 30.0978 16.5218 30.419 17.8433 29.4936L19.8671 28.0765L15.7511 22.1982C14.8257 20.8766 15.1469 19.0551 16.4685 18.1298L21.1448 14.8554L17.8885 10.2049C16.9631 8.88334 15.1416 8.56216 13.8201 9.48753L7.11994 14.179Z"
        fill="currentColor"
      />
      <path
        d="M17.9011 22.6839C16.9758 21.3624 17.2969 19.5409 18.6185 18.6155L25.3186 13.924C26.6402 12.9986 28.4617 13.3198 29.3871 14.6414L36.7594 25.1702C37.6848 26.4917 37.3636 28.3132 36.042 29.2386L29.3419 33.9301C28.0203 34.8554 26.1988 34.5343 25.2735 33.2127L17.9011 22.6839Z"
        fill="currentColor"
      />
    </keychaine.svg>
  )
}
