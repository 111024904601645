import { PageSpinner } from '@chaine/keychaine'
import React, { lazy, Suspense } from 'react'

import { MainWebsiteContainer } from '../../_shared/layouts/application/main-container/website-layout'
import { RequireAuthWebsite } from '../hoc'
import { lazyRetry } from '../lazy-retry'

const Company = lazy(() => lazyRetry(() => import('../../website/company'), 'Company'))
const Home = lazy(() => lazyRetry(() => import('../../website/home'), 'Home'))
const Integrations = lazy(() => lazyRetry(() => import('../../website/integrations'), 'Integrations'))
const PrivacyPolicy = lazy(() => lazyRetry(() => import('../../website/privacy'), 'PrivacyPolicy'))
const TalkToSales = lazy(() => lazyRetry(() => import('../../website/talk-to-sales'), 'TalkToSales'))
const Terms = lazy(() => lazyRetry(() => import('../../website/terms'), 'Terms'))
const Testimonials = lazy(() => lazyRetry(() => import('../../website/testimonials'), 'Testimonials'))
const CarrierVisibilityOnboarding = lazy(() =>
  lazyRetry(() => import('../../website/carrier-visibility-onboarding'), 'CarrierVisibilityOnboarding')
)

const Resources = lazy(() => lazyRetry(() => import('../../website/resources'), 'Resources'))

const CustomerStories = lazy(() => lazyRetry(() => import('../../website/customer-stories'), 'CustomerStories'))

export const websiteRoutes = (toggleColorMode: () => void) => {
  return [
    {
      children: [
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <Home />
            </Suspense>
          ),
          index: true
        },
        { element: <Company />, path: '/company' },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <CarrierVisibilityOnboarding />
            </Suspense>
          ),
          path: '/carrier-visibility-onboarding'
        },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <Integrations />
            </Suspense>
          ),
          path: '/product/integrations'
        },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <PrivacyPolicy />
            </Suspense>
          ),
          path: '/privacy'
        },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <TalkToSales />
            </Suspense>
          ),
          path: '/talk-to-sales'
        },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <Resources />
            </Suspense>
          ),
          path: '/resources'
        },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <CustomerStories />
            </Suspense>
          ),
          path: '/resources/customer-stories'
        },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <Testimonials />
            </Suspense>
          ),
          path: '/resources/testimonials'
        },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <Terms />
            </Suspense>
          ),
          path: '/terms'
        }
      ],
      element: (
        <RequireAuthWebsite>
          <MainWebsiteContainer toggleColorMode={toggleColorMode} />
        </RequireAuthWebsite>
      ),
      path: '/'
    }
  ]
}
