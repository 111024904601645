import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { CompanyType } from '../../_shared/components/setup-workspace/types'
import { useAuth } from '../../auth/data/use-auth'
import { ROUTES } from '../routes-list'

/**
 * This route is used for broker-only routes.
 */
export function BrokerRoute({ children }: { children: JSX.Element }) {
  const { selectedWorkspace } = useAuth()
  const location = useLocation()
  const isBroker = selectedWorkspace?.workspace.workspaceType === CompanyType.Broker

  return isBroker ? (
    children
  ) : (
    <Navigate to={ROUTES.ASSIGNED_TO_ME} replace state={{ path: location.pathname, search: location.search }} />
  )
}
