import React, { useCallback, useEffect } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import { useAuth } from '../../auth/data/use-auth'
import { ROUTES } from '../routes-list'

/**
 *
 * RequireAuth - use this function to wrap private routes
 * @returns children if authenticated, login page if not authenticated
 */
/**
 *
 * @TODO implement logic in use auth to remember the location a user was trying to navigate to
 */
export function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated, userDetails, loadingUserDetails } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  //navigates a user to the onboarding page if their status is not "Active"
  useEffect(() => {
    if (isAuthenticated && userDetails && userDetails.status !== 'Active' && !params?.handle && !loadingUserDetails) {
      navigate(ROUTES.ONBOARDING)
    }
    // we have added the below comment to by pass Eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails])

  /**
   * Returns true if authenticated or the url params contain handle, otherwise it returns false
   */
  const shouldReturnChildren = useCallback(() => {
    if (!isAuthenticated) {
      return false
    }
    return true
  }, [isAuthenticated])

  return shouldReturnChildren() ? (
    <>{children}</>
  ) : (
    <Navigate to={ROUTES.LOGIN} replace state={{ path: location.pathname, search: location.search }} />
  )
}
