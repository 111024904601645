import { Alert, HStack, Link, PinInput, PinInputField, Spinner, VStack } from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'

interface Props {
  /**
   * The email address or phone number that was entered for verification
   */
  emailOrPhone: string
  /**
   * The message to display on the alert component in case of an error
   */
  errorMessage: string
  /**
   * Component will display a spinner when loading is true
   */
  loading: boolean
  /**
   * Callback to use when user clicks to resend the verification code
   */
  onResendCode: ({ emailOrPhone, countryCode }: { countryCode: string; emailOrPhone: string }) => Promise<void>
  /**
   * Callback to use upon successful code verification
   *
   * @returns the object {error: boolean} which is needed to reset the input field
   */
  onSubmit: (pinInputValue: string) => Promise<{ error: boolean }> | { error: boolean }

  /**
   * Optional styling prop to set the pin input size
   */
  size?: string
}

/**
 * Verifies a 6 digit code
 *
 * @param props refer to the {@link Props input props}
 */
export const CodeVerificationInput = (props: Props) => {
  const { onSubmit, loading, errorMessage, emailOrPhone, onResendCode, size } = props

  const LENGTH = 6
  const [pinInputValue, setPinInputValue] = useState('')
  const [focusKey, setfocusKey] = useState(7546384315)
  const [isResending, setIsResending] = useState(false)
  const isWatchSMS = React.useRef<boolean>(true)
  const timerRef = React.useRef<number>(0)
  const codeRef = React.useRef<Array<string>>([])

  useEffect(() => {
    async function submit() {
      if (pinInputValue.length < LENGTH) {
        return
      }
      const { error } = await onSubmit(pinInputValue)
      if (error) {
        setPinInputValue('')
        setfocusKey(focusKey + 1)
      }
    }
    submit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinInputValue])

  /** Resends the code to the user */
  const resendCode = async () => {
    setIsResending(true)
    let cleanedEmailPhone = emailOrPhone
    if (!cleanedEmailPhone?.includes('@')) {
      cleanedEmailPhone = cleanedEmailPhone?.slice(2)
    }

    await onResendCode({ countryCode: '+1', emailOrPhone: cleanedEmailPhone })
    setIsResending(false)
  }

  return (
    <>
      <Alert py={6} status="error" description={errorMessage} closeButton alertIcon />

      <HStack justifyContent="center">
        <PinInput
          key={focusKey}
          size={size || 'lg'}
          autoFocus={true}
          otp
          value={pinInputValue}
          onChange={(code: string) => {
            if (!isWatchSMS.current) {
              setPinInputValue(code)
              return
            }

            // Compatible other browser input, like WeChat or Chrome.
            if (code.length === LENGTH) {
              setPinInputValue(code)
              isWatchSMS.current = false
              return
            }
            // Check is Safari autocomplete or not.
            clearTimeout(timerRef.current)
            timerRef.current = window.setTimeout(() => {
              setPinInputValue(code)
              isWatchSMS.current = false
            }, 100)
            // Compatible safari sms autocomplete.
            codeRef.current.push(code)
            if (codeRef.current.length === LENGTH) {
              clearTimeout(timerRef.current)
              setPinInputValue(codeRef.current.join(''))
              codeRef.current = []
              isWatchSMS.current = false
            }
          }}
          isDisabled={loading}
        >
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <VStack fontSize="sm" mt={5}>
        {loading && <Spinner />}
        <Link onClick={resendCode} fontWeight="semibold">
          {isResending ? 'Sending...' : 'Resend Code'}
        </Link>
      </VStack>
    </>
  )
}
