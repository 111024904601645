import { Box, Flex, useBreakpointValue, useColorModeValue as mode } from '@chaine/keychaine'
import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'

import { useAuth } from '../../auth/data/use-auth'
import { SideMenu } from './../components/side-menu'

/**
 * WorkspacePageLayout sets the layout for the workspace page.
 */
export const WorkspacePageLayout = memo(() => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false })
  const { isAuthenticated, userDetails } = useAuth()

  return (
    <Flex h="full" w="full" justifyContent="center" alignSelf="center">
      {isAuthenticated && !isMobile && userDetails?.status === 'Active' && <SideMenu />}
      <Flex alignItems="center" direction="column" h="full" overflowY="scroll" position="relative" w="full">
        <Box w="full" position="absolute" bg={mode('blue.400', 'blue.600')} h={{ base: 16, lg: 24 }}></Box>
        <Box w={{ base: 'full', xl: '7xl' }} mr="auto" ml="auto" px={{ base: 2, md: 8 }}>
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  )
})

WorkspacePageLayout.displayName = 'WorkspacePageLayout'
