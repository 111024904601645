import { ApolloClient, createHttpLink, FetchResult, fromPromise, gql } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import Observable from 'zen-observable'

import config from '../../_server/config'
import { tokenService } from '../../auth/data'
import { cache } from './cache'

// const config = (window as any).__CONFIG__ as Config

interface ErrorResponse {
  body: {
    message: string
  }
}

/**
 * Graphql Mutation - Regenerate token
 * @param refreshToken: String value which client getting from InitiateAuthOrSignup step
 */
export const REGENERATE_TOKEN = gql`
  mutation RegenerateToken($refreshToken: String!) {
    regenerateToken(input: { refreshToken: $refreshToken }) {
      accessToken
    }
  }
`

// for handling multiple failed requests Only refresh the token once.,
//Keep track of the remaining failed requests and retry them once the token is refreshed.
let isRefreshing = false
let pendingRequests: ((value?: unknown) => void)[] = []

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value
}

const resetPendingRequests = () => {
  pendingRequests = []
}

const addPendingRequest = (resolve: (value?: unknown) => void) => {
  pendingRequests.push(() => resolve())
}

const renewTokenApiClient = new ApolloClient({
  cache,
  credentials: 'include',
  link: createHttpLink({ uri: config.app.GRAPHQL_URL })
})

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  resetPendingRequests()
}

const getNewToken = async (): Promise<string> => {
  const refreshToken = tokenService.getToken('Refresh-Token') || ''
  const { data } = await renewTokenApiClient.mutate({
    mutation: REGENERATE_TOKEN,
    variables: { refreshToken: refreshToken }
  })
  return data.regenerateToken.accessToken
}

const logOut = () => {
  tokenService.clearLocalStore()
  window.location.replace(`${window.location.origin}/login`)
}

/**
 * errorLink link is provided by apollo client to handle and customize the gql api response
 * refer to the {@link https://www.apollographql.com/docs/react/api/link/apollo-link-error/}
 * @param networkError: A network error that occurred while attempting to execute the operation, if any.
 * @param graphQLErrors: An array of GraphQL errors that occurred while executing the operation, if any.
 * @param operation: The details of the GraphQL operation that produced an error.
 * @param forward: A function that calls the next link down the chain. Calling return forward(operation) in your onError callback retries the operation, returning a new observable for the upstream link to subscribe to.
 */
const errorLink = onError(({ networkError, graphQLErrors, operation, forward }): void | Observable<FetchResult> => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions && err.extensions.response) {
        const { body } = err.extensions.response as ErrorResponse

        if (body.message === 'Token Expired') {
          let forward$
          if (!isRefreshing) {
            setIsRefreshing(true)

            /**
             * when two or more requests are failed concurrently refer to {@link https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8}
             */
            forward$ = fromPromise(
              getNewToken()
                .then((token: string) => {
                  tokenService.setToken('Access-Token', token)
                  resolvePendingRequests()
                  return token
                })
                .catch(() => {
                  resetPendingRequests()
                  logOut()
                })
                .finally(() => {
                  setIsRefreshing(false)
                })
            ).filter((value) => Boolean(value))
          } else {
            // Will only emit once the above Promise is resolved
            forward$ = fromPromise(
              new Promise((resolve) => {
                addPendingRequest(resolve)
              })
            )
          }

          return forward$.flatMap(() => forward(operation))
        } else if (body.message === 'Invalid Token') {
          logOut()
        }
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})

export default errorLink
