import { modalBaseStyle, modalBodyBaseStyle, modalCloseButtonBaseStyle, modalContentBaseStyle, modalHeaderBaseStyle, modalOverlayBaseStyle } from '../../overlay';
const baseStyle = (props) => ({
    overlay: modalOverlayBaseStyle(props),
    dialogContainer: modalBaseStyle(props),
    dialog: modalContentBaseStyle(props),
    header: modalHeaderBaseStyle(props),
    closeButton: modalCloseButtonBaseStyle(props),
    body: modalBodyBaseStyle(props)
});
export const Modal = {
    parts: ['overlay', 'header', 'closeButton', 'body', 'dialog', 'dialogContainer'],
    baseStyle
};
