import { gql } from '@apollo/client'

import { MembershipAccessType, MembershipStatus } from '../../../_shared/types/membership-types'

interface IWorkspaceUser {
  /**
   * A members's access type (e.g. Member, Admin, Owner)
   */
  accessType: MembershipAccessType

  user: {
    /**
     *  The phone number's country code
     */
    countryCode?: string
    /**
     *  The member's email
     */
    email?: string
    /**
     *  The member's UUID
     */
    id: string
    /**
     *  The member's name
     */
    name?: string
    /**
     *  The member's phone number
     */
    phone?: string
    /**
     *  The member's photo
     */
    photo?: string
    /**
     *  The user's username
     */
    username: string
  }

  /**
   * The member's userID
   */
  userID: string

  /**
   * The workspace's UUID
   */
  workspaceID: string
}

export interface IWorkspaceMember extends IWorkspaceUser {
  /**
   *  A members's status (e.g. 'Active' or 'Inactive')
   */
  status: MembershipStatus
}

export interface IWorkspaceInvitedUsers extends IWorkspaceUser {
  /**
   * The expiration date of the invitation
   */
  expirationDate: number
}

/**
 * Query to get the members of the workspace
 */
export const GET_WORKSPACE_MEMBERS = gql`
  query {
    workspaceMembers {
      userID
      workspaceID
      accessType
      status
      user {
        id
        name
        photo
        email
        phone
        countryCode
      }
    }
  }
`

/**
 * Query to get the members of the workspace
 */
export const GET_PUBLIC_WORKSPACE_MEMBERS = gql`
  query {
    publicWorkspaceMembers {
      userID
      workspaceID
      accessType
      status
      user {
        id
        name
        photo
        email
        phone
        countryCode
      }
    }
  }
`
