export type TokenType = 'Access-Token' | 'Refresh-Token' | 'User-Chat-Token'
export type JWTToken = string

export type RefreshToken = string

export interface ITokenService {
  clearLocalStore(): void
  getColorMode(): string | null
  getToken(tokenType: TokenType): JWTToken | RefreshToken | null
  getUserID(): string | null
  isAuthenticated(): boolean
  removeToken(tokenType: TokenType): void
  setColorMode(mode: string): void
  setToken(tokenType: TokenType, token: JWTToken | RefreshToken): void
}

export class TokenService implements ITokenService {
  public static accessTokenName = 'chaine-access-token'

  public static refreshTokenName = 'chaine-refresh-token'

  public static userChatTokenName = 'chaine-user-chat-token'

  public static tokenIDName = 'chaine-token-id'

  public accessToken: JWTToken | null

  public refreshToken: RefreshToken | null

  public tokenID: JWTToken | null

  constructor() {
    this.accessToken = this.getToken('Access-Token')
    this.refreshToken = this.getToken('Refresh-Token')
  }

  clearLocalStore() {
    localStorage.clear()
  }

  setColorMode(mode: string) {
    localStorage.setItem('color-mode', mode)
    localStorage.setItem('chakra-ui-color-mode', mode)
  }

  getColorMode() {
    return localStorage.getItem('color-mode')
  }

  isAuthenticated(): boolean {
    return this.getToken('Access-Token') !== null
  }

  getToken(tokenType: TokenType): JWTToken | RefreshToken | null {
    const tokenName: string = this.getTokenName(tokenType)
    return this.getKey(tokenName)
  }

  setToken(tokenType: TokenType, token: JWTToken | RefreshToken): void {
    const tokenName: string = this.getTokenName(tokenType)
    this.setKeyValue(tokenName, token)
  }

  removeToken(tokenType: TokenType): void {
    const tokenName: string = this.getTokenName(tokenType)
    this.removeKey(tokenName)
  }

  setLocalStoreData<T>(key: string, data: T) {
    return this.setKeyValue(key, data)
  }

  getLocalStoreData(key: string) {
    return this.getKey(key)
  }

  getUserID(): string | null {
    try {
      const token = this.getToken('Access-Token')
      if (token) {
        const parsedToken = this.parseJwt(token)
        if (!parsedToken) return null
        return parsedToken.username
      }
      return null
    } catch (error) {
      return null
    }
  }

  /** Private methods */

  private getTokenName(tokenType: TokenType): string {
    let tokenName = ''
    if (tokenType === 'Access-Token') {
      tokenName = TokenService.accessTokenName
    } else if (tokenType === 'Refresh-Token') {
      tokenName = TokenService.refreshTokenName
    } else if (tokenType === 'User-Chat-Token') {
      tokenName = TokenService.userChatTokenName
    }
    return tokenName
  }

  private parseJwt(token: string): { username: string } | null {
    try {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      return JSON.parse(jsonPayload)
    } catch (error) {
      return null
    }
  }

  private getKey(key: string) {
    const data = localStorage.getItem(key)
    return data ? JSON.parse(data) : null
  }

  private setKeyValue<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  private removeKey(key: string) {
    localStorage.removeItem(key)
  }
}
