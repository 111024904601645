import { PageSpinner } from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../auth/data/use-auth'
import { ROUTES } from '../routes-list'

/**
 *
 * unprotectedRoute - use this function to wrap public routes
 * @returns children if unAuthenticated, Inbox page if not authenticated
 */
/**
 *
 * @TODO implement logic in use auth to remember the location a user was trying to navigate to
 */
export function UnprotectedRoute({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth()
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.ASSIGNED_TO_ME)
    } else setLoading(false)
  }, [isAuthenticated, navigate])

  if (loading) return <PageSpinner />
  return children
}
