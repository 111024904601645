import { Box, PageSpinner, useColorModeValue as mode } from '@chaine/keychaine'
import { Footer, NavBar } from '@chaine/website'
import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useSafeAreaInsetsState } from '../../../layouts/safe-area-context'
import { Header } from '../header'
import { useAuth } from './../../../../auth/data/use-auth'

interface MainWebsiteContainerProps {
  toggleColorMode: () => void
}

export const MainWebsiteContainer = (props: MainWebsiteContainerProps) => {
  const { userDetails, loadingUserDetails, isAuthenticated } = useAuth()
  const safeAreaInsets = useSafeAreaInsetsState()

  return (
    <ScrollToTop>
      <Box h="100vh" w="100vw" bg={mode('light', 'black')} {...safeAreaInsets}>
        {(loadingUserDetails && <PageSpinner />) || (
          <Box>
            {userDetails && isAuthenticated ? <Header /> : <NavBar toggleColorMode={props.toggleColorMode} />}
            <Outlet />
            <Footer />
          </Box>
        )}
      </Box>
    </ScrollToTop>
  )
}

const ScrollToTop = (props: { children: React.ReactNode }) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <>{props.children}</>
}
