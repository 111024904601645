import { gql } from '@apollo/client'

export interface IWorkspaceInvitation {
  expirationDate: number
  invitedBy: {
    countryCode: string
    email: string
    id: string
    name: string
    phone: string
    photo: string
  }
  userID: string
  workspace: {
    displayName: string
    id: string
    logo: string
  }
}

/**
 * Query to get the members of a workspace
 */
export const GET_USERS_WORKSPACE_INVITATION = gql`
  query {
    myWorkspaceInvitations {
      workspaceID
      userID
      expirationDate
      workspace {
        id
        logo
        displayName
      }
      invitedBy {
        id
        name
        photo
        email
        phone
        countryCode
      }
    }
  }
`
