import { Box, Button, ChevronDownIcon, ChevronRightIcon, Collapse, Container, Flex, Icon, IconButton, MenuIcon, Popover, PopoverContent, PopoverTrigger, Stack, Text, useBreakpointValue, useColorMode, useColorModeValue as mode, useDisclosure, XCircleIcon } from '@chaine/keychaine';
import { FiSun } from '@react-icons/all-files/fi/FiSun';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { ROUTES } from '../_routes';
import { NAV_ITEMS } from '../_routes/navigation-routes';
import { LogoText as Logo } from '../_shared/logo';
export const NavBar = (props) => {
    const { toggleColorMode: toggleChakraColorMode } = useColorMode();
    const { isOpen, onToggle } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false });
    const handleToggle = () => {
        props.toggleColorMode();
        toggleChakraColorMode();
    };
    return (React.createElement(Box, { as: "section", position: "fixed", top: "0", width: "100%", zIndex: "99999" },
        React.createElement(Box, { as: "nav", bg: mode('rgba(255, 255, 255, .70)', 'rgba(0, 0, 0, 0.65)'), backdropFilter: 'blur(42.5px)' },
            React.createElement(Container, { py: { base: '4', lg: '5' } },
                React.createElement(Flex, { minH: '40px', py: { base: 2 }, px: { base: 4 }, align: 'center' },
                    React.createElement(Flex, { flex: { base: 1, md: 'auto' }, ml: { base: -2 }, display: { base: 'flex', md: 'none' } },
                        React.createElement(IconButton, { onClick: onToggle, icon: isOpen ? React.createElement(XCircleIcon, null) : React.createElement(MenuIcon, { w: 5, h: 5 }), variant: 'ghost', "aria-label": 'Toggle Navigation' })),
                    React.createElement(Flex, { flex: { base: 1 }, justify: { base: 'center', md: 'start' } },
                        !isMobile && (React.createElement(NavLink, { to: '/' },
                            React.createElement(Logo, null))),
                        React.createElement(Flex, { display: { base: 'none', md: 'flex' }, ml: 10 },
                            React.createElement(DesktopNav, null))),
                    React.createElement(Stack, { flex: { base: 1, md: 0 }, justify: 'flex-end', direction: 'row', spacing: 6 },
                        React.createElement(Button, { as: 'a', fontSize: 'md', fontWeight: 400, variant: 'link', href: 'https://legacy.chaineapp.com/', target: "_blank", rel: "noopener noreferrer" }, "Sign In"),
                        React.createElement(Button, { display: { base: 'none', md: 'inline-flex' }, variant: "primary" },
                            React.createElement(NavLink, { to: ROUTES.TALK_TO_SALES.href }, "Talk to sales")),
                        React.createElement(IconButton, { variant: "ghost", icon: React.createElement(Icon, { as: FiSun, fontSize: "xl" }), "aria-label": "Toggle color mode", onClick: handleToggle }))),
                React.createElement(Collapse, { in: isOpen, animateOpacity: true },
                    React.createElement(MobileNav, null))))));
};
const DesktopNav = () => {
    const linkColor = mode('gray.600', 'gray.200');
    const linkHoverColor = mode('gray.800', 'white');
    const popoverContentBgColor = mode('white', 'black');
    return (React.createElement(Stack, { direction: 'row', spacing: 4 }, NAV_ITEMS.map((navItem) => (React.createElement(Box, { key: navItem.label },
        React.createElement(Popover, { trigger: 'hover', placement: 'bottom-start' },
            React.createElement(PopoverTrigger, null,
                React.createElement(Box, { p: 2, fontSize: 'md', fontWeight: 500, color: linkColor, _hover: {
                        textDecoration: 'none',
                        color: linkHoverColor
                    } }, navItem.hash ? (React.createElement(NavHashLink, { to: navItem.hash },
                    navItem.label,
                    navItem.children && React.createElement(Icon, { as: ChevronDownIcon, ml: 2 }))) : (React.createElement(NavLink, { to: navItem.href || '#', key: `${navItem.label}-navLink` },
                    navItem.label,
                    navItem.children && React.createElement(Icon, { as: ChevronDownIcon, ml: 2 }))))),
            navItem.children && (React.createElement(PopoverContent, { border: "1px", boxShadow: 'xl', bg: popoverContentBgColor, borderColor: "border.primary", p: 4, rounded: 'xl', minW: 'sm' },
                React.createElement(Stack, null, navItem.children.map((child) => (React.createElement(DesktopSubNav, { key: child.label, ...child }))))))))))));
};
const DesktopSubNav = ({ label, href, subLabel, isExternalLink, hash }) => {
    return (React.createElement(Box, { role: 'group', display: 'block', p: 2, rounded: 'md', _hover: { bg: mode('gray.50', 'gray.900') } }, isExternalLink ? (React.createElement(Box, { as: "a", href: href || '#', target: "_blank", rel: "noopener noreferrer" },
        React.createElement(Stack, { direction: 'row', align: 'center' },
            React.createElement(Box, null,
                React.createElement(Text, { fontSize: 'sm', transition: 'all .3s ease', _groupHover: { color: 'blue.400' } }, label),
                React.createElement(Text, { fontSize: 'xs' }, subLabel)),
            React.createElement(Flex, { transition: 'all .3s ease', transform: 'translateX(-10px)', opacity: 0, _groupHover: { opacity: '100%', transform: 'translateX(0)' }, justify: 'flex-end', align: 'center', flex: 1 },
                React.createElement(Icon, { color: 'blue.400', w: 5, h: 5, as: ChevronRightIcon }))))) : hash ? (React.createElement(NavHashLink, { to: hash },
        React.createElement(Stack, { direction: 'row', align: 'center' },
            React.createElement(Box, null,
                React.createElement(Text, { fontSize: 'sm', transition: 'all .3s ease', _groupHover: { color: 'blue.400' } }, label),
                React.createElement(Text, { fontSize: 'xs' }, subLabel)),
            React.createElement(Flex, { transition: 'all .3s ease', transform: 'translateX(-10px)', opacity: 0, _groupHover: { opacity: '100%', transform: 'translateX(0)' }, justify: 'flex-end', align: 'center', flex: 1 },
                React.createElement(Icon, { color: 'blue.400', w: 5, h: 5, as: ChevronRightIcon }))))) : (React.createElement(NavLink, { to: href || '/' },
        React.createElement(Stack, { direction: 'row', align: 'center' },
            React.createElement(Box, null,
                React.createElement(Text, { fontSize: 'sm', transition: 'all .3s ease', _groupHover: { color: 'blue.400' } }, label),
                React.createElement(Text, { fontSize: 'xs' }, subLabel)),
            React.createElement(Flex, { transition: 'all .3s ease', transform: 'translateX(-10px)', opacity: 0, _groupHover: { opacity: '100%', transform: 'translateX(0)' }, justify: 'flex-end', align: 'center', flex: 1 },
                React.createElement(Icon, { color: 'blue.400', w: 5, h: 5, as: ChevronRightIcon })))))));
};
const MobileNav = () => {
    return (React.createElement(Stack, { bg: mode('white', 'gray.800'), p: 4, display: { md: 'none' } }, NAV_ITEMS.map((navItem) => (React.createElement(MobileNavItem, { key: navItem.label, ...navItem })))));
};
const MobileNavItem = ({ label, children, href, isExternalLink, hash }) => {
    const { isOpen, onToggle } = useDisclosure();
    const linkColor = mode('gray.600', 'gray.200');
    return (React.createElement(Stack, { spacing: 4, onClick: children && onToggle },
        React.createElement(Box, { py: 2, justifyContent: "space-between", alignItems: "center", _hover: {
                textDecoration: 'none'
            } }, isExternalLink ? (React.createElement(Box, { as: "a", href: href || '#', target: "_blank", rel: "noopener noreferrer" },
            React.createElement(Text, { fontWeight: 600, color: linkColor }, label))) : hash ? (React.createElement(NavHashLink, { to: hash },
            React.createElement(Text, { fontWeight: 600, color: linkColor }, label),
            children && (React.createElement(Icon, { as: ChevronDownIcon, transition: 'all .25s ease-in-out', transform: isOpen ? 'rotate(180deg)' : '', w: 6, h: 6 })))) : (React.createElement(NavLink, { to: href || '#' },
            React.createElement(Text, { fontWeight: 600, color: linkColor }, label),
            children && (React.createElement(Icon, { as: ChevronDownIcon, transition: 'all .25s ease-in-out', transform: isOpen ? 'rotate(180deg)' : '', w: 6, h: 6 }))))),
        React.createElement(Collapse, { in: isOpen, animateOpacity: true, style: { marginTop: '0!important' } },
            React.createElement(Stack, { mt: 2, pl: 4, borderLeft: 1, borderStyle: 'solid', borderColor: mode('gray.200', 'gray.700'), align: 'start' }, children &&
                children.map((child) => (React.createElement(Box, { key: child.label, py: 2 }, child.isExternalLink ? (React.createElement(Box, { as: "a", href: child.href || '#', target: "_blank", rel: "noopener noreferrer" },
                    React.createElement(Text, { fontWeight: 600, color: linkColor }, child.label))) : child.hash ? (React.createElement(NavHashLink, { to: child.hash },
                    React.createElement(Text, { fontWeight: 600, color: linkColor }, child.label))) : (React.createElement(NavLink, { to: child.href || '#', key: `${child.label}` }, child.label)))))))));
};
