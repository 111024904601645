import {
  Box,
  ColorModeToggle,
  Flex,
  HStack,
  useBreakpointValue,
  useColorMode,
  useColorModeValue as mode
} from '@chaine/keychaine'
import React from 'react'
import { useParams } from 'react-router-dom'

import { Logo } from '../../../../_assets/icons/logo'
import { useColorModeContext } from '../../../../_utils/color-mode-context'

const PublicHeader = () => {
  const { changeColorMode, colorMode } = useColorModeContext()

  const { handle } = useParams()
  const { toggleColorMode } = useColorMode()

  //toggle the color mode in chakra so that the components change and we can use chakra's hook.  later we should use our own hook
  const toggle = () => {
    toggleColorMode()
    changeColorMode()
  }

  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false })
  const backgroundColor = mode('gray.900', 'gray.800')

  return isDesktop ? (
    <Flex
      bg={backgroundColor}
      flexDirection="row"
      justify="space-between"
      px={6}
      minHeight={'74px'}
      py={5}
      alignItems="center"
    >
      <Box>{!handle && <Logo size="sm" color="white" />}</Box>
      <HStack spacing={4}>
        <ColorModeToggle updateColorModePreference={toggle} mode={colorMode} />
      </HStack>
    </Flex>
  ) : (
    <></>
  )
}

export default PublicHeader
