import { WarningTwoIcon } from '@chakra-ui/icons';
import React from 'react';
import { Box, Center, Flex, Text } from '../..';
export function FormWarning(props) {
    return (React.createElement(TextIcon, null,
        React.createElement(WarningTwoIcon, { color: "red.500", mr: 2 }),
        React.createElement(Text, { color: "red.500", fontSize: "sm", fontWeight: "medium" }, props.children)));
}
function TextIcon(props) {
    return (React.createElement(Box, null,
        React.createElement(Flex, { mt: 2 },
            React.createElement(Center, null, props.children))));
}
