import { ROUTES } from './routes';
export const FOOTER_ROUTES = [
    {
        title: 'Product',
        links: [{ label: 'Features', href: ROUTES.PRODUCT.href }, ROUTES.CARRIER_VISIBILITY_ONBOARDING, ROUTES.INTEGRATIONS]
    },
    {
        title: 'Developer',
        links: [ROUTES.DEVELOPER_DOCS, ROUTES.CONTAINER_API, ROUTES.TRUCKLOAD_API]
    },
    {
        title: 'Company',
        links: [{ label: 'About us', href: ROUTES.COMPANY.href }, ROUTES.TERMS, ROUTES.PRIVACY]
    },
    {
        title: 'Resources',
        links: [ROUTES.CUSTOMER_STORIES, ROUTES.TESTIMONIALS, ROUTES.LOGISTICS_TRAINING_GUIDE]
    }
];
