import { Center, Flex, HStack, Icon, Text, useColorModeValue as mode } from '@chaine/keychaine'
import React, { useMemo } from 'react'
import { Link, PathMatch, useLocation, useMatch } from 'react-router-dom'

import { useChat } from '../../../../chat/context/chat-context'

type NavActionProps = {
  children?: React.ReactNode
  icon: React.ElementType
  label: string
  parentPath?: string
  path?: string
}

const MobileNavAction = (props: NavActionProps) => {
  const { label, icon, parentPath, path, children } = props
  const { unreadCount } = useChat()

  const displayCount = useMemo(() => {
    return unreadCount > 99 ? `99+` : unreadCount
  }, [unreadCount])

  const location = useLocation()
  let activePath: boolean | '' | PathMatch<string> | null | undefined = useMatch(path || '')
  if (!path) return <></>

  if (parentPath) activePath = location.pathname.includes(parentPath)

  return (
    <Link to={path} key={path}>
      <Center
        py={2}
        aria-current={activePath ? 'page' : undefined}
        _activeLink={{
          color: mode('blue.500', 'blue.300')
        }}
      >
        <Flex position="relative" direction="column" align="center" as="button">
          <HStack>
            {icon && <Icon fontSize="2xl" as={icon} />}
            {label === 'Inbox' && unreadCount && (
              <Flex
                minW={5}
                justifyContent="center"
                px={1}
                borderRadius={'xl'}
                bg="blue.500"
                position="absolute"
                bottom={7}
                left={5}
              >
                <Text fontSize="xs" color="white">
                  {displayCount}
                </Text>
              </Flex>
            )}
          </HStack>
          <Text
            px="3"
            fontSize="xs"
            fontWeight="medium"
            aria-current={activePath ? 'page' : undefined}
            _activeLink={{
              color: mode('blue.500', 'blue.300')
            }}
          >
            {label}
          </Text>
          {children}
        </Flex>
      </Center>
    </Link>
  )
}

export const NavAction = {
  Mobile: MobileNavAction
}
