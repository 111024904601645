import { Box, Flex } from '@chaine/keychaine'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { SideMenu } from './components/side-menu'

export const ManageShipmentsLayout = () => {
  return (
    <Flex h="full" w="full" justifyContent="center">
      <SideMenu />
      <Box w="full" h="full" overflow="auto">
        <Flex justifyContent="center" alignContent="center" h="full">
          <Box w={{ base: 'full', md: '95%' }} pt={{ base: 3, lg: 12, md: 6 }}>
            <Box h="full">
              <Outlet />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}
