import { ComponentType } from 'react'

/**
 * This function is a wrapper around the `lazy` function from the React library
 * that adds support for lazy-loading and error-handling for code splitting chunks.
 *
 * @param componentImport - A function that returns a promise for a default component import.
 * @param chunkIdentifier - A string identifier for the code splitting chunk being loaded.
 * @returns A promise that resolves to the default component, or rejects if the import fails.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyRetry = async <T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  chunkIdentifier: string
): Promise<{ default: T }> => {
  // Use sessionStorage to store a flag indicating whether the chunk has already been refreshed
  const sessionStorageKey = `retry-lazy-refreshed-${chunkIdentifier}`

  // Check if the chunk has already been refreshed due to a previous error
  const hasRefreshed = window.sessionStorage.getItem(sessionStorageKey) === 'true'

  try {
    // Try to import the component using the provided function
    const component = await componentImport()

    // If the import succeeds, reset the refresh state and return the component
    window.sessionStorage.setItem(sessionStorageKey, 'false')
    return component
  } catch (error) {
    // If the import fails, and the chunk hasn't already been refreshed, refresh the page and retry the import
    if (!hasRefreshed) {
      window.sessionStorage.setItem(sessionStorageKey, 'true')
      window.location.reload()
    }

    // If the import still fails after refreshing, or if the chunk has already been refreshed, throw the error
    throw error
  }
}
