import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { RequireAuth, UnprotectedRoute } from '../hoc'
import { ROUTES } from '../routes-list'

const SignupOrLoginPage = lazy(() => import('../../auth/signup-login-page'))
const CodeVerificationPage = lazy(() => import('../../auth/code-verification-page'))
const VerifyInvitationPage = lazy(() => import('../../auth/verify-invitation-page'))
const UserName = lazy(() => import('../../auth/user-name'))
const ProfilePicture = lazy(() => import('../../auth/profile-picture'))

export const onboardingRoutes = (setConfetti: React.Dispatch<React.SetStateAction<boolean>>): RouteObject[] => {
  return [
    {
      element: (
        <UnprotectedRoute>
          <SignupOrLoginPage />
        </UnprotectedRoute>
      ),
      path: ROUTES.LOGIN
    },
    {
      element: (
        <UnprotectedRoute>
          <CodeVerificationPage />
        </UnprotectedRoute>
      ),
      path: ROUTES.VERIFY
    },
    {
      element: <VerifyInvitationPage />,
      path: ROUTES.VERIFY_INVITATION
    },
    {
      element: (
        <RequireAuth>
          <UserName setConfetti={setConfetti} />
        </RequireAuth>
      ),
      path: ROUTES.ONBOARDING
    },
    {
      element: (
        <RequireAuth>
          <ProfilePicture />
        </RequireAuth>
      ),
      path: ROUTES.ONBOARDING_PROFILE_PICTURE
    }
  ]
}
