/**
 * The variant for the for the setup workspace component- create a new workspace or get verified
 */
export enum Variant {
  CreateWorkspace = 'create-workspace',
  VerifyWorkspace = 'verify-workspace'
}

/**
 * The type of identifier for the company (e.g. MC#, DOT#)
 */
export enum CompanyIdentifierType {
  DOT = 'DOT',
  MC = 'MC',
  Other = 'Other'
}

/**
 * The type of the company (e.g. Broker, Carrier)
 */
export enum CompanyType {
  Broker = 'Broker',
  Carrier = 'Carrier'
}

/**
 * If the user is trying to verify via phone, they can select to receive the OTP via SMS or phone call
 */
export enum ReceiveCodeMethodType {
  Email = 'Email',
  PhoneCall = 'PhoneCall',
  SMS = 'SMS'
}

/**
 * The possible values for verifying a workspace
 */
export enum VerificationMethodType {
  CompanyEmail = 'CompanyEmail',
  Custom = 'Custom',
  Email = 'Email',
  Phone = 'Phone'
}

/**
 * Props shared by all steps of the setup workspace process
 */
export interface ModalStepProps {
  /**
   * Go to the next step of the setup workspace process
   */
  handleNext: () => void
  /**
   * Navigates to the previous step or skips to a new step
   */
  previousStepOrSkipStep: (goToStep: number) => void
  /**
   * The step of the setup workspace process which is currently active
   */
  step: number
  /**
   * The modal variant - either create a new workspace or get verified
   */
  variant?: Variant
}

export type WorkspaceProps = {
  /**
   * The name of the workspace the user is trying to get verified for- note, if the variant is to create a new workspace, this has to be null
   */
  displayName: string
  /**
   * The UUID of the workspace the user is trying to get verified for- note, if the variant is to create a new workspace, this has to be null
   */
  id: string

  logo?: string | null
}

/**
 * The authority status of the different authorities (Broker, Property and Contract)
 */
export enum AuthorityStatus {
  A = 'Active',
  I = 'Inactive',
  N = 'No authority'
}

/**
 * If the company is a carrier, this states the carrier's operation type. Codes identifying carriers' type of Operation; A = Interstate, B = Intrastate Hazmat, C = Intrastate Non-Hazmat
 */
export enum CarrierOperation {
  A = 'Interstate',
  B = 'Intrastate Hazmat',
  C = 'Intrastate Non-Hazmat'
}
