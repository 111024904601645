import { gql } from '@apollo/client'

import { CompanyIdentifierType, ReceiveCodeMethodType, VerificationMethodType } from '../types'

/**
 * DOT or MC of type {@link CompanyIdentifierInput}
 */
export type CompanyIdentifierInput = {
  /**
   * DOT or MC of type {@link CompanyIdentifierType}
   */
  type: CompanyIdentifierType
  /**
   * The value of the DOT or MC
   */
  value: string
}

export type InitiateWorkspaceVerificationInput = {
  /**
   * DOT or MC of type belong to the company {@link CompanyIdentifierInput}
   */
  companyIdentifier: CompanyIdentifierInput

  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * Response from the initiate workspace verification mutation that returns whether a user is verified for a workspace
 */
export type InitiateWorkspaceVerificationReturnType = {
  isVerified: boolean
}

/**
 * Initiate user workspace verification which retrieves the various ways a user can verify their workspace
 */
export const INITIATE_WORKSPACE_VERIFICATION = gql`
  mutation InitiateWorkspaceVerification($companyIdentifier: CompanyIdentifierInput!, $workspaceID: ID!) {
    initiateWorkspaceVerification(input: { companyIdentifier: $companyIdentifier, workspaceID: $workspaceID }) {
      isVerified
    }
  }
`

export interface RequestCustomVerificationInput {
  /**
   * The strategy to use for verification (e.g. send email or text, make phone call
   */
  input: {
    companyIdentifier: { type: CompanyIdentifierType; value: string }
  }
}

/* Requests a custom verification
 *
 * @param companyIdentifier the company identifier type (MC or DOT) and value
 */
export const REQUEST_CUSTOM_VERIFICATION = gql`
  mutation RequestCustomVerification($input: RequestCustomVerificationDTO!) {
    requestCustomVerification(input: $input) {
      id
    }
  }
`

export interface SendVerificationCodeInput {
  /**
   * The strategy or method to use for receiving a verification code (e.g. send email or text, make phone call
   */
  method: ReceiveCodeMethodType

  /**
   * The type of value for the verification (e.g. email or phone number)
   */
  type: VerificationMethodType

  /**
   * The value of the email or phone number to be verified
   */
  value?: string
}

/**
 * Requests to send a verification code
 *
 * @param method the method to use for receiving a verification code (e.g. send email or text, make phone call
 * @param type the type of value for the verification (e.g. email or phone number)
 * @param value the value of the email or phone number to be verified
 */
export const SEND_VERIFICATION_CODE = gql`
  mutation SendVerificationCode($method: String!, $type: String!, $value: String) {
    sendVerificationCode(input: { method: $method, type: $type, value: $value }) {
      id
    }
  }
`

export interface VerifyCodeForVerifiedStatusInput {
  /**
   * The verification code
   */
  code: string
}

/**
 * Verifies the code for workspace verification
 *
 * @param code the verification code
 */
export const VERIFY_CODE_FOR_VERIFIED_STATUS = gql`
  mutation VerifyCodeForVerifiedStatus($code: String!) {
    verifyCodeForVerifiedStatus(input: { code: $code }) {
      id
    }
  }
`
