import { gql } from '@apollo/client'

export const INITIATE_VERIFICATION_FOR_WORKSPACE_MEMBER = gql`
  mutation InitiateVerificationForWorkspaceMember($workspaceID: ID!, $workspaceMemberUserID: ID!) {
    initiateVerificationForWorkspaceMember(
      input: { workspaceID: $workspaceID, workspaceMemberUserID: $workspaceMemberUserID }
    ) {
      id
    }
  }
`

export const VERIFY_CODE_FOR_WORKSPACE_MEMBER = gql`
  mutation VerifyCodeForWorkspaceMember($workspaceID: ID!, $code: String!) {
    verifyCodeForWorkspaceMember(input: { workspaceID: $workspaceID, code: $code }) {
      id
    }
  }
`
