export function getBorderStyles(size, variant, borderRadius, boxShadow) {
    let customBorderSize = '0.188rem';
    let customBorderRadius = 'full';
    if (size) {
        switch (size) {
            case '2xl':
                customBorderSize = '0.5rem';
                customBorderRadius = '2rem';
                break;
            case 'xl':
                customBorderSize = '0.375rem';
                customBorderRadius = '1.5rem';
                break;
            case 'lg':
                customBorderSize = '0.25rem';
                customBorderRadius = '1.0rem';
                break;
            case 'md':
                customBorderSize = '0.188rem';
                customBorderRadius = '0.75rem';
                break;
            case 'sm':
                customBorderSize = '0.125rem';
                customBorderRadius = '0.5rem';
                break;
            case 'xs':
                customBorderSize = '0.094rem';
                customBorderRadius = '0.375rem';
                break;
            case '2xs':
                customBorderSize = '0.063rem';
                customBorderRadius = '0.25rem';
                break;
            default:
                customBorderSize = '0.188rem';
                customBorderRadius = '0.75rem';
                break;
        }
    }
    const customBoxShadow = `inset 0px 0px 0px ${customBorderSize} rgba(30, 30, 30, 0.10)`;
    if (variant === 'square') {
        return { borderRadius: customBorderRadius, boxShadow: customBoxShadow };
    }
    else if (variant === 'basic') {
        return { borderRadius: borderRadius || 'full', boxShadow };
    }
    else {
        return { borderRadius: borderRadius || 'full', boxShadow: customBoxShadow };
    }
}
