import * as Yup from 'yup';
function getChildNames(children) {
    const names = [];
    children.forEach((child) => {
        if (Array.isArray(child.props?.children)) {
            names.push(...getChildNames(child.props.children));
        }
        if (child?.props?.name)
            names.push(child.props.name);
    });
    return names;
}
export function validationSchemaBuilder(children, validations) {
    const names = getChildNames(children);
    const schema = {};
    names.map((name) => {
        if (validations?.[name]) {
            schema[name] = validations[name];
        }
    });
    return Yup.object(schema);
}
export function getSchemaFromNames(names, validations) {
    const schema = {};
    names.map((name) => {
        if (validations?.[name]) {
            schema[name] = validations[name];
        }
    });
    return Yup.object(schema);
}
