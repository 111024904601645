import { keychaine, useColorModeValue, useToken } from '@chaine/keychaine';
import * as React from 'react';
export const Logo = (props) => {
    const [white, black] = useToken('colors', ['white', 'gray.800']);
    return (React.createElement(keychaine.svg, { color: useColorModeValue('blue.500', 'blue.300'), "aria-hidden": true, boxSize: "7", ...props },
        ";",
        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -1 24 24" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.0795 6.59458C2.46482 7.02498 2.31543 7.87219 2.74583 8.48687L6.17482 13.384C6.60523 13.9987 7.45244 14.148 8.06712 13.7176L9.00776 13.059L7.09367 10.3254C6.66327 9.71072 6.81265 8.86351 7.42734 8.43311L9.60304 6.90966L8.08813 4.74615C7.65773 4.13147 6.81052 3.98209 6.19584 4.41249L3.0795 6.59458Z", fill: useColorModeValue(black, white) }),
            React.createElement("path", { d: "M8.09365 10.5511C7.66325 9.93644 7.81263 9.08923 8.42731 8.65883L11.5437 6.47674C12.1583 6.04634 13.0055 6.19572 13.4359 6.8104L16.8649 11.7075C17.2953 12.3222 17.146 13.1694 16.5313 13.5998L13.4149 15.7819C12.8003 16.2123 11.953 16.0629 11.5226 15.4482L8.09365 10.5511Z", fill: useColorModeValue(black, white) }))));
};
export const LogoText = (props) => {
    const [white, black] = useToken('colors', ['white', 'gray.800']);
    return (React.createElement(keychaine.svg, { color: useColorModeValue('blue.500', 'blue.300'), "aria-hidden": true, viewBox: "0 0 130 32", fill: "none", height: "8", width: "auto", flexShrink: 0, ...props },
        React.createElement("svg", { width: "130", height: "32", viewBox: "0 0 135 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.56627 8.96737C0.43526 9.75931 0.16039 11.3182 0.952334 12.4492L7.26167 21.4599C8.05362 22.5909 9.61248 22.8657 10.7435 22.0738L12.4787 20.8588L8.95891 15.8321C8.16696 14.7011 8.44183 13.1422 9.57285 12.3503L13.5717 9.55021L10.7822 5.56627C9.99022 4.43526 8.43135 4.16039 7.30034 4.95233L1.56627 8.96737Z", fill: useColorModeValue(black, white) }),
            React.createElement("rect", { x: "9.51562", y: "14.2842", width: "12", height: "16", rx: "2.5", transform: "rotate(-35 9.51562 14.2842)", fill: useColorModeValue(black, white) }),
            React.createElement("path", { d: "M43.91 26.42C46.85 26.42 49.4 24.86 50.69 22.52L47.33 20.6C46.73 21.83 45.44 22.58 43.88 22.58C41.57 22.58 39.86 20.87 39.86 18.5C39.86 16.1 41.57 14.39 43.88 14.39C45.41 14.39 46.7 15.17 47.3 16.4L50.63 14.45C49.4 12.14 46.85 10.58 43.91 10.58C39.35 10.58 35.99 14.03 35.99 18.5C35.99 22.97 39.35 26.42 43.91 26.42ZM61.0768 10.58C59.0668 10.58 57.5068 11.33 56.6068 12.68V4.99999H52.7368V26H56.6068V17.9C56.6068 15.29 58.0168 14.18 59.9068 14.18C61.6468 14.18 62.8768 15.23 62.8768 17.27V26H66.7468V16.79C66.7468 12.8 64.2568 10.58 61.0768 10.58ZM81.4513 11V12.77C80.3713 11.42 78.7513 10.58 76.5613 10.58C72.5713 10.58 69.2713 14.03 69.2713 18.5C69.2713 22.97 72.5713 26.42 76.5613 26.42C78.7513 26.42 80.3713 25.58 81.4513 24.23V26H85.3213V11H81.4513ZM77.2813 22.73C74.9113 22.73 73.1413 21.02 73.1413 18.5C73.1413 15.98 74.9113 14.27 77.2813 14.27C79.6813 14.27 81.4513 15.98 81.4513 18.5C81.4513 21.02 79.6813 22.73 77.2813 22.73ZM90.7506 9.2C92.0706 9.2 93.1506 8.12 93.1506 6.82999C93.1506 5.53999 92.0706 4.42999 90.7506 4.42999C89.4606 4.42999 88.3806 5.53999 88.3806 6.82999C88.3806 8.12 89.4606 9.2 90.7506 9.2ZM88.8306 26H92.7006V11H88.8306V26ZM104.583 10.58C102.573 10.58 101.013 11.33 100.113 12.68V11H96.2427V26H100.113V17.9C100.113 15.29 101.523 14.18 103.413 14.18C105.153 14.18 106.383 15.23 106.383 17.27V26H110.253V16.79C110.253 12.8 107.763 10.58 104.583 10.58ZM116.827 20.09H128.137C128.227 19.58 128.287 19.07 128.287 18.5C128.287 14.09 125.137 10.58 120.697 10.58C115.987 10.58 112.777 14.03 112.777 18.5C112.777 22.97 115.957 26.42 120.997 26.42C123.877 26.42 126.127 25.25 127.537 23.21L124.417 21.41C123.757 22.28 122.557 22.91 121.057 22.91C119.017 22.91 117.367 22.07 116.827 20.09ZM116.767 17.09C117.217 15.17 118.627 14.06 120.697 14.06C122.317 14.06 123.937 14.93 124.417 17.09H116.767Z", fill: useColorModeValue(black, white) }))));
};
