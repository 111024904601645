import { PageSpinner } from '@chaine/keychaine'
import React, { lazy, Suspense } from 'react'

import { lazyRetry } from '../lazy-retry'
import { ROUTES } from '../routes-list'

const Inbox = lazy(() => lazyRetry(() => import('../../inbox'), 'Inbox'))

export const inboxRoutes = () => {
  return [
    {
      children: [
        {
          element: (
            <Suspense>
              <Inbox />
            </Suspense>
          ),
          path: ROUTES.DIRECT_MESSAGES
        },
        {
          element: (
            <Suspense>
              <Inbox />
            </Suspense>
          ),
          path: ROUTES.SHIPMENT_CHATS
        },
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <Inbox />
            </Suspense>
          ),
          path: ROUTES.OFFER_CHATS
        },
        {
          element: (
            <Suspense>
              <Inbox />
            </Suspense>
          ),
          path: `${ROUTES.TEAM_MEMBER}/:member_id`
        }
      ],
      element: (
        <Suspense>
          <Inbox />
        </Suspense>
      ),
      path: ROUTES.INBOX
    }
  ]
}
