import { UserRole } from '../_shared/types/user-types'

/**
 * Represents the user utils for user details
 */
export class UserUtils {
  /**
   * Returns the username to display on the UI
   * @param user.email the user's email address'
   * @returns username the user's name or email or phone number
   */
  static userDisplayName(user: {
    email: string | undefined
    name: string | undefined
    phone: string | undefined
  }): string {
    return `${user.name || user.email || user.phone || 'user'}`
  }

  /**
   * Possible user roles
   */
  static roles = [
    { label: 'Carrier Sales Rep', value: UserRole.CarrierSalesRep },
    { label: 'Customer Sales Rep', value: UserRole.CustomerSalesRep },
    { label: 'Dispatcher', value: UserRole.Dispatcher },
    { label: 'Driver', value: UserRole.Driver },
    { label: 'Owner Operator', value: UserRole.OwnerOperator },
    { label: 'Shipper Sales Rep', value: UserRole.ShipperSalesRep }
  ]

  /**
   *
   * Returns label from the array item by matching its value
   */
  static getLabelByValue(value: UserRole, array: { label: string; value: UserRole }[]): string {
    const item = array.find((a) => a.value === value)
    return item?.label || ''
  }
}
