import { selectableCardPrimaryStyle, selectableCardSecondaryStyle } from '../../form/cards';
export const SelectableCard = {
    baseStyle: {
        spacing: 3
    },
    variants: {
        primary: selectableCardPrimaryStyle,
        secondary: selectableCardSecondaryStyle
    }
};
