import { Box } from '@chaine/keychaine'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { AccountSettingsSideMenu } from './account-settings-side-menu'

/**
 *
 * @returns Layout for Page settings page. e.g; user profile, workspaces, members, billing etc.
 */
const UnmemoizedAccountSettingsPage = () => {
  return (
    <Box h="100%" display={{ lg: 'flex' }} fontSize={{ base: '14px', sm: '16px' }} w="full">
      {/* This is going to contain the Left nav component of the Account settings page */}
      <Box w={{ base: '100%', lg: '10%', md: '25%' }} pl={{ lg: 4, md: 4 }}>
        <AccountSettingsSideMenu />
      </Box>
      {/* This is going to contain the content of the Account settings page */}
      <Box w={{ base: '100%', lg: '80%' }} pl={{ base: 3, lg: 28, md: 10 }} ml={0} pr={{ base: 3, lg: 0, md: 10 }}>
        <Outlet />
      </Box>
      {/* This is empty for now */}
      {/* <Box bg="green" w={{ base: '100%', lg: '25%' }}></Box> */}
    </Box>
  )
}

export const AccountSettingsPage = React.memo(UnmemoizedAccountSettingsPage)
