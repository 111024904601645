import * as Joi from 'joi';
export const isInputValueEmailPhone = (inputValue) => {
    const fourCharacters = Joi.string().min(4);
    const phoneNumber = Joi.string().pattern(/^\d+$/);
    const isFourCharacters = fourCharacters.validate(inputValue);
    const isPhoneNumber = phoneNumber.validate(inputValue);
    if (!isFourCharacters.error) {
        if (isPhoneNumber.error) {
            return 'email';
        }
        else {
            if (inputValue[0] === '1' && inputValue.length === 4)
                return 'email';
            else
                return 'phone';
        }
    }
    else {
        return 'email';
    }
};
