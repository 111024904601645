import React from 'react';
import { useController } from 'react-hook-form';
import { FormWarning, HStack, useRadioGroup, VStack } from '../..';
import { RadioCard } from '..';
export const selectableCardSecondaryStyle = {
    layout: 'vertical',
    width: '56',
    textAlign: 'left',
    spacing: 3,
    borderRadius: 'xl',
    borderWidth: '1px',
    px: 4,
    py: 4
};
export const selectableCardPrimaryStyle = {
    layout: 'horizontal',
    lineHeight: '1.2',
    fontSize: 'sm',
    borderRadius: 'md',
    borderWidth: '1px',
    spacing: 3,
    px: 2,
    py: 2
};
export function SelectableCard({ name, control, options, disableErrorMessage, ...rest }) {
    const { layout, spacing, variant } = rest;
    const { field, formState: { errors } } = useController({
        control,
        name,
        rules: { required: { value: true, message: 'Required field' } }
    });
    const { getRootProps, getRadioProps } = useRadioGroup({
        name,
        onChange: field.onChange,
        value: field.value
    });
    const rootProps = getRootProps();
    const variantProps = variant === 'secondary' ? selectableCardSecondaryStyle : selectableCardPrimaryStyle;
    return layout === 'vertical' || variantProps.layout === 'vertical' ? (React.createElement(VStack, { spacing: spacing ? spacing : 3, ...rootProps },
        options.map((item) => {
            const { value, label, ...remaining } = item;
            const radioProps = getRadioProps({ value });
            return (React.createElement(RadioCard, { key: value, ...radioProps, ...remaining }, label));
        }),
        !disableErrorMessage && errors[name] && React.createElement(FormWarning, null, errors[name]?.message))) : (React.createElement(HStack, { spacing: spacing ? spacing : 3, ...rootProps },
        options.map((item) => {
            const { value, label } = item;
            const radioProps = getRadioProps({ value });
            return (React.createElement(RadioCard, { key: value, ...radioProps }, label));
        }),
        !disableErrorMessage && errors[name] && React.createElement(FormWarning, null, errors[name]?.message)));
}
