import { gql } from '@apollo/client'

import { CompanyIdentifierType, CompanyType } from '../types'
import { CompanyIdentifierInput } from './mutations'

export interface GetConstraintInput {
  constraint: string
}

export interface GetWorkspaceByDOTOrMCNumberInput {
  dotOrMcNumber: string
  type: CompanyIdentifierType
}

/**
 * This query returns true if a workspacename exists
 * @param constraint is the value to check for set validation, so the value of workspacename
 */
export const VALIDATE_WORKSPACE_NAME_UNIQUENESS = gql`
  query GetConstraint($constraint: String!) {
    getConstraint(input: { constraint: $constraint }) {
      id
    }
  }
`

/**
 * The company returned from the DOT or MC number that was entered by the user.
 */
export interface CompanyReturnType {
  /**
   * This is determined from the FMCSA QC API using the allowedToOperate property and explains whether a carrier/broker has an active DOT number.
   * https://safer.fmcsa.dot.gov/saferhelp.aspx#OOS
   *
   */
  allowedToOperate?: boolean

  /**
   * Specifies whether a company is an authorized freight broker
   */
  authorizedForBroker?: boolean

  /**
   * For carriers only if they are authorized to haul freight i.e. property
   */
  authorizedForProperty?: boolean
  brokerAuthorityStatus?: string

  commonAuthorityStatus?: string

  /**
   * The company's chosen name (if different from legal name)
   * @example 'Acme Trucking'
   */
  companyName: string
  contractAuthorityStatus?: string
  /**
   * The company's email address registered with the FMCSA
   */
  emailAddress: string | undefined

  /**
   * The company's DOT number
   */
  id: string

  mcNumber?: string

  /**
   * The company's phone number registered with the FMCSA
   */
  phoneNumber: string | undefined

  /**
   * The company's physical address as registered with the FMCSA
   */
  physicalAddress:
    | {
        city: string
        country: string
        state: string
        street: string
        zip: string
      }
    | undefined
}

/**
 * This query returns the company by DOT or MC
 * @param id The DOT or MC
 * @param type Type of ID (DOT/MC) of {@link CompanyIdentifierType}
 * @return company The company details of type {@link CompanyReturnType}
 */
export const GET_COMPANY_BY_DOT_OR_MC = gql`
  query Company($id: ID!, $type: String!) {
    company(input: { id: $id, type: $type }) {
      id
      mcNumber
      companyName
      allowedToOperate
      authorizedForBroker
      authorizedForProperty
      brokerAuthorityStatus
      commonAuthorityStatus
      contractAuthorityStatus
      physicalAddress {
        street
        city
        state
      }
    }
  }
`

/**
 * The workspace details returned from the DOT or MC number that was entered by the user.
 */
export interface WorkspaceByDOTOrMCNumberReturnType {
  displayName: string
  dot: string | null
  id: string
  logo: string | null
  mcNumber: string | null
  workspaceType: CompanyType
  workspacename: string
}

/**
 * This query returns the workspace with restricted data by DOT or MC
 * @param dotOrMcNumber The DOT or MC
 * @param type Type of ID (DOT/MC) of {@link CompanyIdentifierType}
 * @return getWorkspaceByDotOrMcNumber The workspace details of type {@link WorkspaceByDOTOrMCNumberReturnType}
 */
export const GET_WORKSPACE_BY_DOT_OR_MC = gql`
  query GetWorkspaceByDOTOrMCNumber($dotOrMCNumber: String!, $type: DOTOrMCType!) {
    getWorkspaceByDOTOrMCNumber(input: { dotOrMCNumber: $dotOrMCNumber, type: $type }) {
      id
      displayName
      dot
      mcNumber
      workspaceType
      workspacename
      logo
    }
  }
`

export type CompanyVerificationMethodsInput = {
  /**
   * DOT or MC of type belong to company {@link CompanyIdentifierInput}
   */
  companyIdentifier: CompanyIdentifierInput

  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * Response from the get verification methods query that returns whether a various verification methods
 */
export type CompanyVerificationMethodsReturnType = {
  verificationMethods: { type: string; value: string }[] | null
}

/**
 * get verification methods which retrieves the various ways a user can verify their workspace
 *
 * @param companyIdentifier DOT or MC of type {@link CompanyIdentifierInput}
 */

export const GET_COMPANY_VERIFICATION_METHODS = gql`
  query CompanyVerificationMethods($companyIdentifier: CompanyIdentifierInput!, $workspaceID: ID!) {
    companyVerificationMethods(input: { companyIdentifier: $companyIdentifier, workspaceID: $workspaceID }) {
      verificationMethods {
        value
        type
      }
    }
  }
`
