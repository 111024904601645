import { tooltipBaseStyle, tooltipKBDStyle } from '../..';
export const Tooltip = {
    variants: {
        kbd: tooltipKBDStyle,
        base: tooltipBaseStyle
    },
    defaultProps: {
        variant: 'base'
    }
};
