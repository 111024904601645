export { Button, useButtonGroup } from '@chakra-ui/react';
export const disabledStyles = {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none'
};
export const buttonBaseStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'gray.900' : 'gray.50',
        color: colorMode === 'dark' ? 'text.tertiary' : 'text.primary',
        border: '',
        borderRadius: '0.4rem',
        boxShadow: 'none',
        _hover: {
            bg: colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.100',
            boxShadow: 'none',
            border: ''
        },
        _active: {
            bg: colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.200',
            border: '',
            boxShadow: 'none'
        },
        _focus: {
            bg: colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.200',
            border: ''
        }
    };
};
export const buttonSideBarStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: 'none',
        color: colorMode === 'dark' ? 'gray.600' : 'gray.500',
        border: undefined,
        borderRadius: '0.4rem',
        boxShadow: 'none',
        _hover: {
            color: colorMode === 'dark' ? 'gray.100' : 'blue.500',
            boxShadow: 'none',
            border: undefined
        },
        _active: {
            bg: colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.50',
            color: colorMode === 'dark' ? 'gray.300' : 'blue.500',
            border: undefined,
            boxShadow: 'none'
        },
        _focus: {
            bg: colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.50',
            border: undefined
        }
    };
};
export const buttonOutlineStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'gray.900' : 'white',
        color: colorMode === 'dark' ? 'text.tertiary' : 'text.primary',
        border: colorMode === 'dark' ? '0.85px solid #535159' : '0.85px solid #DADADB',
        borderRadius: '0.4rem',
        boxShadow: colorMode === 'dark' ? 'none' : '0px 1px 0px rgba(30, 30, 30, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)',
        _hover: {
            bg: colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.50',
            boxShadow: colorMode === 'dark'
                ? 'none'
                : '0px 1px 0px rgba(30, 30, 30, 0.06), inset 0px 2px 0px rgba(255, 255, 255, 0.25)',
            border: colorMode === 'dark' ? undefined : '0.85px solid #DADADB'
        },
        _active: {
            bg: colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.100',
            border: colorMode === 'dark' ? undefined : '0.85px solid #DADADB',
            boxShadow: 'none'
        },
        _focus: { outline: 'none' }
    };
};
export const buttonPrimaryStyle = (props) => {
    const { colorMode } = props;
    const bg = colorMode === 'dark' ? 'blue.600' : 'blue.400';
    return {
        bg: colorMode === 'dark' ? 'blue.600' : 'blue.400',
        color: colorMode === 'dark' ? 'text.primary' : 'white',
        bgGradient: colorMode === 'dark' ? 'linear(blue.600, blue.700)' : 'linear(blue.400, blue.500)',
        borderRadius: '0.4rem',
        boxShadow: colorMode === 'dark' ? '0px 0.5px 1.5px 0px rgba(0,91,191,.25)' : '0px 0.5px 1.5px 0px rgba(0,122,255,.25)',
        _hover: {
            bg: colorMode === 'dark' ? 'blue.800' : 'blue.600',
            _disabled: {
                bg: bg
            },
            bgGradient: colorMode === 'dark' ? 'linear(blue.700, blue.800)' : 'linear(blue.500, blue.600)',
            boxShadow: 'none'
        },
        _active: {
            bg: colorMode === 'dark' ? 'blue.800' : 'blue.600',
            bgGradient: 'none',
            boxShadow: 'none'
        },
        _focus: {
            bg: colorMode === 'dark' ? 'blue.700' : 'blue.500',
            boxShadow: 'none'
        }
    };
};
export const buttonSecondaryStyle = (props) => {
    const { colorMode } = props;
    const bg = colorMode === 'dark' ? 'gray.700' : 'gray.100';
    return {
        bg: colorMode === 'dark' ? 'gray.700' : 'gray.100',
        color: colorMode === 'dark' ? 'text.tertiary' : 'text.primary',
        bgGradient: colorMode === 'dark' ? 'none' : 'linear(#FAFAFC, #F5F4F6)',
        border: colorMode === 'dark' ? 'none' : '0.85px solid #D1D1D6',
        borderRadius: '0.4rem',
        boxShadow: colorMode === 'dark'
            ? '0px 1px 1.5px rgba(30,30,30,1), inset 0px 1px 0px rgba(229, 229, 234, 0.15)'
            : '0px 1px 0px rgba(30, 30, 30, 0.06), inset 0px 2px 0px rgba(255, 255, 255, 0.25)',
        _hover: {
            bg: colorMode === 'dark' ? 'gray.600' : 'gray.100',
            _disabled: {
                bg: bg
            },
            boxShadow: colorMode === 'dark'
                ? '0px 1px 1.5px rgba(30, 30, 30, 1), inset 0px 1px 0px rgba(255, 255, 255, 0.15)'
                : '0px 1px 0px rgba(30, 30, 30, 0.06), inset 0px 2px 0px rgba(255, 255, 255, 0.25)'
        },
        _active: {
            bg: colorMode === 'dark' ? 'blue.700' : 'gray.200',
            bgGradient: colorMode === 'dark' ? 'linear(blue.600, blue.700)' : 'none',
            boxShadow: 'none'
        },
        _focus: {
            bg: colorMode === 'dark' ? 'gray.700' : 'gray.200',
            boxShadow: 'none'
        }
    };
};
export const buttonDangerStyle = (props) => {
    const { colorMode } = props;
    const bg = colorMode === 'dark' ? 'red.600' : 'red.400';
    return {
        bg: colorMode === 'dark' ? 'red.600' : 'red.400',
        color: colorMode === 'dark' ? 'text.secondary' : 'white',
        bgGradient: colorMode === 'dark' ? 'linear(red.600, red.700)' : 'linear(red.400, red.500)',
        borderRadius: '0.4rem',
        boxShadow: colorMode === 'dark' ? '0px 0.5px 1.5px 0px rgba(191,39,31,.25)' : '0px 0.5px 1.5px 0px rgba(255,59,48,.25)',
        _hover: {
            bg: colorMode === 'dark' ? 'red.800' : 'red.600',
            _disabled: {
                bg: bg
            },
            bgGradient: colorMode === 'dark' ? 'linear(red.700, red.800)' : 'linear(red.500, red.600)',
            boxShadow: 'none'
        },
        _active: {
            bg: colorMode === 'dark' ? 'red.800' : 'red.600',
            bgGradient: 'none',
            boxShadow: 'none'
        },
        _focus: {
            bg: colorMode === 'dark' ? 'red.700' : 'red.500',
            boxShadow: 'none'
        }
    };
};
