import { useMutation } from '@apollo/client'
import { Button, EmailInput, Form, FormLabel, FormWarning } from '@chaine/keychaine'
import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'

import { Toast } from '../toast'
import { SEND_VERIFICATION_CODE, SendVerificationCodeInput } from './data/mutations'
import { ModalStepProps, ReceiveCodeMethodType, VerificationMethodType } from './types'
import { Wrapper } from './wrapper'

interface EmailAddressProps extends ModalStepProps {
  /**
   * How the verification code will be sent to the user
   */
  receiveCodeMethod: ReceiveCodeMethodType

  /**
   * Sets the email address that the user wants to verify
   */
  setEmailVerificationMethod: (params: { type: VerificationMethodType; value: string }) => void

  /**
   * Sets the verification strategy (SMS or phone call)
   */
  setReceiveCodeMethod: (strategy: ReceiveCodeMethodType) => void

  /**
   * The preferred method for completing verification
   */
  verificationMethod: { type: VerificationMethodType; value: string } | null

  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * The user enters in the email address that they want to verify
 */
export const UnmemoizedEmailAddress = (props: EmailAddressProps) => {
  const {
    step,
    previousStepOrSkipStep,
    setEmailVerificationMethod,
    handleNext,
    workspaceID,
    receiveCodeMethod,
    verificationMethod
  } = props
  const { showToast } = Toast()

  //focus the input ref when the component loads
  const inputRef = React.useRef<HTMLInputElement>(null)

  // sending code mutation
  const [sendVerificationCode, { loading, error }] = useMutation<
    { sendVerificationCode: { id: string } },
    SendVerificationCodeInput
  >(SEND_VERIFICATION_CODE, {
    onCompleted: () => {
      handleNext()
    },
    onError: (err) => {
      showToast({
        status: 'error',
        title: err.message
      })
    }
  })

  //focus the ref to the input when the component loads
  useEffect(() => {
    if (null !== inputRef.current) inputRef.current.focus()
  }, [])

  const handleSubmit = useCallback(
    async (email: string) => {
      setEmailVerificationMethod({ type: VerificationMethodType.CompanyEmail, value: email })
      const input = {
        method: receiveCodeMethod,
        type: VerificationMethodType.CompanyEmail,
        value: email
      }
      await sendVerificationCode({
        context: {
          headers: {
            'ws-id': workspaceID || ''
          }
        },
        variables: input
      })
      handleNext()
    },
    [setEmailVerificationMethod, workspaceID, handleNext, receiveCodeMethod, sendVerificationCode]
  )

  const validEmailDomain = `@${verificationMethod?.value}`

  // validation schema
  const validations: { email: Yup.StringSchema<string | undefined, Record<string, string>, string | undefined> } = {
    email: Yup.string()
      .email('Invalid email address')
      .required('Please enter your email')
      .test(
        'domain',
        `Your email must end with ${validEmailDomain}`,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (value: any) => value?.endsWith(validEmailDomain) // Returns true if the email ends with the required domain
      )
  }

  return (
    <>
      <Wrapper
        title={`Enter an "${validEmailDomain}" email`}
        activeStep={step}
        backButtonProps={{ displayBackArrow: true, onSubmit: () => previousStepOrSkipStep(5), text: 'Go back' }}
      >
        <Form validations={validations} onSubmit={({ email }) => handleSubmit(email)}>
          <FormLabel mt={4} htmlFor="email">
            Email address
          </FormLabel>
          <EmailInput id="email" name="email" ref={inputRef} />
          <Button width="100%" type="submit" mt={4} variant="primary" isLoading={loading}>
            Next
          </Button>
        </Form>
        {error?.message && <FormWarning>{error?.message}</FormWarning>}
      </Wrapper>
    </>
  )
}

export const EmailAddress = React.memo(UnmemoizedEmailAddress)
