export const ROUTES = {
    PRODUCT: {
        label: 'Product',
        href: '/'
    },
    INTEGRATIONS: {
        label: 'Integrations',
        href: '/product/integrations'
    },
    CUSTOMER_STORIES: {
        label: 'Customer Stories',
        href: '/resources/customer-stories'
    },
    TESTIMONIALS: {
        label: 'Testimonials',
        href: '/resources/testimonials'
    },
    LOGISTICS_TRAINING_GUIDE: {
        label: 'Logistics Training Guide',
        isExternalLink: true,
        href: 'https://logistics.chaineapp.com/',
        target: '_blank'
    },
    COMPANY: {
        label: 'Company',
        href: '/company'
    },
    TERMS: {
        label: 'Terms of Service',
        href: '/terms'
    },
    PRIVACY: {
        label: 'Privacy Policy',
        href: '/privacy'
    },
    TALK_TO_SALES: {
        label: 'Talk to Sales',
        href: '/talk-to-sales'
    },
    CARRIER_VISIBILITY_ONBOARDING: {
        label: 'Carrier Onboarding',
        href: '/carrier-visibility-onboarding'
    },
    HOME: {
        label: 'Home',
        href: '/'
    },
    BLOG: {
        label: 'Blog',
        isExternalLink: true,
        href: 'https://blog.chaineapp.com/',
        target: '_blank'
    },
    CONTAINER_API: {
        label: 'Container API',
        isExternalLink: true,
        href: 'https://handbook.chaineapp.com/api/ocean-visibility',
        target: '_blank'
    },
    TRUCKLOAD_API: {
        label: 'Truckload API',
        isExternalLink: true,
        href: 'https://handbook.chaineapp.com/api/docs/in-house-tms/in-house-tms-integration',
        target: '_blank'
    },
    DEVELOPER_DOCS: {
        label: 'Docs',
        isExternalLink: true,
        href: 'https://handbook.chaineapp.com/api/home',
        target: '_blank'
    }
};
