import React from 'react';
import ReactConfetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { variants } from './settings';
export function Confetti({ ...props }) {
    const { width, height } = useWindowSize();
    let { variant } = props;
    const { onComplete } = props;
    if (!variant) {
        variant = 'swirl';
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactConfetti, { width: width, height: height, numberOfPieces: 150, recycle: false, gravity: 0.3, opacity: 0.5, tweenDuration: 6000, colors: variants[variant].color1, onConfettiComplete: onComplete }),
        React.createElement(ReactConfetti, { width: width, height: height, numberOfPieces: 1000, recycle: false, gravity: 0.3, tweenDuration: 6000, colors: variants[variant].color2 }),
        React.createElement(ReactConfetti, { width: width, height: height, numberOfPieces: 150, recycle: false, gravity: 0.3, opacity: 0.2, tweenDuration: 6000, colors: variants[variant].color3 })));
}
