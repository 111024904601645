import { Avatar, Box } from '@chaine/keychaine'
import React, { useState } from 'react'

import { Logo } from '../../../../_assets/icons/logo'
import { useAuth } from '../../../../auth/data/use-auth'

/**
 * Displays the header logo
 */
export const HeaderLogo = React.memo(() => {
  const { selectedWorkspace } = useAuth()
  const workspaceLogo = selectedWorkspace?.workspace?.logo
  const [isLogoLoaded, setLogoLoaded] = useState(false)

  return (
    <Box as="button" _hover={{ cursor: 'pointer' }}>
      {!isLogoLoaded && <Logo size="sm" color="white" />}
      {workspaceLogo && (
        <Avatar
          src={workspaceLogo}
          size="xs"
          variant="square"
          style={{ display: isLogoLoaded ? 'block' : 'none' }}
          onLoad={() => setLogoLoaded(true)}
        />
      )}
    </Box>
  )
})

HeaderLogo.displayName = 'HeaderLogo'
