import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControl, keychaine } from '../..';
import { validationSchemaBuilder } from './validations';
export function Form({ children, onSubmit, initialValues, validations, validationSchema, formProps }) {
    const schema = validationSchema
        ? validationSchema
        : validations
            ? validationSchemaBuilder(children, validations)
            : null;
    const methods = useForm({
        resolver: schema ? yupResolver(schema) : undefined,
        reValidateMode: 'onChange',
        defaultValues: initialValues ?? {}
    });
    const { handleSubmit, register, formState: { errors }, control } = methods;
    const consolidatedChildren = [];
    if (children && children.length > 0) {
        children.map((child) => {
            if (Array.isArray(child?.props?.children)) {
                child.props.children.map((grandchild) => consolidatedChildren.push(grandchild));
            }
            else {
                consolidatedChildren.push(child);
            }
        });
    }
    return (React.createElement(FormProvider, { ...methods },
        React.createElement(FormControl, null,
            React.createElement(keychaine.form, { onSubmit: handleSubmit(onSubmit), noValidate: true }, Array.isArray(consolidatedChildren)
                ? consolidatedChildren.map((child) => {
                    let { props } = child;
                    props = omitProps(props);
                    return props?.name
                        ? React.createElement(child.type, {
                            ...{
                                ...props,
                                ...formProps,
                                control,
                                register: register,
                                errors,
                                ref: child?.ref,
                                key: props?.name
                            }
                        })
                        : child;
                })
                : children))));
}
export function FormStep({ children, onSubmit, methods }) {
    const { handleSubmit, register, formState: { errors }, control } = methods;
    const consolidatedChildren = [];
    if (children && children.length > 0) {
        children.map((child) => {
            if (Array.isArray(child?.props?.children)) {
                child.props.children.map((grandchild) => consolidatedChildren.push(grandchild));
            }
            else {
                consolidatedChildren.push(child);
            }
        });
    }
    return (React.createElement(FormProvider, { ...methods },
        React.createElement(FormControl, null,
            React.createElement(keychaine.form, { onSubmit: handleSubmit(onSubmit), noValidate: true }, Array.isArray(consolidatedChildren)
                ? consolidatedChildren.map((child) => {
                    let { props } = child;
                    props = omitProps(props);
                    return props?.name
                        ? React.createElement(child.type, {
                            ...{
                                ...props,
                                control,
                                register: register,
                                errors,
                                ref: child.ref,
                                key: props?.name
                            }
                        })
                        : child;
                })
                : children))));
}
const omitProps = (props) => {
    if (props?.placeHolder) {
        const { placeHolder, ...rest } = props;
        return { placeholder: placeHolder, ...rest };
    }
    return props;
};
