import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConnectionOpen, DefaultGenerics, StreamChat } from 'stream-chat'

import { chatClientService } from '../../_services'
import { StringUtils } from '../../_utils'
import { createGenericContext } from '../../_utils/create-generic-context'
import { tokenService } from '../../auth/data'
import { useAuth } from '../../auth/data/use-auth'
import { IChatUser } from '../data/types'

/**
 * All {@link IChatContext}
 * @chatClient Stream chat client connection
 * @userConnection user connection with stream chat
 * @disconnectUser disconnects the user connection with stream chat
 * @unreadCount is total number of unread messages
 * @searchUser searches the connected user
 * @setActiveChat sets the active chat
 * @activeChat is the active chat id
 */
type IChatContext = {
  chatClient: StreamChat
  disconnectUser: () => void
  getUsersContacts: (userID: string) => Promise<IChatUser[] | []>
  searchUser: (inputValue: string) => Promise<IChatUser[] | []>
  unreadCount: number
  userConnection: ConnectionOpen<DefaultGenerics> | null
}

/**
 * Generate context the {@link proper https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947} way
 * ChatContext - react context {@link React.createContext}
 * {@link https://reactjs.org/docs/context.html}
 */
const [useChat, ChatContextProvider] = createGenericContext<IChatContext>(
  'useChat must be used within the ChatContextProvider'
)

interface ChatProviderProps {
  children: React.ReactNode
}

const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [userConnection, setUserConnection] = React.useState<ConnectionOpen<DefaultGenerics> | null>(null)
  const [unreadCount, setUnreadCount] = React.useState<number>(0)
  const { userDetails } = useAuth()
  const userChatToken = tokenService.getToken('User-Chat-Token')
  const navigate = useNavigate()

  /**
   * handleConnectUser - This method is used to connect the user to stream chat client
   * You should always make sure to have the user set before making any more calls. All SDKs make this very easy and wait or queue requests until then.
   * {@link https://getstream.io/chat/docs/react/init_and_users/}
   */
  const connectUser = useCallback(async (): Promise<void> => {
    try {
      if (!userConnection && userChatToken && userDetails?.id) {
        const connection = await chatClientService.client.connectUser(
          {
            id: userDetails.id || '',
            image: userDetails.photo || '',
            name: userDetails.name || ''
          },
          userChatToken
        )

        chatClientService.client.on(async (event) => {
          if (event.total_unread_count !== null && event.total_unread_count !== undefined) {
            setUnreadCount(event.total_unread_count || 0)
          }
        })

        if (connection) {
          setUnreadCount(connection?.me?.total_unread_count || 0)
          setUserConnection(connection)
        }
      }
    } catch (e) {
      //temporarily leaving this log
      console.warn('Error in connectUser', e)
    }
  }, [userChatToken, userConnection, userDetails?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * disconnectUser: This method is used to disconnected the user connection.
   * {@link https://getstream.io/chat/docs/react/logout/}
   */
  const disconnectUser: IChatContext['disconnectUser'] = useCallback((): void => {
    try {
      chatClientService.client.disconnectUser()
      setUserConnection(null)
    } catch (e) {
      //temporarily leaving this log
      console.warn('Error in disconnectUser', e)
    }
  }, [])

  const searchUser = useCallback(
    async (inputValue: string) => {
      const channels = await chatClientService.client.queryChannels({
        $and: [{ members: { $in: [userDetails.id] } }, { type: 'messaging' }]
      })

      const users: IChatUser[] = []
      channels.map((channel) => {
        const connectedUserID = Object.keys(channel.state.members).filter((key) => key !== userDetails.id)[0]

        const userData = channel.state.members[connectedUserID].user as IChatUser
        if (!userData?.deleted) {
          if (StringUtils.isEmail(inputValue) && userData?.email === inputValue) users.push(userData)
          else if (StringUtils.isPhone(inputValue) && userData?.phone === inputValue) users.push(userData)
          else if (userData?.name?.toLowerCase().includes(inputValue.toLowerCase())) users.push(userData)
        }
      })
      return users
    },
    [userDetails]
  )

  /**
   * This function is to be used temporarily to get a user's "contacts".  Ideally, we want to implement a backend search for this, but this will be sufficient for now.
   *
   * @param userID the user's ID that you are getting the contacts for
   */
  const getUsersContacts = useCallback(
    async (userID: string) => {
      try {
        const channels = await chatClientService.client.queryChannels({
          $and: [{ members: { $in: [userID] } }, { type: 'messaging' }]
        })

        const users: IChatUser[] = []
        channels.map((channel) => {
          const connectedUserID = Object.keys(channel.state.members).filter((key) => key !== userDetails.id)[0]

          const userData = channel.state.members[connectedUserID].user as IChatUser
          if (!userData?.deleted) {
            users.push(userData)
          }
        })
        return users.filter((c) => c.username).map((value) => ({ ...value, userID: value.id }))
      } catch (e) {
        return []
      }
    },
    [userDetails]
  )

  /**
   * call handle connect user method - To connect the user to stream chat client
   * @returns () handleDisconnectUser - call this method when component removes from the DOM.
   */
  useEffect(() => {
    if (userChatToken && userDetails?.id) {
      connectUser()
    }
    return () => {
      if (userConnection && !userChatToken && userDetails?.id) {
        disconnectUser()
      }
    }
  }, [userChatToken, userDetails?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  /** Initialize the chat client listeners */
  useEffect(() => {
    chatClientService.addListeners()

    return () => {
      chatClientService.removeListeners()
    }
  }, [])

  /** Set the navigate instance in the chatClientService */
  useEffect(() => {
    chatClientService.setNavigateInstance(navigate)
  }, [navigate])

  return (
    <ChatContextProvider
      value={{
        chatClient: chatClientService.client,
        disconnectUser,
        getUsersContacts,
        searchUser,
        unreadCount,
        userConnection
      }}
    >
      {children}
    </ChatContextProvider>
  )
}

export { ChatContextProvider, ChatProvider, useChat }
