import { UserRole } from '.'
import { IWorkspace } from './workspace-types'

export enum MembershipAccessType {
  ADMIN = 'Admin',
  DRIVER = 'Driver',
  MEMBER = 'Member',
  OWNER = 'Owner'
}

export enum MembershipRole {
  CarrierSalesRep = 'CarrierSalesRep',
  CustomerSalesRep = 'CustomerSalesRep',
  Dispatcher = 'Dispatcher',
  Driver = 'Driver',
  OwnerOperator = 'OwnerOperator',
  ShipperSalesRep = 'ShipperSalesRep'
}

export enum MembershipStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  INVITED = 'Invited'
}

export interface IMembership {
  accessType?: MembershipAccessType
  role?: MembershipRole | UserRole
  status?: MembershipStatus
  userID?: string
  workspace?: IWorkspace
  workspaceID?: string
}

export interface TypeSafeMembership {
  accessType: MembershipAccessType
  role: MembershipRole
  status: MembershipStatus
  userID: string
  workspace: IWorkspace
  workspaceID: string
}
