import React, { lazy, Suspense } from 'react'
import { Navigate } from 'react-router-dom'

import { AccountSettingsPage } from '../../settings'
import { RequireWorkspaceValidation } from '../hoc/protected-workspace-route'
import { lazyRetry } from '../lazy-retry'
import { ROUTES } from '../routes-list'

const DevelopersPage = lazy(() => lazyRetry(() => import('../../settings/developer/developer-page'), 'DevelopersPage'))
const LogsPage = lazy(() => lazyRetry(() => import('../../settings/logging/logs-page'), 'LogsPage'))
const IntegrationsPage = lazy(() =>
  lazyRetry(() => import('../../settings/integrations/integrations-page'), 'IntegrationsPage')
)
const MembersPage = lazy(() => lazyRetry(() => import('../../settings/members/members-page'), 'MembersPage'))
const UsagePage = lazy(() => lazyRetry(() => import('../../settings/reports/usage-page'), 'UsagePage'))
const UserProfilePage = lazy(() =>
  lazyRetry(() => import('../../settings/user-profile/user-profile-page'), 'UserProfilePage')
)
const WorkspacePage = lazy(() => lazyRetry(() => import('../../settings/workspace/workspace-page'), 'WorkspacePage'))
const Workspaces = lazy(() => lazyRetry(() => import('../../settings/workspace-list/workspace-page'), 'Workspaces'))
const AppsPage = lazy(() => lazyRetry(() => import('../../settings/apps/apps-page'), 'AppsPage'))
const CopilotPage = lazy(() => lazyRetry(() => import('../../settings/copilot/copilot-page'), 'CopilotPage'))

export const myAccountSettingsRoutes = () => {
  return [
    {
      children: [
        { element: <Navigate to={ROUTES.PROFILE} />, index: true },
        {
          element: (
            <Suspense>
              <UserProfilePage />
            </Suspense>
          ),
          path: ROUTES.PROFILE
        },
        {
          element: (
            <Suspense>
              <Workspaces />
            </Suspense>
          ),
          path: ROUTES.WORKSPACES
        }
      ],
      element: <AccountSettingsPage />,
      path: ROUTES.ACCOUNT
    }
  ]
}

export const workspaceAccountSettingsRoutes = () => {
  return [
    {
      children: [
        { element: <Navigate to={ROUTES.WORKSPACE} />, index: true },
        {
          element: (
            <Suspense>
              <WorkspacePage />
            </Suspense>
          ),
          path: ROUTES.WORKSPACE
        },
        {
          element: (
            <Suspense>
              <DevelopersPage />
            </Suspense>
          ),
          path: ROUTES.DEVELOPERS
        },
        {
          element: (
            <Suspense>
              <AppsPage />
            </Suspense>
          ),
          path: ROUTES.APPS
        },
        {
          element: (
            <Suspense>
              <CopilotPage />
            </Suspense>
          ),
          path: ROUTES.COPILOT
        },
        {
          element: (
            <Suspense>
              <LogsPage />
            </Suspense>
          ),
          path: ROUTES.LOGS
        },
        {
          element: (
            <Suspense>
              <IntegrationsPage />
            </Suspense>
          ),
          path: ROUTES.INTEGRATIONS
        },
        {
          element: (
            <Suspense>
              <MembersPage />
            </Suspense>
          ),
          path: ROUTES.MEMBERS
        },
        {
          element: (
            <Suspense>
              <UsagePage />
            </Suspense>
          ),
          path: ROUTES.USAGE
        }
      ],
      element: (
        <RequireWorkspaceValidation>
          <Suspense>
            <AccountSettingsPage />
          </Suspense>
        </RequireWorkspaceValidation>
      ),
      path: `/:workspace_name/${ROUTES.ACCOUNT}`
    }
  ]
}
