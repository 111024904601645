import { CheckCircleFillIcon, CommentDiscussionIcon, GearIcon } from '@chaine/keychaine'

import { ROUTES } from '../../../../_routes/routes-list'

export const items = {
  assignedToMe: {
    icon: CheckCircleFillIcon,
    label: 'Assigned',
    path: ROUTES.ASSIGNED_TO_ME
  },

  inbox: {
    icon: CommentDiscussionIcon,
    label: 'Inbox',
    path: ROUTES.INBOX
  },
  settings: {
    icon: GearIcon,
    label: 'Settings',
    parentPath: ROUTES.ACCOUNT,
    path: ROUTES.ACCOUNT
  }
}
