import React from 'react'

import { createGenericContext } from '../../../_utils/create-generic-context'

interface ISafeAreaContext {
  paddingBottom: string
  paddingLeft: string
  paddingRight: string
  paddingTop: string
  /* cspell:disable-next-line */
  topheight: number
}

interface ISafeAreaInsetsProvider {
  children?: React.ReactNode
}

const [useSafeAreaContext, StateContextProvider] = createGenericContext<ISafeAreaContext>()

export const useSafeAreaInsetsState = () => {
  const context = useSafeAreaContext()

  if (context === undefined) throw new Error("Cannot use 'useSafeAreaInsetsState' outside of a SafeAreaInsetsProvider!")

  return context
}

const SafeAreaInsetsProvider = ({ children }: ISafeAreaInsetsProvider) => {
  const [state] = React.useState({
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    /* cspell:disable-next-line */
    topheight: 0
  })

  return <StateContextProvider value={state}>{children}</StateContextProvider>
}

export default SafeAreaInsetsProvider

// https://stackblitz.com/edit/nextjs-gobv75?file=src%2Findex.js,src%2FSafeAreaView%2FuseSafeAreaValues.js
