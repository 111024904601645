import { useToast } from '@chaine/keychaine'

export interface IToastProps {
  description?: string
  duration?: number
  isClosable?: boolean
  onCloseComplete?: () => void
  position?: 'top' | 'top-right' | 'top-left' | 'bottom' | 'bottom-right' | 'bottom-left'
  status: IToastStatus
  title: string
  variant?: 'solid' | 'subtle' | 'left-accent' | 'top-accent'
}

export type IToastStatus = 'info' | 'warning' | 'success' | 'error'

export function Toast() {
  const toast = useToast()
  const showToast = (props: IToastProps) => {
    const {
      title,
      description,
      status = 'info',
      duration = 5000,
      isClosable = true,
      onCloseComplete,
      position = 'top',
      variant = 'subtle'
    } = props

    return toast({
      description: description,
      duration: duration,
      isClosable: isClosable,
      onCloseComplete: onCloseComplete,
      position: position,
      status: status,
      title: title,
      variant: variant
    })
  }

  return {
    showToast
  }
}
