import { CaptureOptions, posthog, Properties, RequestCallback } from 'posthog-js'

import { Workspace } from '../../auth/data/auth-types'

/**
 * Initializes the analytics api. Use at the top level of your application
 */

// const localHost = 'localhost:8080'
export const init = () => {
  posthog.init(process.env.VITE_ANALYTICS_KEY || '', {
    api_host: 'https://app.posthog.com',
    autocapture: false,
    disable_session_recording: true,
    loaded: (posthogInstance) => {
      /** Only start posthog recording for the production environment */
      posthogInstance.onFeatureFlags(() => {
        if (posthogInstance.isFeatureEnabled('record-na')) posthogInstance.startSessionRecording()
      })
    }
  })
}

/**
 * All {@link IAnalyticsContext}
 * @identify Identifies a user with their unique ID and other properties. If the method is never called, then unique visitors will be identified by a UUID (anonymous_id) generated the first time they visit the site.
 * @capture Manually makes a 'pageview' for specific components.
 * @logout Un-sets the user's distinct_id. This must be done at logout.
 * @people Various functions used on "people"
 *
 */
export interface IAnalyticsContext {
  /**
   * Capture an event and allows you to send more context than the default event info that PostHog captures whenever a user does something
   *
   * ### Usage:
   *
   *     // capture an event named 'Registered'
   *     posthog.capture('Registered', {'Gender': 'Male', 'Age': 21})
   *
   *     // you can also use the $set to set user properties instead of event properties
   *     posthog.capture('some event', { $set: { userProperty: 'value' }})
   *
   *     // capture an event using navigator.sendBeacon
   *     posthog.capture('Left page', {'duration_seconds': 35}, {transport: 'sendBeacon'})
   *
   *
   * @param eventName The name of the event. This can be anything the user does - 'Button Click', 'Sign Up', 'Item Purchased', etc.
   * @param properties A set of {@link Properties} to include with the event you're sending. These describe the user who did the event or details about the event itself.
   * @param options Optional configuration {@link CaptureOptions} for this capture request.
   */
  captureEvent: (eventName: string, properties?: Properties | null, options?: CaptureOptions) => void
  /**
   * Manually makes a 'pageview' for specific components.
   * The JS snippet automatically sends pageview events whenever it gets loaded. If you have a one-page app, that means it'll only send a pageview once, when your app loads.
   * To make sure any navigating a user does within your app gets captured, you can make a pageview call manually.
   * This will automatically send the current URL.
   * @param page the name of the page in kebab case. ex. 'my-page'
   */
  capturePageview: (page: string) => void
  /**
   * Identifies a user with their unique ID and other properties. If the method is never called, then unique visitors will be identified by a UUID (anonymous_id) generated the first time they visit the site.
   * If user properties are passed, they are also sent to posthog.
   * @param userID a user's distinct user id
   * @param property1 optional property that uses $set i.e. {userProperty: 'value1'}
   * @param property2 optional property that uses $set_once i.e. {anotherUserProperty: 'value2'}
   */
  identify: (userID: string, property1?: { ['string']: string }, property2?: { ['string']: string }) => void
  /**
   * Sets the user selected workspace as a group in posthog & adds the user to the group
   * @param workspaceID the workspace id of the selected workspace
   * @param workspaceName the workspace name of the selected workspace
   * {@link https://posthog.com/docs/product-analytics/group-analytics}
   */
  identifyWorkspace(workspace: Workspace): void

  isFeatureEnabled: (flag: string) => boolean

  /**
   * Used to set a user's metadata. You can make this call on every page view to make sure this information is up-to-date. Alternatively, you can also do this whenever a user first appears (after signup) or when they change their information.
   * @param property User's property to update. For example: { email: 'john@gmail.com' }
   * @param to
   * @param callback
   */
  setUserProperty: People['setUserProperty']
}

export const identify = (userID: string, property1?: { ['string']: string }, property2?: { ['string']: string }) => {
  posthog.identify(userID, property1, property2)
}

/**
 * Un-sets the user's distinct_id. This must be done at logout.
 */
export const logout = () => {
  posthog.reset(true)
}

export const capturePageview = (page: string) => {
  posthog.capture('$pageview', { page: page })
}

export const captureEvent = (eventName: string, properties?: Properties | null, options?: CaptureOptions) => {
  posthog.capture(eventName, properties, options)
}

export const identifyWorkspace = (workspace: Workspace) => {
  posthog.group('workspace', workspace.id, {
    displayName: workspace.displayName,
    isVerified: workspace.isVerified,
    name: workspace.workspacename,
    workspaceID: workspace.id
  })
}

export const isFeatureEnabled = (flag: string) => {
  let value = false
  posthog.onFeatureFlags(function () {
    value = posthog.isFeatureEnabled(flag)
  })
  return value
}

/**
 * Various functions used on "people"
 */
interface People {
  setUserProperty: (
    property: string | Properties,
    to?: string | undefined,
    callback?: RequestCallback | undefined
  ) => void
}

export const people: People = {
  setUserProperty: (property: string | Properties, to?: string | undefined, callback?: RequestCallback | undefined) => {
    posthog.people.set(property, to, callback)
  }
}
