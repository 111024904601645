import { Progress, VStack } from '@chaine/keychaine'
import React, { createContext, ReactElement, useContext, useEffect, useRef, useState } from 'react'

type ProviderProps = {
  children: ReactElement | ReactElement[]
}

type ProgressProps = {
  done: () => void
  start: () => void
  value: number
}

const LoadingProgressContext = createContext<ProgressProps>({
  done: () => {
    // do nothing
  },
  start: () => {
    // do nothing
  },
  value: 0
})

export const useLoadingProgress = (): ProgressProps => {
  return useContext<ProgressProps>(LoadingProgressContext)
}

const LoadingProgress = () => {
  const { value } = useLoadingProgress()

  return (
    <VStack align="flex-end" position="absolute" top={0} left={0} right={0}>
      <Progress value={value} size="xs" width="100%" />
    </VStack>
  )
}

export const LoadingProgressProvider = ({ children }: ProviderProps): ReactElement => {
  const step = useRef(5)
  const [value, setValue] = useState(0)
  const [isOn, setOn] = useState(false)

  useEffect(() => {
    if (isOn) {
      let timeout: number | NodeJS.Timeout = 0

      if (value < 20) {
        step.current = 5
      } else if (value < 40) {
        step.current = 4
      } else if (value < 60) {
        step.current = 3
      } else if (value < 80) {
        step.current = 2
      } else {
        step.current = 1
      }

      if (value <= 98) {
        timeout = setTimeout(() => {
          setValue(value + step.current)
        }, 500)
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout as number)
        } else return
      }
    } else return
  }, [value, isOn])

  const start = () => {
    setValue(0)
    setOn(true)
  }

  const done = () => {
    setValue(100)
    setTimeout(() => {
      setOn(false)
    }, 200)
  }

  return (
    <LoadingProgressContext.Provider
      value={{
        done,
        start,
        value
      }}
    >
      {isOn ? <LoadingProgress /> : <></>}
      {children}
    </LoadingProgressContext.Provider>
  )
}
