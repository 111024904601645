import { mode } from '../..';
export const modalBaseStyle = (props) => {
    const { isCentered, scrollBehavior } = props;
    return {
        display: 'flex',
        zIndex: 'modal',
        justifyContent: 'center',
        alignItems: isCentered ? 'center' : 'flex-start',
        overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
        enter: {
            visibility: 'visible',
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.2,
                ease: [0.4, 0, 0.2, 1]
            }
        },
        exit: {
            transitionEnd: {
                visibility: 'hidden'
            },
            opacity: 0,
            scale: 0.8,
            transition: {
                duration: 0.1,
                easings: 'easeOut'
            }
        }
    };
};
export const modalOverlayBaseStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'rgba(30, 30, 30, 0.90)' : 'rgba(0,0,0,0.55)',
        zIndex: 'modal'
    };
};
export const modalContentBaseStyle = (props) => {
    const { scrollBehavior, colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'gray.900' : 'white',
        borderRadius: '2xl',
        color: 'inherit',
        my: '3.75rem',
        zIndex: 'modal',
        maxH: scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : undefined,
        boxShadow: mode('base', 'xl')(props)
    };
};
export const modalHeaderBaseStyle = ({ colorMode }) => ({
    color: colorMode === 'dark' ? 'gray.100' : 'grayDark',
    textAlign: 'center',
    fontSize: '1.1875rem',
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: '0.3px',
    lineHeight: '1.875rem',
    px: 6
});
export const modalCloseButtonBaseStyle = ({ colorMode }) => ({
    color: colorMode === 'dark' ? 'gray.500' : 'gray.700',
    _hover: { bg: colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.100' },
    position: 'absolute',
    top: 4,
    insetEnd: 5,
    borderRadius: 'md'
});
export const modalBodyBaseStyle = (props) => {
    const { scrollBehavior } = props;
    return {
        px: 6,
        pb: 6,
        flex: 1,
        overflow: scrollBehavior === 'inside' ? 'auto' : undefined
    };
};
