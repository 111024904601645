import { mode } from '../..';
const isWindows = /Windows|Win32|Win64/.test(navigator.userAgent);
const globalWindowsScrollBarStyles = (props) => {
    return isWindows
        ? {
            '*::-webkit-scrollbar': {
                width: '.4em',
                'background-color': 'rgba(0, 0, 0, 0)',
                overflow: 'visible',
                'border-radius': '4px'
            },
            '*::-webkit-scrollbar-track': {
                background: 'rgba(0, 0, 0, 0)'
            },
            '*::-webkit-scrollbar-thumb': {
                overflow: 'visible',
                'border-radius': '4px',
                'background-color': mode('rgba(0,0,0,.2)', 'rgba(255,255,255,.2)')(props)
            },
            '*::-webkit-scrollbar-thumb:hover': {
                'background-color': mode('rgba(0,0,0,.2)', 'rgba(255,255,255,.2)')(props),
                'border-radius': '4px',
                '-webkit-transition': 'all .5s'
            },
            '*': {
                'scrollbar-width': 'thin',
                'scrollbar-color': mode('rgba(0,0,0,.2)', 'rgba(255,255,255,.2)')(props)
            },
            '*::-webkit-scrollbar-thumb:active': {
                'background-color': mode('rgba(0,0,0,.2)', 'rgba(255,255,255,.2)')(props)
            }
        }
        : {};
};
export default {
    global: (props) => ({
        body: {
            fontFamily: 'body',
            color: mode('gray.900', 'whiteAlpha.900')(props),
            bg: mode('white', 'gray.900')(props),
            lineHeight: 'base'
        },
        h1: {
            fontFamily: 'heading',
            fontSize: '2rem'
        },
        h2: {
            fontFamily: 'heading',
            fontSize: '1.5rem'
        },
        h3: {
            fontFamily: 'heading',
            fontSize: '1.188rem'
        },
        h4: {
            fontFamily: 'heading',
            fontSize: '1rem'
        },
        h5: {
            fontFamily: 'heading',
            fontSize: '0.875rem'
        },
        h6: {
            fontFamily: 'heading',
            fontSize: '0.6875rem'
        },
        '*::placeholder': {
            opacity: 1,
            color: 'muted'
        },
        '*, *::before, &::after': {
            borderColor: mode('gray.200', 'gray.700')(props)
        },
        'html,body': {
            height: '100%'
        },
        '#__next, #root': {
            display: 'flex',
            flexDirection: 'column',
            minH: '100%'
        },
        ...globalWindowsScrollBarStyles(props)
    })
};
