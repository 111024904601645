import { Box, Flex, Link, Text, useColorModeValue as mode } from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'

import { browserNotificationService } from '../../../../_services'

const UnmemoizedBrowserPermissionBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false)
  const primaryTextColor = mode('white', 'black')
  useEffect(() => {
    if (window.Notification && (Notification.permission === 'granted' || Notification.permission === 'denied')) return
    setIsBannerVisible(true)
  }, [])

  const bannerBgcolor = mode('gray.800', 'gray.200')

  function grantPermission() {
    if (window.Notification && Notification.permission === 'granted') {
      browserNotificationService.create({
        title: 'You are already subscribed to web notifications'
      })

      setIsBannerVisible(false)
      return
    }
    if (window.Notification && Notification.permission === 'default') {
      Notification.requestPermission().then((result) => {
        if (result === 'granted') {
          browserNotificationService.create({
            body: 'Nice, notifications are now enabled!',
            title: 'New message from Chaine'
          })
          setIsBannerVisible(false)
        }
        if (result === 'denied') setIsBannerVisible(false)
      })
    }
  }

  return (
    <>
      {isBannerVisible && (
        <Flex
          bottom="0"
          left="0"
          right="0"
          p="10px"
          bg={bannerBgcolor}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Box ml="4">
            <Text color={primaryTextColor}>
              To get notified on new messages and offers, &nbsp;
              <Link onClick={grantPermission} fontWeight="bold" textDecoration="underline">
                enable desktop notifications
              </Link>
            </Text>
          </Box>
        </Flex>
      )}
    </>
  )
}

export const BrowserPermissionBanner = React.memo(UnmemoizedBrowserPermissionBanner)
