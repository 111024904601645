import {
  Box,
  Divider,
  Flex,
  HStack,
  MailIcon,
  MenuIcon,
  MenuIconExpanded,
  MilestoneIcon,
  octicons,
  PeopleIcon,
  Stack,
  TagIcon,
  useColorModeValue as mode,
  useMediaQuery
} from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'

import { ROUTES } from '../../../_routes/routes-list'
import { CompanyType } from '../../../_shared/components/setup-workspace/types'
import { useAuth } from '../../../auth/data/use-auth'
import { NavGroup } from './nav-group'
import { NavLink } from './nav-link'

interface SideBarMenuItem {
  icon: octicons.Icon
  name: string
  path: string
}
interface SideBarMenu {
  general: SideBarMenuItem[]
  workspace: SideBarMenuItem[]
}

/**
 * The side menu for the entire booking section
 */
export const SideMenu = () => {
  const { selectedWorkspace } = useAuth()

  const generalMenuOptions = selectedWorkspace
    ? selectedWorkspace.workspace.workspaceType === CompanyType.Broker
      ? [
          {
            icon: MilestoneIcon,
            name: 'My posts',
            path: ROUTES.MY_POSTS
          },
          {
            icon: MailIcon,
            name: 'Inbox',
            path: ROUTES.INBOX
          }
        ]
      : [
          // {
          //   name: 'Available loads',
          //   path: ROUTES.HOME,
          //   icon: HomeIcon
          // },
          {
            icon: TagIcon,
            name: 'Offers you made',
            path: ROUTES.OFFERS
          },
          {
            icon: MailIcon,
            name: 'Inbox',
            path: ROUTES.INBOX
          }
        ]
    : [
        {
          icon: TagIcon,
          name: 'Offers you made',
          path: ROUTES.OFFERS
        },
        {
          icon: MailIcon,
          name: 'Inbox',
          path: ROUTES.INBOX
        }
      ]

  const [generalMenu] = useState<SideBarMenu['general']>(generalMenuOptions)

  const [workspaceMenu, setWorkspaceMenu] = useState<SideBarMenu['workspace']>([])

  useEffect(() => {
    if (selectedWorkspace?.workspace?.workspacename) {
      const activeWorkspace = selectedWorkspace.workspace.workspacename

      const workspaceMenuOptions =
        selectedWorkspace.workspace.workspaceType === CompanyType.Broker
          ? [
              {
                icon: TagIcon,
                name: 'Offers',
                path: `${activeWorkspace}/${ROUTES.OFFERS}`
              },
              {
                icon: MilestoneIcon,
                name: 'All posts',
                path: `${activeWorkspace}/${ROUTES.POSTS}`
              },
              {
                icon: PeopleIcon,
                name: 'My network',
                path: `${activeWorkspace}/${ROUTES.NETWORK}`
              }
            ]
          : [
              {
                icon: PeopleIcon,
                name: 'My network',
                path: `${activeWorkspace}/${ROUTES.NETWORK}`
              }
              // {
              //   name: 'All offers',
              //   path: `/${activeWorkspace}/${ROUTES.OFFERS}`,
              //   icon: TagIcon
              // }
            ]

      setWorkspaceMenu(workspaceMenuOptions)
    }
  }, [selectedWorkspace])

  const [isLargerThan480] = useMediaQuery('(min-width: 480px)')

  const [navSize, changeNavSize] = React.useState(`${isLargerThan480 ? 'expanded' : 'contracted'}`)
  const bg = mode('gray.50', 'gray.900')
  const color = mode('grayDark', 'gray.100')
  const toggleSideNav = () => {
    if (navSize == 'contracted') changeNavSize('expanded')
    else changeNavSize('contracted')
  }
  return (
    <Flex
      as="nav"
      direction="column"
      transition="all 1s"
      color={color}
      bg={bg}
      fontSize="0.875em"
      borderRightWidth="1px"
      p={4}
      height="100%"
      w="max-content"
      transitionDuration="fast"
      aria-label="side-menu"
    >
      <HStack h={8} justifyContent={navSize === 'expanded' ? 'right' : 'center'} pl={navSize === 'expanded' ? 1 : 0}>
        {navSize === 'expanded' ? (
          <>
            <Box onClick={toggleSideNav} _hover={{ cursor: 'pointer' }}>
              <MenuIconExpanded
                aria-label="menu-icon-expanded"
                boxSize={8}
                color={'gray.500'}
                _hover={{ bg: mode('gray.100', 'whiteAlpha.200'), borderRadius: 'base' }}
              />
            </Box>
          </>
        ) : (
          <Box onClick={toggleSideNav} _hover={{ cursor: 'pointer' }}>
            <MenuIcon
              aria-label="menu-icon-contracted"
              boxSize={8}
              color={'gray.500'}
              _hover={{ bg: mode('gray.100', 'whiteAlpha.200'), borderRadius: 'base' }}
            />
          </Box>
        )}
      </HStack>

      <Stack mt={4} w="max-content">
        <NavGroup menuHeadingExpanded="" menuHeadingContracted="" navSize={navSize}>
          {generalMenu.map((link: SideBarMenuItem) => (
            <NavLink
              key={link.name}
              title={link.name}
              path={link.path}
              icon={link.icon}
              navSize={navSize}
              activeOnlyWhenExact={true}
            />
          ))}
        </NavGroup>

        {workspaceMenu.length > 0 && (
          <>
            <Divider />
            <NavGroup menuHeadingExpanded="Workspace" menuHeadingContracted="WS" navSize={navSize}>
              {workspaceMenu.map((link: SideBarMenuItem) => (
                <NavLink
                  key={link.name}
                  title={link.name}
                  path={link.path}
                  icon={link.icon}
                  navSize={navSize}
                  activeOnlyWhenExact={true}
                />
              ))}
            </NavGroup>
          </>
        )}
      </Stack>
    </Flex>
  )
}
