import { forwardRef } from '@chakra-ui/system';
import { __DEV__, cx } from '@chakra-ui/utils';
import * as React from 'react';
import { keychaine as chakra, StylesProvider, useColorModeValue as mode, useImage, useMultiStyleConfig, useStyles } from '../..';
import { getBorderStyles } from './logic';
export const AvatarBadge = forwardRef((props, ref) => {
    const styles = useStyles();
    const badgeStyles = {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        insetEnd: '0',
        bottom: '0',
        ...styles.badge
    };
    return React.createElement(chakra.div, { ref: ref, ...props, className: cx('chakra-avatar__badge', props.className), __css: badgeStyles });
});
if (__DEV__) {
    AvatarBadge.displayName = 'AvatarBadge';
}
function initials(name) {
    const [firstName, lastName] = name.split(' ');
    return firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : firstName.charAt(0);
}
const AvatarName = (props) => {
    const { name, getInitials, ...rest } = props;
    const styles = useStyles();
    return (React.createElement(chakra.div, { role: "img", "aria-label": name, ...rest, __css: styles.label }, name ? getInitials?.(name) : null));
};
const DefaultIcon = (props) => (React.createElement(chakra.svg, { viewBox: "0 0 128 128", color: "#fff", width: "100%", height: "100%", className: "chakra-avatar__svg", ...props },
    React.createElement("path", { fill: "currentColor", d: "M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z" }),
    React.createElement("path", { fill: "currentColor", d: "M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24" })));
export const baseStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'medium',
    position: 'relative',
    flexShrink: 0
};
export const Avatar = forwardRef((props, ref) => {
    const styles = useMultiStyleConfig('Avatar', props);
    const { src, name, showBorder, onError, getInitials = initials, icon = React.createElement(DefaultIcon, null), iconLabel = ' avatar', loading, children, ignoreFallback, ...rest } = props;
    const { borderRadius, boxShadow } = getBorderStyles(props.size, props.variant, props.borderRadius, props.boxShadow);
    const avatarStyles = {
        borderRadius,
        borderWidth: showBorder ? '0.125rem' : 'undefined',
        ...baseStyle,
        ...styles.container
    };
    avatarStyles.boxShadow = boxShadow;
    return (React.createElement(chakra.span, { ref: ref, ...rest, className: cx('chakra-avatar', props.className), __css: avatarStyles, bg: showBorder && src ? mode('white', 'gray.800') : undefined },
        React.createElement(StylesProvider, { value: styles },
            React.createElement(AvatarImage, { src: src, loading: loading, onError: onError, getInitials: getInitials, name: name, borderRadius: borderRadius, boxShadow: boxShadow, icon: icon, iconLabel: iconLabel, ignoreFallback: ignoreFallback }),
            children)));
});
if (__DEV__) {
    Avatar.displayName = 'Avatar';
}
const AvatarImage = (props) => {
    const { src, onError, getInitials, name, borderRadius, boxShadow, loading, iconLabel, icon = React.createElement(DefaultIcon, null), ignoreFallback } = props;
    const status = useImage({ src, onError, ignoreFallback });
    const hasLoaded = status === 'loaded';
    const showFallback = !src || !hasLoaded;
    if (showFallback) {
        return name ? (React.createElement(AvatarName, { className: "chakra-avatar__initials", getInitials: getInitials, name: name })) : (React.cloneElement(icon, {
            role: 'img',
            'aria-label': iconLabel
        }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(chakra.img, { src: src, alt: name, className: "chakra-avatar__img", loading: loading, __css: {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: borderRadius
            } }),
        React.createElement(chakra.div, { __css: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: borderRadius,
                boxShadow,
                top: 0,
                left: 0
            } })));
};
if (__DEV__) {
    AvatarImage.displayName = 'AvatarImage';
}
