import React, { lazy, Suspense } from 'react'

import { BookingPageLayout } from '../../booking/booking-layout'
import { BrokerRoute } from '../hoc/broker-route'
import { RequireWorkspaceValidation } from '../hoc/protected-workspace-route'
import { lazyRetry } from '../lazy-retry'
import { ROUTES } from '../routes-list'

const MyOffersPage = lazy(() => lazyRetry(() => import('../../booking/offers-made'), 'OffersMade'))

const NetworkPage = lazy(() => lazyRetry(() => import('../../booking/network-page'), 'Network'))

const OffersReceivedPage = lazy(() => lazyRetry(() => import('../../booking/offers-received'), 'OffersReceived'))

const UserPostsPage = lazy(() =>
  lazyRetry(() => import('../../booking/private-post-pages/users-posts-page'), 'MyPosts')
)

const WorkspacePostsPage = lazy(() =>
  lazyRetry(() => import('../../booking/private-post-pages/workspace-posts-page'), 'Posts')
)

export const bookingRoutes = () => {
  return [
    {
      children: [
        {
          element: (
            <RequireWorkspaceValidation>
              <BrokerRoute>
                <Suspense>
                  <WorkspacePostsPage />
                </Suspense>
              </BrokerRoute>
            </RequireWorkspaceValidation>
          ),
          path: `/:workspace_name/${ROUTES.POSTS}`
        },
        {
          element: (
            <RequireWorkspaceValidation>
              <BrokerRoute>
                <Suspense>
                  <OffersReceivedPage />
                </Suspense>
              </BrokerRoute>
            </RequireWorkspaceValidation>
          ),
          path: `/:workspace_name/${ROUTES.OFFERS}`
        },
        {
          element: (
            <RequireWorkspaceValidation>
              <Suspense>
                <NetworkPage />
              </Suspense>
            </RequireWorkspaceValidation>
          ),
          path: `/:workspace_name/${ROUTES.NETWORK}`
        },
        {
          element: (
            <Suspense>
              <UserPostsPage />
            </Suspense>
          ),
          path: `/my-posts`
        },
        {
          element: (
            <Suspense>
              <MyOffersPage />
            </Suspense>
          ),
          path: `${ROUTES.OFFERS}`
        }
      ],
      element: <BookingPageLayout />
    }
  ]
}
