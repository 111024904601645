import { Box, Flex, useColorModeValue as mode } from '@chaine/keychaine'
import * as React from 'react'

import { NavAction } from './nav-action'
import { items } from './nav-items'

const DesktopFooter = () => {
  return <footer>© 2021 Copyright. All rights reserved.</footer>
}

const MobileFooter = () => {
  const footerBG = mode('white', 'gray.900')
  return (
    <Box
      width="full"
      borderTopWidth={0.25}
      display={{ lg: 'none' }}
      justifyContent="end"
      marginBottom="0"
      position="relative"
    >
      <Flex
        px="2"
        paddingTop={2}
        justifyContent="space-around"
        position="fixed"
        bottom="0"
        minH="max-content"
        bg={footerBG}
        w="full"
      >
        {/* <NavAction.Mobile {...items.home} /> */}
        <NavAction.Mobile {...items.inbox} />
        <NavAction.Mobile {...items.assignedToMe} />
        {/* <NavAction.Mobile {...items.user} /> */}
        <NavAction.Mobile {...items.settings} />
      </Flex>
    </Box>
  )
}

export const Footer = {
  Desktop: DesktopFooter,
  Mobile: MobileFooter
}
