export const defaultColors = {
    brand: 'blue',
    black: '#02000A',
    white: '#FFFFFF',
    gray: {
        50: '#F5F5F6',
        100: '#ECECED',
        200: '#DADADB',
        300: '#BFBFC1',
        400: '#A4A3A6',
        500: '#807E83',
        600: '#535159',
        700: '#38363F',
        800: '#1D1B25',
        900: '#14121C'
    },
    blue: {
        50: '#F0F8FF',
        100: '#CCE4FF',
        200: '#99CAFF',
        300: '#66AFFF',
        400: '#3394FF',
        500: '#007AFF',
        600: '#006BDF',
        700: '#005BBF',
        800: '#004C9F',
        900: '#003D80'
    },
    green: {
        50: '#F0FFF4',
        100: '#D4F7DC',
        200: '#A9EDBA',
        300: '#81E299',
        400: '#5AD579',
        500: '#34C759',
        600: '#2BB04D',
        700: '#239941',
        800: '#1C8135',
        900: '#15692A'
    },
    teal: {
        50: '#F4FDFF',
        100: '#DBF5FF',
        200: '#B8EBFF',
        300: '#97E0FF',
        400: '#78D4FE',
        500: '#5AC8FA',
        600: '#4CB0DD',
        700: '#3F98BF',
        800: '#337F9F',
        900: '#276680'
    },
    yellow: {
        50: '#FFFDF0',
        100: '#FFF8CC',
        200: '#FFEF99',
        300: '#FFE566',
        400: '#FFD933',
        500: '#FFCC00',
        600: '#DFB500',
        700: '#BF9D00',
        800: '#9F8500',
        900: '#806B00'
    },
    orange: {
        50: '#FFF8F0',
        100: '#FFEBCC',
        200: '#FFD699',
        300: '#FFC166',
        400: '#FFAB33',
        500: '#FF9500',
        600: '#DF8300',
        700: '#BF7100',
        800: '#9F5F00',
        900: '#804C00'
    },
    red: {
        50: '#FFF2F2',
        100: '#FFD4D2',
        200: '#FFABA6',
        300: '#FF847D',
        400: '#FF5F55',
        500: '#FF3B30',
        600: '#DF3127',
        700: '#BF271F',
        800: '#9F1F17',
        900: '#801711'
    },
    purple: {
        50: '#F9F2FE',
        100: '#F0DAFB',
        200: '#E0B6F6',
        300: '#D093EF',
        400: '#C072E7',
        500: '#AF52DE',
        600: '#9A46C4',
        700: '#853AAA',
        800: '#6F2F8F',
        900: '#592474'
    },
    pink: {
        50: '#FFF3F8',
        100: '#FFE0ED',
        200: '#FFC2DA',
        300: '#FFA5C9',
        400: '#FF8AB7',
        500: '#FF70A6',
        600: '#DF6091',
        700: '#BF507C',
        800: '#9F4167',
        900: '#803352'
    },
    text: {
        light: {
            primary: '#26242D',
            secondary: '#77757A',
            tertiary: '#9B9A9D'
        },
        dark: {
            primary: '#ECECED',
            secondary: '#BFBFC1',
            tertiary: '#A4A3A6',
            quaternary: '#807E83'
        }
    },
    border: {
        light: { primary: '#DADADB', secondary: '#BFBFC1', tertiary: '#A4A3A6' },
        dark: { primary: '#38363F', secondary: '#535159', tertiary: '#807E83' }
    },
    icon: {
        light: {
            primary: '#38363F',
            secondary: '#807E83',
            tertiary: '#BFBFC1'
        },
        dark: {
            primary: '#DADADB',
            secondary: '#807E83',
            tertiary: '#535159'
        }
    },
    grayGradiant: {
        light: 'linear(gray.100, gray.200)',
        dark: 'none'
    },
    blueGradient: {
        light: 'linear(blue.400, blue.500)',
        dark: 'linear(blue.600, blue.700)'
    },
    whitefade: {
        10: 'rgba(255, 255, 255, 0.10)',
        15: 'rgba(255, 255, 255, 0.15)',
        30: 'rgba(255, 255, 255, 0.30)',
        70: 'rgba(255, 255, 255, 0.70)'
    },
    grayfade: {
        10: 'rgba(174, 174, 178, 0.10)',
        15: 'rgba(174, 174, 178, 0.15)',
        30: 'rgba(174, 174, 178, 0.30)',
        70: 'rgba(174, 174, 178, 0.70)'
    },
    blackfade: {
        10: 'rgba(30, 30, 30, 0.10)',
        15: 'rgba(30, 30, 30, 0.15)',
        30: 'rgba(30, 30, 30, 0.30)',
        50: 'rgba(30, 30, 30, 0.50)',
        70: 'rgba(30, 30, 30, 0.70)'
    },
    whiteAlpha: {
        50: 'rgba(255, 255, 255, 0.04)',
        100: 'rgba(255, 255, 255, 0.06)',
        200: 'rgba(255, 255, 255, 0.08)',
        300: 'rgba(255, 255, 255, 0.16)',
        400: 'rgba(255, 255, 255, 0.24)',
        500: 'rgba(255, 255, 255, 0.36)',
        600: 'rgba(255, 255, 255, 0.48)',
        700: 'rgba(255, 255, 255, 0.64)',
        800: 'rgba(255, 255, 255, 0.80)',
        900: 'rgba(255, 255, 255, 0.92)'
    },
    blackAlpha: {
        50: 'rgba(0, 0, 0, 0.04)',
        100: 'rgba(0, 0, 0, 0.06)',
        200: 'rgba(0, 0, 0, 0.08)',
        300: 'rgba(0, 0, 0, 0.16)',
        400: 'rgba(0, 0, 0, 0.24)',
        500: 'rgba(0, 0, 0, 0.36)',
        600: 'rgba(0, 0, 0, 0.48)',
        700: 'rgba(0, 0, 0,  0.64)',
        800: 'rgba(0, 0, 0, 0.80)',
        900: 'rgba(0, 0, 0, 0.92)'
    },
    grayDark: '#2C2C2E',
    bgGray: '#F5F5F5',
    bgBlack: '#0B0913',
    customGray: '#F9F9F9',
    bodyText: '#3D3D3D',
    dark: {
        100: '#343443',
        200: '#272737',
        300: '#1D1D28',
        400: '#1A1924'
    }
};
