/* cspell:disable */
import { NavigateFunction } from 'react-router-dom'
import { Channel as ChannelInstance, DefaultGenerics, Event, StreamChat } from 'stream-chat'
import type { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'

import { ROUTES } from '../_routes/routes-list'
import config from '../_server/config'
import { IBrowserNotificationService } from './browser-notification'
import { ITokenService } from './token-service'

/**
 * Chat client service interface
 */
export interface IChatClientService {
  /**
   * This method is used to add listeners to the client object for 'message.new' and 'notification.message_new' events.
   * It calls handleNewMessage and handleNewMessageNotification methods respectively when these events are triggered.
   */
  addListeners(): void

  /**
   * This method is used to remove listeners from the client object for 'message.new' and 'notification.message_new' events.
   */
  removeListeners(): void

  /**
   * This method is used to set the activeChat prop which is used to check if the message is not in the current active chat channel.
   */
  setActiveChat(channel: ChannelInstance<DefaultStreamChatGenerics> | null): void

  /**
   * This method is used to set the navigate prop which is used to navigate to the chat channel when a notification is clicked.
   */
  setNavigateInstance(navigate: NavigateFunction): void
}

/**
 * ChatClientService class that implements the IChatClientService interface
 */
export class ChatClientService implements IChatClientService {
  // Creating a client object of StreamChat
  client: StreamChat<DefaultGenerics>

  loggedInUserID: string | null

  navigate: NavigateFunction | null

  activeChatID: string | null

  constructor(private tokenService: ITokenService, private browserNotificationService: IBrowserNotificationService) {
    /**
     * Initializing the client object with the app key from config
     * Sometimes the connection cannot be established due to network or a corporate firewall.
     * In such cases, the client will establish or switch to XHR fallback mechanisms and gently poll our service to keep the client up-to-date. The fallback mechanism can be enabled with the flag enableWSFallback
     */
    this.client = StreamChat.getInstance(config.app.STREAM_APP_KEY, { enableWSFallback: true, timeout: 10000 })

    this.loggedInUserID = this.tokenService.getUserID()
    this.navigate = null
    this.activeChatID = localStorage.getItem('activeChatID') || null
  }

  addListeners = () => {
    this.client.on('message.new', this.handleNewMessage)
    this.client.on('notification.message_new', this.handleNewMessageNotification)

    /** Adds listener on browser local storage */
    window.addEventListener('storage', this.localStorageListener)

    /** Adds listener on browser close tab event */
    window.addEventListener('beforeunload', this.cleanupAfterTabClosed)
  }

  removeListeners = () => {
    this.client.off('message.new', this.handleNewMessage)
    this.client.off('notification.message_new', this.handleNewMessageNotification)

    /** Remove listener on browser local storage */
    window.removeEventListener('storage', this.localStorageListener)

    /** Remove listener on browser close tab event */
    window.removeEventListener('beforeunload', this.cleanupAfterTabClosed)
  }

  setNavigateInstance = (navigate: NavigateFunction) => {
    this.navigate = navigate
  }

  setActiveChat = (channel: ChannelInstance<DefaultStreamChatGenerics> | null) => {
    this.activeChatID = channel?.id || null

    if (window.location.pathname.indexOf('inbox') != 0) {
      localStorage.setItem('activeChatID', this.activeChatID || '')
    }
  }

  /**
   * This method is a private method that is called when a new message is received.
   * It checks if the message is not sent by the logged in user and if the message is not in the current active chat channel.
   * If these conditions are met, it calls the browserNotificationService's create method to create a new notification for the user.
   */
  private handleNewMessage = (event: Event<DefaultGenerics>) => {
    /** Skip to send the notification on shipment events */
    if (event?.message?.action_type) return

    if (
      this.loggedInUserID !== event?.user?.id &&
      event.message &&
      event.message.user &&
      event.channel_id !== this.activeChatID
    ) {
      this.browserNotificationService.create<{ messageID: string }>({
        body: event.message.text || '',
        data: { messageID: event.message.id },
        notificationHandler: () => {
          if (this.navigate) {
            this.navigate(ROUTES.INBOX, {
              state: {
                chatID: event.channel_id,
                chatType: event.channel_type
              }
            })
          }
        },
        title: event.message.user?.name ? `${event.message.user.name} sent a message` : ''
      })
    }
  }

  /**
   * This method is a private method that is called when a new notification message is received.
   * it calls the browserNotificationService's create method to create a new notification for the user.
   */
  private handleNewMessageNotification = (event: Event<DefaultGenerics>) => {
    /** Skip to send the notification on shipment events */
    if (event?.message?.action_type) return

    if (
      event.message &&
      event.message.user &&
      this.loggedInUserID !== event?.message.user.id &&
      event.channel_id !== this.activeChatID
    ) {
      this.browserNotificationService.create<{ messageID: string }>({
        body: event.message.text || '',
        data: {
          messageID: event.message.id
        },
        notificationHandler: () => {
          if (this.navigate) {
            this.navigate(ROUTES.INBOX, {
              state: {
                chatID: event.channel_id,
                chatType: event.channel_type
              }
            })
          }
        },
        title: event.message.user?.name ? `${event.message.user.name} sent a message` : ''
      })
    }
  }

  /**
   * This method listens for changes in local storage and updates the activeChatID state accordingly.
   * It filters out changes that are made in the URL containing 'inbox' to avoid triggering the update.
   *
   * @param event - The storage event object that holds the information about the change made in local storage.
   *
   * @returns void
   */
  private localStorageListener = (event: StorageEvent) => {
    if (event.key === 'activeChatID' && event.url.indexOf('inbox') != 0) {
      this.activeChatID = event.newValue
    }
  }

  /**
   * This method is called when the tab is closed.
   * It updates the active chat id in local storage to an empty string if the current location is not in the inbox.
   */
  private cleanupAfterTabClosed = () => {
    if (window.location.pathname.indexOf('inbox') != 0) {
      localStorage.setItem('activeChatID', '')
    }
  }
}
