export { Switch } from '@chakra-ui/react';
import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const { definePartsStyle } = createMultiStyleConfigHelpers(switchAnatomy.keys);
export const boxy = definePartsStyle({
    track: {
        borderRadius: '4px',
        p: 1
    }
});
