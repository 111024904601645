import { ApolloError } from '@apollo/client'
import { GraphQLError, GraphQLErrorExtensions } from 'graphql'

type CustomError = Partial<ApolloError>

interface Error extends CustomError {
  code: string | unknown

  extensions: GraphQLErrorExtensions
  message: string
}

export const graphQLErrorParser = (apolloError: ApolloError): Error[] => {
  const { graphQLErrors: errors } = apolloError

  const errorArray = errors.map((e: GraphQLError) => ({
    code: e.extensions?.code || '',
    extensions: e.extensions || {},
    message: e.message || ''
  }))
  return errorArray
}
