import { CompanyIdentifierType, VerificationMethodType } from './types'

/**
 * Handles the logic of deciding which step to go to next when the next button is clicked in the setup workspace component
 *
 * @params params.step the current step
 * @params params.companyIdentifierType the company identifier type (e.g. MC#, DOT#)
 * @params params.verificationType the verification type (e.g. predetermined phone number, predetermined email, new email address)
 * @params params.setStep the function to set the step
 */
export const handleNext = (params: {
  companyIdentifierType: string
  setStep: (step: number) => void
  step: number
  verificationMethodType: VerificationMethodType | null
}) => {
  const { step, companyIdentifierType, verificationMethodType, setStep } = params

  switch (step) {
    case 1:
      setStep(2)
      break
    case 2:
      if (companyIdentifierType === CompanyIdentifierType.MC || companyIdentifierType === CompanyIdentifierType.DOT) {
        setStep(3)
      } else {
        setStep(10)
      }
      break
    case 3:
      setStep(4)
      break
    case 4:
      setStep(5)
      break
    case 5:
      if (verificationMethodType === VerificationMethodType.Phone) {
        setStep(6)
      } else if (verificationMethodType === VerificationMethodType.Email) {
        setStep(8)
      } else if (verificationMethodType === VerificationMethodType.CompanyEmail) {
        setStep(7)
      } else {
        setStep(9)
      }
      break
    case 6:
      setStep(8)
      break
    case 7:
      setStep(8)
      break
    case 8:
      setStep(10)
      break
    case 9:
      setStep(10)
      break
    default:
      break
  }
}
