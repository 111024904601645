import { Button, Text } from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'

import { authService } from '../../../auth/data'
import { UsersWorkspace } from '../../../auth/data/auth-types'
import { useAuth } from '../../../auth/data/use-auth'
import { CompanyIdentifierType, ModalStepProps, VerificationMethodType } from './types'
import { Wrapper } from './wrapper'

const MAX_RETRY_COUNT = 10

interface ConfirmationStepProps extends ModalStepProps {
  /**
   * The type of identifier the company wants to use to verify (MC or DOT)
   */
  companyIdentifierType: CompanyIdentifierType

  /**
   * Closes the modal
   */
  onClose: () => void

  /**
   * This method is used to choose a workspace after it has been created and a user has been added as a member.
   */
  onWorkspaceCreation?: (workspace: UsersWorkspace) => void

  /**
   * Method to update the URL with new workspace name
   */
  replaceWorkspacenameInURL?: (workspacename: string) => void
  /**
   * The type that the user chose to verify
   */
  verificationMethodType: VerificationMethodType | null
  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * The final step of the setup process
 */
const UnmemoizedConfirmationStep = (props: ConfirmationStepProps) => {
  const {
    step,
    verificationMethodType,
    companyIdentifierType,
    onClose,
    replaceWorkspacenameInURL,
    workspaceID,
    onWorkspaceCreation
  } = props
  const [loadingWorkspace, setLoadingWorkspace] = useState(false)
  const { refetchUserDetails, selectedWorkspace } = useAuth()
  const { workspaces, refetchWorkspaces } = authService.getWorkspacesByUser()
  const [intervalId, setIntervalId] = useState<number | null>(null)
  const [workspacename, setWorkspacename] = useState<string | null>(null)
  const [retryCount, setRetryCount] = useState(0) // State to keep track of retries

  const handleClose = () => {
    if (replaceWorkspacenameInURL && workspacename) replaceWorkspacenameInURL(workspacename)
    refetchUserDetails()
    onClose()
  }

  /**
   * Clear the interval if the component closes
   */
  const handleCloseInterval = () => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }

  useEffect(() => {
    if (
      companyIdentifierType !== CompanyIdentifierType.Other &&
      verificationMethodType !== VerificationMethodType.Custom &&
      !intervalId
    ) {
      setLoadingWorkspace(true)

      /**
       * Set the interval to refetch the workspaces until we will not found the workspaces list where we added user as a member
       */
      const id = window.setInterval(() => {
        refetchWorkspaces()
        setRetryCount((prevCount) => prevCount + 1) // Increment retry count
      }, 1500) // Set to poll every 1500 ms
      setIntervalId(id)
    }

    return () => {
      handleCloseInterval()
    }
  }, [])

  useEffect(() => {
    if (retryCount > MAX_RETRY_COUNT) {
      handleCloseInterval()
    }
  }, [retryCount])

  useEffect(() => {
    if (workspaces.length > 0) {
      const workspace = workspaces.find((w) => w.workspace.id === workspaceID)
      // If the onWorkspaceCreation method is passed from the parent component as a prop, we call this method with the new workspace details.
      if (workspace) {
        const selectedWorkspaceID = selectedWorkspace?.workspace.id
        if (onWorkspaceCreation && (!selectedWorkspaceID || selectedWorkspaceID === workspaceID)) {
          onWorkspaceCreation(workspace)
          setWorkspacename(workspace.workspace.workspacename)
        }

        // Set the loading state to false and change the message to user you have been verified
        setLoadingWorkspace(false)

        handleCloseInterval()
      }
    }
    // we required to run this effect only when workspaces are fetched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces])

  return (
    <>
      <Wrapper title={'🎉 You are all set!'} activeStep={step} backButtonProps={null}>
        {companyIdentifierType === CompanyIdentifierType.Other && <Text>Your workspace has been created</Text>}
        {verificationMethodType === VerificationMethodType.Custom && <Text>Your request has been sent to support</Text>}
        {companyIdentifierType !== CompanyIdentifierType.Other &&
          verificationMethodType !== VerificationMethodType.Custom && (
            <Text my={4}>
              {loadingWorkspace
                ? `🌟 We're validating your MC/DOT number now. Sit tight while we set up your workspace!`
                : `You have been verified!`}
            </Text>
          )}
        <Button
          width="100%"
          onClick={handleClose}
          mt={4}
          variant="primary"
          isLoading={loadingWorkspace}
          loadingText="Hang tight"
        >
          Finish
        </Button>
      </Wrapper>
    </>
  )
}

export const ConfirmationStep = React.memo(UnmemoizedConfirmationStep)
