export { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay } from '@chakra-ui/react';
export const drawerOverlayBaseStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'rgba(20, 20, 20, 0.5)' : 'rgba(0,0,0,0.55)',
        zIndex: 'modal'
    };
};
export const drawerCloseButtonBaseStyle = ({ colorMode }) => ({
    color: colorMode === 'dark' ? 'gray.500' : 'gray.700',
    bg: 'none',
    _hover: { bg: colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.100' },
    _focus: {
        bg: colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.100'
    },
    position: 'absolute',
    top: 6,
    insetEnd: 5,
    borderRadius: '2xl'
});
function getSize(value) {
    if (value === 'full') {
        return {
            dialog: { maxW: '100vw', h: '100vh' }
        };
    }
    return {
        dialog: { maxW: value }
    };
}
export const sizes = {
    xs: getSize('xs'),
    sm: getSize('md'),
    md: getSize('lg'),
    lg: getSize('2xl'),
    xl: getSize('4xl'),
    '2xl': getSize('6xl'),
    '3xl': { dialog: { maxW: { base: '100%', sm: '100%', md: '90%', lg: '80%', xl: '75%', '2xl': '75%' } } },
    '4xl': { dialog: { maxW: { base: '100%', sm: '100%', md: '90%', lg: '85%', xl: '80%', '2xl': '80%' } } },
    huge: { dialog: { maxW: '106rem' } },
    full: getSize('full')
};
