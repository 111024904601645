import { Input as KeychaineInput, InputLeftAddon, InputLeftElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';
import { AsYouType } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { FormControl, FormHelperText, FormLabel, FormWarning, InputGroup } from '../..';
import { isInputValueEmailPhone } from './logic';
const defaultCountryCode = '+1';
export { KeychaineInput as KeychaineInput };
const Input = React.forwardRef(({ name, register, errors, labelText, handleChange, helperText, inputType = 'text', ...rest }, ref) => {
    const { ref: registerRef, ...registerRest } = register(name);
    const { setValue } = useFormContext();
    const onChange = (e) => {
        inputType !== 'file' && setValue(name, e.target.value, { shouldValidate: true });
        handleChange && handleChange(e);
    };
    return (React.createElement(FormControl, null,
        labelText && (React.createElement(FormLabel, { id: name + 'label', key: name + 'label' }, labelText)),
        React.createElement(KeychaineInput, { ref: (e) => {
                registerRef(e);
                if (ref) {
                    ref.current = e;
                }
            }, isInvalid: errors[name] && true, ...registerRest, ...rest, type: inputType, onChange: onChange, "aria-labelledby": labelText ? name + 'label' : undefined }),
        helperText && !errors[name] && React.createElement(FormHelperText, { key: 'sub-label' + name }, helperText),
        errors[name] && React.createElement(FormWarning, null, errors[name].message)));
});
Input.displayName = 'input';
const EmailPhoneInput = React.forwardRef(({ name, register, control, errors, labelText, inputType = 'text', ...rest }, ref) => {
    const { setValue, getValues } = useFormContext();
    const [isEmailPhone, setIsEmailPhone] = useState('');
    const formatValue = (event) => {
        const { value } = event.target;
        const cleanedTarget = value?.replace(/[()-]|\s/g, '');
        const isEmailPhoneCheck = isInputValueEmailPhone(cleanedTarget);
        setValue('cleanedEmailPhone', cleanedTarget);
        if (isEmailPhoneCheck === 'phone') {
            const formattedPhoneNumber = new AsYouType('US').input(value);
            setValue('emailPhone', formattedPhoneNumber);
            setValue('countryCode', defaultCountryCode);
            setIsEmailPhone('phone');
            setValue('isPhoneNumber', true);
        }
        else {
            const cleanedEmail = value?.replace(/[(),:;<>]|\s/g, '');
            setValue('cleanedEmailPhone', cleanedEmail.toLowerCase());
            setValue('emailPhone', cleanedEmail.toLowerCase());
            setIsEmailPhone('email');
            setValue('isPhoneNumber', false);
        }
    };
    const { ref: registerRef, onChange, ...registerRest } = register(name, {
        onChange: formatValue
    });
    const initialValue = getValues(name);
    useEffect(() => {
        const toEventTarget = {
            target: { value: initialValue }
        };
        formatValue(toEventTarget);
    }, []);
    return (React.createElement(FormControl, null,
        labelText && (React.createElement(FormLabel, { id: name + 'label', key: name + 'label' }, labelText)),
        React.createElement(InputGroup, null,
            isEmailPhone === 'phone' && React.createElement(InputLeftAddon, null, defaultCountryCode),
            React.createElement(KeychaineInput, { ref: (e) => {
                    registerRef(e);
                    if (ref) {
                        ref.current = e;
                    }
                }, onChange: onChange, type: isEmailPhone === 'phone' ? 'tel' : 'text', country: "US", ...registerRest, ...rest, isInvalid: errors[name] && true, "aria-labelledby": labelText ? name + 'label' : undefined })),
        errors[name] && React.createElement(FormWarning, null, errors[name].message)));
});
EmailPhoneInput.displayName = 'EmailPhoneInput';
export const ControllerInput = React.forwardRef(({ isRequired, name, control, inputType = 'text', labelText, placeholder }, _) => {
    const { errors } = useFormState({ control });
    return (React.createElement(FormControl, { isRequired: isRequired },
        labelText && (React.createElement(FormLabel, { id: name + 'label', key: name + 'label' }, labelText)),
        React.createElement(Controller, { control: control, name: name, render: ({ field: { onChange, onBlur, value, ref } }) => (React.createElement(React.Fragment, null,
                React.createElement(KeychaineInput, { onBlur: onBlur, onChange: onChange, value: value, key: name, id: name, ref: ref, name: name, type: inputType, placeholder: placeholder, isInvalid: errors[name] && true, "aria-labelledby": labelText ? name + 'label' : undefined }),
                errors[name] && React.createElement(FormWarning, null, errors[name]?.message))) })));
});
ControllerInput.displayName = 'controllerInput';
const ControllerNumberInput = React.forwardRef(({ name, control, labelText }, _) => {
    const { errors } = useFormState({ control });
    return (React.createElement(FormControl, null,
        labelText && (React.createElement(FormLabel, { id: name + 'label', key: name + 'label' }, labelText)),
        React.createElement(Controller, { control: control, name: name, render: ({ field: { onChange, onBlur, value, ref } }) => (React.createElement(React.Fragment, null,
                React.createElement(NumberInput, { precision: 2, onBlur: onBlur, onChange: onChange, value: value, key: name, id: name, ref: ref, name: name, isInvalid: errors[name] && true, "aria-labelledby": labelText ? name + 'label' : undefined },
                    React.createElement(NumberInputField, null),
                    React.createElement(NumberInputStepper, null,
                        React.createElement(NumberIncrementStepper, null),
                        React.createElement(NumberDecrementStepper, null))),
                errors[name] && React.createElement(FormWarning, null, errors[name]?.message))) })));
});
ControllerNumberInput.displayName = 'controllerNumberInput';
const InputAmountController = React.forwardRef(({ isRequired, name, control, inputType = 'number', labelText, placeholder, leftInputText, bg }, _) => {
    const { errors } = useFormState({ control });
    return (React.createElement(FormControl, { isRequired: isRequired },
        labelText && (React.createElement(FormLabel, { id: name + 'label', key: name + 'label' }, labelText)),
        React.createElement(Controller, { control: control, name: name, render: ({ field: { onChange, onBlur, value, ref } }) => (React.createElement(React.Fragment, null,
                React.createElement(InputGroup, null,
                    leftInputText && (React.createElement(InputLeftElement, { pointerEvents: "none", color: "gray.300", fontSize: "1.2em", mr: 5 }, leftInputText)),
                    React.createElement(KeychaineInput, { onBlur: onBlur, onChange: onChange, value: value, key: name, id: name, w: "full", ref: ref, name: name, type: inputType, placeholder: placeholder, isInvalid: errors[name] && true, "aria-labelledby": labelText ? name + 'label' : undefined, bg: bg })),
                errors[name] && React.createElement(FormWarning, null, errors[name]?.message))) })));
});
InputAmountController.displayName = 'inputAmountController';
const EmailInput = React.forwardRef(({ name, register, errors, labelText, handleChange, helperText, inputType = 'text', ...rest }, ref) => {
    const { ref: registerRef, ...registerRest } = register(name);
    const { setValue } = useFormContext();
    const onChange = (e) => {
        setValue(name, e.target.value.toLowerCase(), { shouldValidate: true });
        handleChange && handleChange(e);
    };
    return (React.createElement(FormControl, null,
        labelText && (React.createElement(FormLabel, { id: name + 'label', key: name + 'label' }, labelText)),
        React.createElement(KeychaineInput, { ref: (e) => {
                registerRef(e);
                if (ref) {
                    ref.current = e;
                }
            }, isInvalid: errors[name] && true, ...registerRest, ...rest, type: inputType, onChange: onChange, "aria-labelledby": labelText ? name + 'label' : undefined }),
        helperText && !errors[name] && React.createElement(FormHelperText, { key: 'sub-label' + name }, helperText),
        errors[name] && React.createElement(FormWarning, null, errors[name].message)));
});
EmailInput.displayName = 'EmailInput';
export { ControllerNumberInput, EmailInput, EmailPhoneInput, Input, InputAmountController };
