export const typography = {
    fonts: ['Eloquia', '-apple-system', 'system-ui', 'Helvetica', 'InterVariable', 'Arial', 'sans-serif'],
    fontSizes: ['12px', '14px', '16px', '20px', '24px', '32px'],
    lineHeights: {
        base: 1.5,
        condensedUltra: 1,
        condensed: 1.25,
        default: 1.5
    },
    fontWeights: {
        hairline: 'hairline',
        thin: 'thin',
        extralight: 'extralight',
        light: 'light',
        regular: 'normal',
        medium: 'medium',
        semibold: 'semibold',
        bold: 'bold',
        extrabold: 'extrabold',
        black: 'black'
    }
};
