import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useAuth } from '../../auth/data/use-auth'

/**
 * Replaces the workspacename in the URL
 */
export const useReplaceWorkspacenameInURL = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const client = useApolloClient()
  const { userDetails } = useAuth()
  const { workspace_name: workspacename, handle } = useParams()

  const replaceWorkspacenameInURL = useCallback(
    async (workspaceName: string) => {
      if (workspacename) {
        navigate(location.pathname.replace(workspacename, workspaceName), { replace: true })
        navigate(0)

        await client.refetchQueries({
          updateCache(cache) {
            cache.evict({ fieldName: 'workspaceShipments' })
          }
        })
      }

      if (handle && userDetails.username !== handle) {
        navigate(location.pathname.replace(handle, workspaceName))
        navigate(0)
      } else navigate(0)
    },
    [navigate, location.pathname, workspacename, handle, client, userDetails?.username]
  )

  return { replaceWorkspacenameInURL }
}
