import { useLazyQuery } from '@apollo/client'
import {
  Button,
  FormControl,
  FormLabel,
  FormWarning,
  keychaine,
  KeychaineInput,
  useColorModeValue as mode
} from '@chaine/keychaine'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import {
  CompanyReturnType,
  GET_COMPANY_BY_DOT_OR_MC,
  GET_WORKSPACE_BY_DOT_OR_MC,
  WorkspaceByDOTOrMCNumberReturnType
} from './data/queries'
import { CompanyIdentifierType, ModalStepProps } from './types'
import { Wrapper } from './wrapper'

const validationSchema = Yup.object({
  dotOrMCNumber: Yup.string().required('Please enter your DOT or MC number')
})

interface MCOrDOTNumberProps extends ModalStepProps {
  /**
   * The type of identifier the company wants to use to verify (MC or DOT)
   */
  companyIdentifierType: CompanyIdentifierType

  /**
   * The value of the MC# or DOT#
   */
  companyIdentifierValue: string
  /**
   * Sets the retrieved company details to companyDetails
   */
  setCompanyDetails: (company: CompanyReturnType) => void

  /**
   * Sets the MC or DOT number
   */
  setCompanyIdentifierValue: (companyIdentifierValue: string) => void

  /**
   * Sets the ID of the workspace
   */
  setWorkspaceID: (id: string) => void
}

/**
 * The user enters their MC or DOT number
 */
const UnmemoizedMCOrDOTNumber = (props: MCOrDOTNumberProps) => {
  const {
    step,
    previousStepOrSkipStep,
    companyIdentifierType,
    companyIdentifierValue,
    setCompanyIdentifierValue,
    setWorkspaceID,
    handleNext,
    setCompanyDetails
  } = props
  const [showErrorLabel, setShowErrorLabel] = useState<boolean>(false)
  const defaultValues = { dotOrMCNumber: '' }
  const method = useForm({ defaultValues, resolver: yupResolver(validationSchema) })
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = method

  /**
   * This hook utilizes the useLazyQuery hook from Apollo Client to fetch company details based on DOT or MC number input. It handles the loading state and executes further actions depending on the result of the query.
   */
  const [getCompany, { loading, error }] = useLazyQuery<{ company: CompanyReturnType | null }>(
    GET_COMPANY_BY_DOT_OR_MC,
    {
      onCompleted: ({ company }: { company: CompanyReturnType | null }) => {
        if (company) {
          setCompanyDetails(company)
          handleNext()
        } else {
          setShowErrorLabel(true)
        }
      }
    }
  )

  /**
   * This hook utilizes the useLazyQuery hook from Apollo Client to fetch workspace details
   * based on DOT or MC number input. It handles the loading state and executes further actions
   * depending on the result of the query.
   */
  const [getWorkspace, { loading: getWorkspaceDetailsLoading }] = useLazyQuery<{
    getWorkspaceByDOTOrMCNumber: WorkspaceByDOTOrMCNumberReturnType | null
  }>(GET_WORKSPACE_BY_DOT_OR_MC, {
    onCompleted: async ({ getWorkspaceByDOTOrMCNumber }) => {
      if (getWorkspaceByDOTOrMCNumber) {
        /**
         * If the workspace is found with the given MC or DOT, set the workspace details which we are going to use later to add member of the company
         */
        setWorkspaceID(getWorkspaceByDOTOrMCNumber?.id)
      }
      getCompany({ variables: { id: companyIdentifierValue, type: companyIdentifierType } })
    }
  })

  //focus the input ref when the component loads
  const inputRef = React.useRef<HTMLInputElement>(null)

  //focus the ref to the input when the component loads
  useEffect(() => {
    if (null !== inputRef.current) inputRef.current.focus()
  }, [])

  const onSubmit = (event: { dotOrMCNumber: string }) => {
    const { dotOrMCNumber } = event
    setCompanyIdentifierValue(dotOrMCNumber)
    getWorkspace({ variables: { dotOrMCNumber: dotOrMCNumber, type: companyIdentifierType } })
  }

  return (
    <>
      <Wrapper
        title={`Enter your ${companyIdentifierType}`}
        activeStep={step}
        backButtonProps={{ displayBackArrow: true, onSubmit: () => previousStepOrSkipStep(2), text: 'Back' }}
      >
        <keychaine.form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            render={({ field }) => (
              <FormControl>
                <FormLabel mt={4} htmlFor="dotOrMCNumber">
                  {`${companyIdentifierType}#`}
                </FormLabel>
                <KeychaineInput
                  id="dotOrMCNumber"
                  {...field}
                  variant={mode('outline', 'filled')}
                  ref={inputRef}
                  onKeyDown={() => setShowErrorLabel(false)}
                />
                {errors.dotOrMCNumber?.message && <FormWarning>{errors.dotOrMCNumber?.message}</FormWarning>}
              </FormControl>
            )}
            name="dotOrMCNumber"
            control={control}
          />
          {showErrorLabel && <FormWarning>Company not found with that {companyIdentifierType}</FormWarning>}
          {error && <FormWarning>{error.message}</FormWarning>}
          <Button
            width="100%"
            type="submit"
            mt={4}
            variant="primary"
            isLoading={loading || getWorkspaceDetailsLoading}
            loadingText="Finding company..."
          >
            Next
          </Button>
        </keychaine.form>
      </Wrapper>
    </>
  )
}

export const MCOrDOTNumber = React.memo(UnmemoizedMCOrDOTNumber)
