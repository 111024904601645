import { kbdBaseStyle, kbdPrimaryStyle } from '../../data-display/kbd';
export const Kbd = {
    variants: {
        primary: kbdPrimaryStyle,
        base: kbdBaseStyle
    },
    defaultProps: {
        variant: 'primary'
    }
};
