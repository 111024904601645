import { ROUTES } from './routes';
export const NAV_ITEMS = [
    {
        label: 'Product',
        hash: '/#chaine-features',
        children: [
            {
                label: 'Copilot',
                subLabel: 'AI-powered automation to reduce manual work, improve efficiency and increase margins',
                hash: '/#ai-powered-automation-section'
            },
            {
                label: 'Capacity',
                subLabel: 'Increase, engage, and digitally book your carrier base',
                hash: '/#capacity-section'
            },
            {
                label: 'Visibility',
                subLabel: 'Truckload tracking, ocean container tracking, predictive ETAs, exception management',
                hash: '/#tracking-section'
            },
            {
                label: 'Integrations',
                subLabel: 'Connect your favorite tools into your existing workflows',
                href: ROUTES.INTEGRATIONS.href
            }
        ]
    },
    {
        label: 'Resources',
        children: [
            {
                label: 'Customer Stories',
                subLabel: 'Videos from our customers sharing their experience',
                href: ROUTES.CUSTOMER_STORIES.href
            },
            {
                label: 'Testimonials',
                subLabel: 'Read what our customers are saying',
                href: ROUTES.TESTIMONIALS.href
            },
            {
                label: 'Logistics Training Guide',
                subLabel: 'Learn the basics of logistics',
                isExternalLink: true,
                href: ROUTES.LOGISTICS_TRAINING_GUIDE.href
            }
        ]
    },
    {
        label: 'Company',
        href: ROUTES.COMPANY.href
    }
];
