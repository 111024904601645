import { useMutation } from '@apollo/client'
import { Button, Text } from '@chaine/keychaine'
import React, { useCallback } from 'react'

import { REQUEST_CUSTOM_VERIFICATION, RequestCustomVerificationInput } from './data/mutations'
import { CompanyIdentifierType, ModalStepProps } from './types'
import { Wrapper } from './wrapper'

interface RequestCustomVerificationProps extends ModalStepProps {
  /**
   * The type of identifier the company wants to use to verify (MC or DOT)
   */
  companyIdentifierType: CompanyIdentifierType
  /**
   * The type of identifier the company wants to use to verify (MC or DOT)
   */
  companyIdentifierValue: string
  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * In this step, the user can confirm if they want to request a custom verification
 */
export const UnmemoizedRequestCustomVerification = (props: RequestCustomVerificationProps) => {
  const { step, companyIdentifierValue, companyIdentifierType, workspaceID, handleNext } = props

  const [requestCustomVerification] = useMutation<void, RequestCustomVerificationInput>(REQUEST_CUSTOM_VERIFICATION)

  const handleSubmit = useCallback(async () => {
    await requestCustomVerification({
      context: {
        headers: {
          'ws-id': workspaceID || ''
        }
      },
      variables: {
        input: {
          companyIdentifier: { type: companyIdentifierType, value: companyIdentifierValue }
        }
      }
    })
    handleNext()
  }, [requestCustomVerification, companyIdentifierValue, companyIdentifierType, workspaceID, handleNext])

  return (
    <>
      <Wrapper
        title={'Request custom verification'}
        activeStep={step}
        backButtonProps={{ displayBackArrow: true, text: 'Go back' }}
      >
        <Text>Are you sure you want to request a custom verification?</Text>
        <Button width="100%" type="submit" mt={4} variant="primary" onClick={handleSubmit}>
          Request verification
        </Button>
      </Wrapper>
    </>
  )
}

export const RequestCustomVerification = React.memo(UnmemoizedRequestCustomVerification)
