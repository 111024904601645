import { ArrowLeftIcon, Button, Icon, ModalBody, ModalHeaderLayout } from '@chaine/keychaine'
import React from 'react'

interface WrapperProps {
  /**
   * The step of the setup workspace process which is currently active
   */
  activeStep: number
  backButtonProps: {
    displayBackArrow: boolean
    onSubmit?: () => void
    text: string
  } | null
  /**
   * The component body for the modal
   */
  children: React.ReactNode
  /**
   * The subtext to display
   */
  subtitle?: string

  /**
   * The title that should be displayed in the header
   */
  title: string | JSX.Element | JSX.Element[]
}

/**
 * Wraps the body of the setup workspace component to provide component styling
 *
 * @param props.title the title to display in the header
 * @param props.activeStep the step of the setup workspace process which is currently active
 * @param props.subtitle the subtext to display
 * @param props.previousStepOrSkipStep navigates to the previous step or goes to a different step
 * @param props.children the component body for the modal
 * @param props.backButtonProps.displayBackArrow pass true to display the back arrow
 */
export const Wrapper = (props: WrapperProps) => {
  // const { activeStep, subtitle, previousStep, children, title } = props
  const { subtitle, children, title, backButtonProps } = props

  return (
    <>
      <ModalHeaderLayout title={title} showCloseButton={true} subtitle={subtitle} />
      <ModalBody>
        {/* <Stack spacing={4} justifyContent="center">
          <Stack spacing={2}>
            <Box width="100%"> */}
        {/* <Alert py={6} status="error" description={alertError} closeButton alertIcon /> */}
        {/* </Box> */}
        {/* {activeStep > 1 && (
          <Box width="100%">
            <Button
              variant="link"
              isLoading={false}
              type="submit"
              onClick={previousStep}
              leftIcon={<Icon as={ArrowLeftIcon} />}
            >
              Go back
            </Button>
          </Box>
        )} */}
        {/* </Stack> */}
        {children}
        {backButtonProps && (
          <Button
            onClick={backButtonProps.onSubmit}
            mt={4}
            variant="unstyled"
            leftIcon={backButtonProps.displayBackArrow ? <Icon as={ArrowLeftIcon} /> : undefined}
          >
            {backButtonProps.text}
          </Button>
        )}
      </ModalBody>
    </>
  )
}
