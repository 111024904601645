import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { MembershipAccessType } from '../types/membership-types'

export const DOES_USER_EXIST = gql`
  query DoesUserExist($emailOrPhone: String!) {
    userExists(input: { emailOrPhone: $emailOrPhone }) {
      id
      countryCode
      phone
      email
      name
      photo
      memberships {
        workspaceID
        role
        workspace {
          displayName
          logo
        }
      }
    }
  }
`

/**
 * Response from the does user exist query
 */
export type DoesUserExistReturnType = {
  email: string
  id: string
  memberships: {
    role: string
    workspace: {
      displayName: string
      logo: string
    }
    workspaceID: string
  }
  name: string
  phone: string
  photo: string
}

/**
 * Query to get the invited users of the workspace
 */
export const GET_WORKSPACE_INVITATIONS = gql`
  query WorkspaceInvitations($workspaceID: ID!) {
    workspaceInvitations(input: { workspaceID: $workspaceID }) {
      userID
      workspaceID
      accessType
      expirationDate
      user {
        id
        name
        photo
        email
        phone
        countryCode
      }
    }
  }
`

/**
 * Response from the get workspace invitations query
 */
export type TGetWorkspaceInvitationsReturnType = {
  accessType: MembershipAccessType
  expirationDate: string
  user: {
    countryCode: string
    email: string
    id: string
    name: string
    phone: string
    photo: string
  }
  userID: string
  workspaceID: string
}

/**
 * The return type for the user page in the "user" graphql query
 */
export type PageReturnType = {
  /**
   * The profile's bio info (a small summary or a short description)
   */
  bio: string | null

  /**
   * The profile's freight preferences (Ex: Dry Van, Reefer, Hazmat)
   */
  freightPreferences: string[]
}

export type GetConstraintReturnType = {
  canEditPage: boolean
  id: string
  type: string
  uuid: string
  workspace: ConstraintWorkspaceType
}

export type ConstraintWorkspaceType = {
  displayName: string
  dot: string | null
  id: string
  isVerified: boolean
  logo: string | null
  mcNumber: string | null
  workspacePage: PageReturnType
  workspaceType: string
  workspacename: string
}

export const GET_CONSTRAINT = gql`
  query GetConstraint($constraint: String!) {
    getConstraint(input: { constraint: $constraint }) {
      id
      uuid
      type
      workspace {
        id
        logo
        displayName
        isVerified
        workspacename
        workspaceType
        dot
        mcNumber
        workspacePage {
          bio
          freightPreferences
        }
      }
      canEditPage
    }
  }
`

/**
 * useConstraintQuery to fetch the constraint data
 */
export function useConstraintQuery() {
  const { handle } = useParams()

  const {
    data,
    loading,
    refetch: refetchGetConstraint,
    error
  } = useQuery<{ getConstraint: GetConstraintReturnType | null }>(GET_CONSTRAINT, {
    fetchPolicy: 'no-cache',
    variables: { constraint: handle }
  })

  return {
    data,
    error,
    loading,
    refetchGetConstraint
  }
}

/**
 * Get's a user's IP metadata which contains information about the user's IP usage and last used ip
 */
export const GET_USER_IP_METADATA = gql`
  query GetUserIPMetadata($id: ID!) {
    userIPMetadata(input: { id: $id }) {
      hasUsedAbusiveIP
      hasUsedBogonIP
      hasUsedCrawler
      hasUsedDatacenterIP
      hasUsedProxy
      hasUsedTOR
      hasUsedVPN
      id
      ipLocations
      lastUsedIP {
        ip
        isAbuser
        isBogon
        isCrawler
        isDatacenter
        isProxy
        isTOR
        isVPN
        lastUsed
        location {
          city
          country
          countryCode
          latitude
          longitude
          region
          state
          zip
        }
      }
    }
  }
`

/**
 * Get's a user's IP metadata which contains information about the user's IP usage and last used ip
 */
export const GET_WORKSPACE_IP_METADATA = gql`
  query GetWorkspaceIPMetadata($id: ID!) {
    workspaceIPMetadata(input: { id: $id }) {
      hasUsedAbusiveIP
      hasUsedBogonIP
      hasUsedCrawler
      hasUsedDatacenterIP
      hasUsedProxy
      hasUsedTOR
      hasUsedVPN
      id
      ipLocations
      lastUsedIP {
        ip
        isAbuser
        isBogon
        isCrawler
        isDatacenter
        isProxy
        isTOR
        isVPN
        lastUsed
        location {
          city
          country
          countryCode
          latitude
          longitude
          region
          state
          zip
        }
      }
    }
  }
`

/**
 * Get's all users associated with an IP
 */
export const GET_IP_USER_ASSOCIATIONS = gql`
  query IPUserAssociations($ip: ID!) {
    ipUserAssociations(input: { ip: $ip }) {
      id
      ip
      isAbuser
      isBogon
      isCrawler
      isDatacenter
      isProxy
      isTOR
      isVPN
      lastUsed
      location {
        city
        country
        countryCode
        region
        state
        latitude
        longitude
        zip
      }
      user {
        id
        name
        photo
        primaryMembership {
          workspace {
            id
            displayName
            dot
            isVerified
            logo
            mcNumber
          }
        }
      }
    }
  }
`

/**
 * Get's all workspaces associated with an IP
 */
export const GET_IP_WORKSPACE_ASSOCIATIONS = gql`
  query IPWorkspaceAssociations($ip: ID!) {
    ipWorkspaceAssociations(input: { ip: $ip }) {
      id
      ip
      isAbuser
      isBogon
      isCrawler
      isDatacenter
      isProxy
      isTOR
      isVPN
      lastUsed
      location {
        city
        country
        countryCode
        region
        state
        latitude
        longitude
        zip
      }
      workspace {
        id
        displayName
        isVerified
        logo
        dot
        mcNumber
      }
    }
  }
`
