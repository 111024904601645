import { mode } from '../..';
export const inputOutlineStyle = (props) => {
    const { colorMode } = props;
    return {
        field: {
            bg: colorMode === 'dark' ? 'gray.800' : 'white',
            border: '1px solid',
            borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.200',
            boxShadow: colorMode === 'dark' ? 'none' : 'inset 1px 2px 1px rgba(229, 229, 234, 0.2)',
            borderRadius: '0.4rem',
            _placeholder: {
                color: mode('gray.400', 'gray.500')(props)
            },
            _hover: {
                borderColor: mode('gray.300', 'gray.500')(props)
            },
            _readOnly: {
                boxShadow: 'none !important',
                userSelect: 'all'
            },
            _disabled: {
                opacity: 0.4,
                cursor: 'not-allowed'
            },
            _invalid: {
                borderColor: colorMode === 'dark' ? 'red.500' : 'red.700',
                boxShadow: colorMode === 'dark'
                    ? '3px -3px 0px #4E1515, -3px 3px 0px #4E1515, 3px 3px 0px #4E1515, -3px -3px 0px #4E1515'
                    : '3px -3px 0px #FFCCCC, -3px 3px 0px #FFCCCC, 3px 3px 0px #FFCCCC, -3px -3px 0px #FFCCCC'
            },
            _focus: {
                borderColor: colorMode === 'dark' ? 'blue.300' : 'blue.500',
                boxShadow: colorMode === 'dark'
                    ? '3px -3px 0px #15304E, -3px 3px 0px #15304E, 3px 3px 0px #15304E, -3px -3px 0px #15304E'
                    : '3px -3px 0px #CCE4FF, -3px 3px 0px #CCE4FF, 3px 3px 0px #CCE4FF, -3px -3px 0px #CCE4FF',
                transitionProperty: 'border-color box-shadow',
                transitionTimingFunction: 'ease-in',
                transitionDuration: 'normal'
            }
        },
        addon: {
            border: '1px solid',
            borderColor: mode('inherit', 'whiteAlpha.50')(props),
            bg: mode('white', 'gray.800')(props)
        }
    };
};
