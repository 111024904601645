import {
  Box,
  ColorModeToggle,
  Flex,
  HStack,
  useBreakpointValue,
  useColorMode,
  useColorModeValue as mode
} from '@chaine/keychaine'
import React, { memo } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { ROUTES } from '../../../../_routes/routes-list'
import { useColorModeContext } from '../../../../_utils/color-mode-context'
import { useAuth } from '../../../../auth/data/use-auth'
import { CompleteVerificationButton } from './complete-verification-button'
import { HeaderLogo } from './header-logo'
import { HeaderMenu } from './header-menu'
import HeaderNavList from './header-nav-list'
import { NotificationButton } from './notification-button'

const useLocationContext = () => {
  const location = useLocation()
  const { username } = useParams()

  return { location, username }
}

const useAuthContext = () => {
  const { userDetails, selectedWorkspace } = useAuth()
  const activeWorkspace = selectedWorkspace?.workspace?.workspacename

  return { activeWorkspace, userDetails }
}

const useColorModeContextFn = () => {
  const { toggleColorMode } = useColorMode()
  const { colorMode, changeColorMode } = useColorModeContext()

  const toggle = () => {
    toggleColorMode()
    changeColorMode()
  }

  return { colorMode, toggle }
}

const DesktopHeader = memo(() => {
  const { location } = useLocationContext()
  const { userDetails } = useAuthContext()
  const { colorMode, toggle } = useColorModeContextFn()

  return (
    <Flex
      bg={mode('gray.900', 'gray.800')}
      flexDirection="row"
      justify="space-between"
      px={6}
      py={3}
      alignItems="center"
    >
      <HStack spacing={10} flexShrink={0}>
        <Link to={ROUTES.ASSIGNED_TO_ME}>
          <HeaderLogo />
        </Link>
        <HeaderNavList />
      </HStack>
      <HStack spacing={4} flexShrink={0}>
        <CompleteVerificationButton />

        <NotificationButton />

        <ColorModeToggle updateColorModePreference={toggle} mode={colorMode} />
        {location.pathname != ROUTES.ONBOARDING && <HeaderMenu user={userDetails} isMobile={false} />}
      </HStack>
    </Flex>
  )
})

DesktopHeader.displayName = 'DesktopHeader'

const MobileHeader = memo(() => {
  const { userDetails } = useAuth()
  const { toggleColorMode } = useColorMode()
  const { colorMode, changeColorMode } = useColorModeContext()

  //toggle the color mode in chakra so that the components change and we can use chakra's hook.  later we should use our own hook
  const toggle = () => {
    toggleColorMode()
    changeColorMode()
  }

  return (
    <Box px="4" py="4" borderBottomWidth="1px" overflow="auto" bg={mode('gray.900', 'gray.800')}>
      <Flex align="center" justify="space-between" display={{ base: 'flex', lg: 'none' }}>
        <HStack spacing="3">
          <HeaderLogo />
        </HStack>
        <HStack spacing="3">
          <ColorModeToggle updateColorModePreference={toggle} mode={colorMode} />
          <HeaderMenu isMobile={true} user={userDetails} />
        </HStack>
      </Flex>
    </Box>
  )
})

MobileHeader.displayName = 'MobileHeader'

export const Header = memo(() => {
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false })

  return <>{isDesktop ? <DesktopHeader /> : <MobileHeader />}</>
})

Header.displayName = 'Header'
