import React, { useEffect } from 'react'

import { tokenService } from '../../auth/data'
import { createGenericContext } from '../create-generic-context'
import type { IAnalyticsContext } from './analytics-service'
import * as analyticsService from './analytics-service'

/**
 * Generate context the {@link proper https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947} way
 * ChatContext - react context {@link React.createContext}
 * {@link https://reactjs.org/docs/context.html}
 */
const [useAnalytics, AnalyticsContextProvider] = createGenericContext<IAnalyticsContext>()

interface AnalyticsProviderProps {
  children: React.ReactNode
}

const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  // const [userConnection, setUserConnection] = React.useState<ConnectionOpen<DefaultGenerics> | null>(null)
  // const [channelList, setChannelList] = React.useState<Channel<DefaultGenerics>[] | null>(null)

  const userID = tokenService.getUserID() || undefined

  /**
   * call handle connect user method - To connect the user to stream chat client
   * @returns () handleDisconnectUser - call this method when component removes from the DOM.
   */

  /**
   * Initialize analytics api
   */
  useEffect(() => {
    analyticsService.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * If user log's in, identify the user with their userID
   */

  useEffect(() => {
    if (userID) {
      analyticsService.identify(userID)
    }
  }, [userID]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AnalyticsContextProvider
      value={{
        captureEvent: analyticsService.captureEvent,
        capturePageview: analyticsService.capturePageview,
        identify: analyticsService.identify,
        identifyWorkspace: analyticsService.identifyWorkspace,
        isFeatureEnabled: analyticsService.isFeatureEnabled,
        setUserProperty: analyticsService.people.setUserProperty
      }}
    >
      {children}
    </AnalyticsContextProvider>
  )
}

export { AnalyticsProvider, useAnalytics }
