/**
 * @param id the user id
 * @param name the name of the selected user
 * @param email the used to display the email of selected user
 * @param photo the url of the profile photo of selected user
 * @param phone the used to display the phone number of user
 */
export interface UserDetails {
  countryCode?: string
  displayName?: string
  email?: string
  id: string
  name?: string
  online?: boolean
  phone?: string
  photo?: string
  role?: UserRole
  username?: string
}

export enum UserStatus {
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
  REGISTERED = 'Registered'
}

export enum UserRole {
  CarrierSalesRep = 'CarrierSalesRep',
  CustomerSalesRep = 'CustomerSalesRep',
  Dispatcher = 'Dispatcher',
  Driver = 'Driver',
  OwnerOperator = 'OwnerOperator',
  ShipperSalesRep = 'ShipperSalesRep'
}

export enum CreatedVia {
  ChaineWebApp = 'ChaineWebApp',
  Integration = 'Integration'
}
