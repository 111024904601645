import { Box, Flex, useBreakpointValue, useColorModeValue as mode } from '@chaine/keychaine'
import React, { useCallback, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { LoadingProgressProvider } from '../../../../_utils/progress-bar'
import { useAuth } from '../../../../auth/data/use-auth'
import { useSafeAreaInsetsState } from '../../../layouts/safe-area-context'
import { Footer } from '../footer'
import { Header } from '../header'
import PublicHeader from '../public-header'
import { BrowserPermissionBanner } from './browser-permission-banner'
import { WorkspaceInvitationsReceived } from './workspace-invitations-received'

const UnmemoizedMainContainer = () => {
  const { isAuthenticated, userDetails } = useAuth()

  //temporary
  // const pastDueAccount = '60586137-c7f1-4d8a-aed2-1e549de86e8c'

  //temporary
  // const isPastDue = selectedWorkspace?.workspace?.id ? selectedWorkspace?.workspace?.id === pastDueAccount : false

  const safeAreaInsets = useSafeAreaInsetsState()

  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false })
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  if (isMobile) {
    window.Intercom('shutdown')
  }

  const mainHeader = useCallback(() => {
    return isAuthenticated && userDetails && userDetails.status == 'Active' ? <Header /> : <PublicHeader />
  }, [isAuthenticated, userDetails])

  useMobileViewportHeight()

  return (
    <LoadingProgressProvider>
      {isDesktop ? (
        <>
          {/* Desktop Version Layout structure */}
          <Box h="100vh" overflow="hidden" display="flex" flexDirection="column">
            {mainHeader()}
            {/* {isPastDue && (
              <Flex
                bottom="0"
                left="0"
                right="0"
                p="10px"
                bg={mode('red.50', 'red.100')}
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <HStack ml="4" alignItems="center">
                  <Icon as={AlertIcon} color={mode('red.500', 'red.600')} />
                  <Text color={mode('red.500', 'red.600')} fontWeight="semibold">
                    Your account is past due. To avoid interruptions to your service, please{' '}
                    <Link
                      fontWeight="bold"
                      textDecoration="underline"
                      href="https://billing.stripe.com/p/login/9AQcOO7INg6paRi3cc"
                      isExternal
                    >
                      up your payment
                    </Link>{' '}
                    as soon as possible.
                  </Text>
                </HStack>
              </Flex>
            )} */}
            <Flex overflowY={'auto'} flex={'1'} id="app-container" bg={mode('light', 'black')}>
              <Box flex="1">
                <Box h={'full'} overflow="auto">
                  <Outlet />
                </Box>
              </Box>
            </Flex>
            {isAuthenticated && <BrowserPermissionBanner></BrowserPermissionBanner>}
            {isAuthenticated && <WorkspaceInvitationsReceived />}
          </Box>
        </>
      ) : (
        <>
          {/* Mobile Version Layout structure */}
          <Flex w={'100vw'} direction="column" pb="4.5rem" overflow="hidden" {...safeAreaInsets}>
            {mainHeader()}
            <Flex flex="1" overflow="hidden" w={'full'} h={'full'} flexDirection={'column'}>
              <Flex flex="1" fontSize="sm" overflow="auto" w={'full'} h={'max-content'} mb="12">
                <Outlet />
              </Flex>
              {isAuthenticated && <WorkspaceInvitationsReceived />}
              {isAuthenticated && <Footer.Mobile />}
            </Flex>
          </Flex>
        </>
      )}
    </LoadingProgressProvider>
  )
}

export const MainContainer = React.memo(UnmemoizedMainContainer)

const useMobileViewportHeight = () => {
  useEffect(() => {
    const setFullHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window.addEventListener('resize', setFullHeight)
    setFullHeight()

    return () => window.removeEventListener('resize', setFullHeight)
  }, [])
}
