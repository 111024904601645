import { useMutation } from '@apollo/client'
import { Button, FormWarning, Radio, RadioGroup, VStack } from '@chaine/keychaine'
import React from 'react'

import { Toast } from '../toast'
import { SEND_VERIFICATION_CODE, SendVerificationCodeInput } from './data/mutations'
import { ErrorModal } from './error-modal'
import { ModalStepProps, ReceiveCodeMethodType, VerificationMethodType } from './types'
import { Wrapper } from './wrapper'

interface ReceiveCodeMethodProps extends ModalStepProps {
  /**
   * How the verification code will be sent to the user
   */
  receiveCodeMethod: ReceiveCodeMethodType
  /**
   * Sets the verification strategy (SMS or phone call)
   */
  setReceiveCodeMethod: (strategy: ReceiveCodeMethodType) => void

  /**
   * The preferred method for completing verification
   */
  verificationMethodType: VerificationMethodType | null

  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * If the user is verifying with a phone number, they can choose whether to receive the OTP via a text or phone call
 */
const UnmemoizedReceiveCodeMethod = (props: ReceiveCodeMethodProps) => {
  const {
    step,
    previousStepOrSkipStep,
    setReceiveCodeMethod,
    receiveCodeMethod,
    handleNext,
    verificationMethodType,
    workspaceID
  } = props
  const { showToast } = Toast()

  // sending code mutation
  const [sendVerificationCode, { loading, error }] = useMutation<
    { sendVerificationCode: { id: string } },
    SendVerificationCodeInput
  >(SEND_VERIFICATION_CODE, {
    onCompleted: () => {
      handleNext()
    },
    onError: (err) => {
      showToast({
        status: 'error',
        title: err.message
      })
    }
  })

  if (!verificationMethodType)
    return <ErrorModal step={step} previousStepOrSkipStep={previousStepOrSkipStep} goToStep={5} />

  const handleSubmit = async () => {
    const input = {
      method: receiveCodeMethod,
      type: verificationMethodType
    }
    await sendVerificationCode({
      context: {
        headers: {
          'ws-id': workspaceID || ''
        }
      },
      variables: input
    })
  }

  return (
    <>
      <Wrapper
        title="How do you want to receive the code?"
        activeStep={step}
        backButtonProps={{ displayBackArrow: true, onSubmit: () => previousStepOrSkipStep(5), text: 'Go back' }}
      >
        <RadioGroup value={receiveCodeMethod} onChange={setReceiveCodeMethod}>
          <VStack width="100%" alignItems="flex-start">
            <Radio value={ReceiveCodeMethodType.SMS}>Text message</Radio>
            <Radio value={ReceiveCodeMethodType.PhoneCall}>Phone call</Radio>
          </VStack>
        </RadioGroup>
        {error?.message && <FormWarning>{error?.message}</FormWarning>}
        <Button width="100%" onClick={handleSubmit} mt={4} variant="primary" isLoading={loading}>
          Next
        </Button>
      </Wrapper>
    </>
  )
}

export const ReceiveCodeMethodForPhone = React.memo(UnmemoizedReceiveCodeMethod)
