import { buttonBaseStyle, buttonDangerStyle, buttonOutlineStyle, buttonPrimaryStyle, buttonSecondaryStyle, buttonSideBarStyle } from '../../form/button';
export const Button = {
    sizes: {
        sm: {
            fontSize: 'xs',
            h: 7,
            px: 3,
            py: '0px'
        },
        md: {
            fontSize: 'sm',
            h: 9,
            px: 3.5
        },
        lg: {
            fontSize: 'md',
            px: 4
        }
    },
    variants: {
        primary: buttonPrimaryStyle,
        secondary: buttonSecondaryStyle,
        base: buttonBaseStyle,
        outline: buttonOutlineStyle,
        sideBar: buttonSideBarStyle,
        danger: buttonDangerStyle
    },
    defaultProps: {
        variant: 'outline',
        size: 'md'
    }
};
