import { defaultColors as tokenColors } from '../../theme/foundations';
export const apple = [
    tokenColors.blue[500],
    tokenColors.blue[400],
    tokenColors.red[400],
    tokenColors.red[500],
    tokenColors.orange[500],
    tokenColors.yellow[400],
    tokenColors.yellow[500],
    tokenColors.teal[400],
    tokenColors.teal[500],
    tokenColors.purple[400],
    tokenColors.purple[500],
    tokenColors.purple[400],
    tokenColors.pink[400],
    tokenColors.pink[500]
];
export const sprinkles = [
    tokenColors.blue[700],
    tokenColors.blue[500],
    tokenColors.blue[400],
    tokenColors.blue[500],
    tokenColors.blue[400],
    tokenColors.blue[500],
    tokenColors.blue[400],
    tokenColors.red[400],
    tokenColors.red[400],
    tokenColors.red[400],
    tokenColors.red[500],
    tokenColors.red[500],
    tokenColors.red[500],
    tokenColors.orange[400],
    tokenColors.orange[500],
    tokenColors.orange[400],
    tokenColors.yellow[400],
    tokenColors.yellow[500],
    tokenColors.yellow[400],
    tokenColors.yellow[500],
    tokenColors.teal[700],
    tokenColors.teal[400],
    tokenColors.teal[500],
    tokenColors.teal[400],
    tokenColors.teal[500],
    tokenColors.purple[400],
    tokenColors.purple[500],
    tokenColors.purple[400],
    tokenColors.purple[500],
    tokenColors.green[400],
    tokenColors.green[500],
    tokenColors.green[400],
    tokenColors.green[500],
    tokenColors.pink[400],
    tokenColors.pink[500],
    tokenColors.pink[400],
    tokenColors.pink[500]
];
export const gold = [
    tokenColors.yellow[900],
    tokenColors.yellow[800],
    tokenColors.yellow[700],
    tokenColors.yellow[600],
    tokenColors.yellow[500],
    tokenColors.yellow[500],
    tokenColors.yellow[500],
    tokenColors.yellow[400],
    tokenColors.yellow[400],
    tokenColors.yellow[400],
    tokenColors.yellow[300],
    tokenColors.yellow[200],
    tokenColors.yellow[100]
];
export const variants = {
    apple: {
        color1: apple,
        color2: apple,
        color3: apple
    },
    sprinkles: {
        color1: sprinkles,
        color2: sprinkles,
        color3: sprinkles
    },
    gold: {
        color1: gold,
        color2: gold,
        color3: gold
    },
    swirl: {
        color1: sprinkles,
        color2: apple,
        color3: sprinkles
    }
};
