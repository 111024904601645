import { wrapUseRoutes } from '@sentry/react'
import React from 'react'
import { useRoutes } from 'react-router-dom'

import { MainContainer } from '../../_shared/layouts/application/main-container'
import { RequireAuth } from '../hoc/protected-route'
import { myAccountSettingsRoutes, workspaceAccountSettingsRoutes } from './account-settings'
import { bookingRoutes } from './booking'
import { inboxRoutes } from './inbox'
import { onboardingRoutes } from './onboarding'
import { publicRoutes } from './public'
import { shipmentRoutes } from './shipments'
import { websiteRoutes } from './website'

const useSentryRoutes = wrapUseRoutes(useRoutes)

// To display different pages to the user based on whether they are accessing the application through a web browser or through a standalone app
const mainRoutes = () => {
  return [
    {
      children: [
        //---------------- Booking Pages ----------------//
        ...bookingRoutes(),

        //---------------- Inbox Pages ----------------//
        ...inboxRoutes(),

        //---------------- Settings Pages ----------------//
        ...myAccountSettingsRoutes(),
        ...workspaceAccountSettingsRoutes(),

        //---------------- Shipment Pages ----------------//
        ...shipmentRoutes()
      ],
      element: (
        <RequireAuth>
          <MainContainer />
        </RequireAuth>
      )
    }
  ]
}

// To display different public pages to the user based on whether they are accessing the application through a web browser or through a standalone app
const publicMainRoutes = () => {
  return [
    {
      children: [
        //---------------- Public Pages ----------------//
        ...publicRoutes()
      ],
      element: <MainContainer />
    }
  ]
}

export const useRenderRoutes = (
  setConfetti: React.Dispatch<React.SetStateAction<boolean>>,
  toggleColorMode: () => void
) =>
  useSentryRoutes([
    //---------------- Website Pages ----------------//
    ...websiteRoutes(toggleColorMode),
    //---------------- Authentication Pages ----------------//
    ...onboardingRoutes(setConfetti),
    //---------------- Main Application Pages ----------------//
    ...mainRoutes(),
    //---------------- Public Main Application Pages ----------------//
    ...publicMainRoutes()
  ])
