import { Avatar, Button, FormLabel, HStack, Radio, RadioGroup, Text, VStack } from '@chaine/keychaine'
import React from 'react'

import { CompanyIdentifierType, ModalStepProps, Variant, WorkspaceProps } from './types'
import { Wrapper } from './wrapper'

interface MCOrDOTTypeProps extends ModalStepProps {
  /**
   * The type of identifier the company wants to use to verify (MC or DOT)
   */
  companyIdentifierType: CompanyIdentifierType

  /**
   * Sets the company identifier type
   */
  setCompanyIdentifierType: (companyIdentifierType: CompanyIdentifierType) => void

  /**
   * The {@link WorkspaceProps details} of the workspace to be verified
   */
  workspace: WorkspaceProps | null
}

/**
 * The user chooses how they want to be verified: either with an MC or DOT number
 */
const UnmemoizedMCOrDOTType = (props: MCOrDOTTypeProps) => {
  const { step, companyIdentifierType, setCompanyIdentifierType, handleNext, workspace, variant } = props

  const title = workspace ? (
    <HStack alignItems="center">
      <Text>{`Let's get`}</Text>
      <Avatar src={workspace.logo || ''} name={workspace?.displayName || ''} size="xs" variant="square" />
      <Text>{workspace?.displayName} verified!</Text>
    </HStack>
  ) : variant === Variant.VerifyWorkspace ? (
    'MC or DOT?'
  ) : (
    "Let's get your company verified!"
  )

  return (
    <>
      <Wrapper title={title} subtitle={'Pick how you want to get verified.'} activeStep={step} backButtonProps={null}>
        <FormLabel mt={4} htmlFor="dotOrMC">
          Select one
        </FormLabel>
        <RadioGroup value={companyIdentifierType} onChange={setCompanyIdentifierType} id="dotOrMC" name="dotOrMC">
          <VStack width="100%" alignItems="flex-start">
            <Radio value={CompanyIdentifierType.DOT}>DOT#</Radio>
            <Radio value={CompanyIdentifierType.MC}>MC#</Radio>
          </VStack>
        </RadioGroup>
        <Button width="100%" onClick={handleNext} mt={4} variant="primary">
          Next
        </Button>
      </Wrapper>
    </>
  )
}

export const MCOrDOTType = React.memo(UnmemoizedMCOrDOTType)
