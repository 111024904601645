import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '../../auth/data/use-auth'
import { ROUTES } from '../routes-list'

/**
 *
 * RequireAuth - use this function to wrap private routes
 * @returns children if authenticated, login page if not authenticated
 */
/**
 *
 * @TODO implement logic in use auth to remember the location a user was trying to navigate to
 */
export function RequireAuthWebsite({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const getIsAuthenticated = () => isAuthenticated && location.pathname === '/'

  return !getIsAuthenticated() ? (
    children
  ) : (
    <Navigate to={ROUTES.ASSIGNED_TO_ME} replace state={{ path: location.pathname, search: location.search }} />
  )
}
