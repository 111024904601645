import { Button } from '@chaine/keychaine'
import React from 'react'

import { ModalStepProps } from './types'
import { Wrapper } from './wrapper'

/**
 * Error modal used when the previous step didn't return what was expected. Typically due to a server error.
 * @param props {@link ModalStepProps}
 * @returns
 */
const UnmemoizedErrorModal = (props: Omit<ModalStepProps, 'handleNext'> & { goToStep: number }) => {
  const { step, previousStepOrSkipStep, goToStep } = props
  return (
    <Wrapper
      title={'Something went wrong'}
      subtitle={'Please try again later.'}
      activeStep={step}
      backButtonProps={null}
    >
      <Button width="100%" onClick={() => previousStepOrSkipStep(goToStep)} mt={4} variant="secondary">
        Back
      </Button>
    </Wrapper>
  )
}

export const ErrorModal = React.memo(UnmemoizedErrorModal)
