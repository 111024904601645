import { getColor, mode, transparentize } from '../..';
export function getBg(props) {
    const { theme, colorScheme: c } = props;
    const lightBg = getColor(theme, `${c}.100`, c);
    const darkBg = transparentize(`${c}.200`, 0.16)(theme);
    return mode(lightBg, darkBg)(props);
}
export const alertBaseStyle = (props) => {
    return {
        container: {
            px: 4,
            py: 4,
            bg: mode(`blue.50`, 'rgba(0, 76, 159, 0.2)')(props),
            borderRadius: props?.borderRadius || 'md',
            width: props?.width || 'fit-content',
            position: props?.position || 'absolute',
            border: mode('1px solid #99CAFF', '1px solid rgba(51, 148, 255, 0.5)')(props),
            boxSizing: 'border-box',
            color: mode(`blue.600`, 'rgba(51, 148, 255, 0.8)')(props)
        },
        title: {
            fontWeight: 'bold',
            lineHeight: 'taller',
            marginEnd: 2
        },
        description: {
            lineHeight: 'taller',
            color: mode(`text.primary`, 'text.tertiary')(props),
            fontSize: props?.fontSize || 'sm'
        },
        icon: {
            flexShrink: 0,
            marginEnd: 3,
            w: 5,
            h: 6
        }
    };
};
export const alertWarningStyle = (props) => {
    return {
        container: {
            px: 4,
            py: 4,
            bg: mode(`yellow.50`, 'rgba(159, 133, 0, 0.2)')(props),
            borderRadius: props?.borderRadius || 'md',
            width: props?.width || 'fit-content',
            position: props?.position || 'absolute',
            border: mode('1px solid #FFABA6', '1px solid rgba(255, 217, 51, 0.5)')(props),
            boxSizing: 'border-box',
            color: mode(`yellow.600`, 'rgba(255, 217, 51, 0.8)')(props)
        },
        title: {
            fontWeight: 'bold',
            lineHeight: 'taller',
            marginEnd: 2
        },
        description: {
            lineHeight: 'taller',
            color: mode(`text.primary`, 'text.tertiary')(props),
            fontSize: props?.fontSize || 'sm'
        },
        icon: {
            flexShrink: 0,
            marginEnd: 3,
            w: 5,
            h: 6
        }
    };
};
export const alertSuccessStyle = (props) => {
    return {
        container: {
            px: 4,
            py: 4,
            bg: mode(`green.50`, 'rgba(28, 129, 53, 0.2)')(props),
            borderRadius: props?.borderRadius || 'md',
            width: props?.width || 'fit-content',
            position: props?.position || 'absolute',
            border: mode('1px solid #A9EDBA', '1px solid rgba(90, 213, 121, 0.5)')(props),
            boxSizing: 'border-box',
            color: mode(`green.600`, 'rgba(90, 213, 121, 0.8)')(props)
        },
        title: {
            fontWeight: 'bold',
            lineHeight: 'taller',
            marginEnd: 2
        },
        description: {
            lineHeight: 'taller',
            color: mode(`text.primary`, 'text.tertiary')(props),
            fontSize: props?.fontSize || 'sm'
        },
        icon: {
            flexShrink: 0,
            marginEnd: 3,
            w: 5,
            h: 6
        }
    };
};
export const alertErrorStyle = (props) => {
    return {
        container: {
            px: 4,
            py: 4,
            bg: mode(`red.50`, 'rgba(159, 31, 23, 0.2)')(props),
            borderRadius: props?.borderRadius || 'md',
            width: props?.width || 'fit-content',
            position: props?.position || 'absolute',
            border: mode('1px solid #FFABA6', '1px solid rgba(255, 95, 85, 0.5)')(props),
            boxSizing: 'border-box',
            color: mode(`red.600`, 'rgba(255, 95, 85, 0.8)')(props)
        },
        title: {
            fontWeight: 'bold',
            lineHeight: 'taller',
            marginEnd: 2
        },
        description: {
            lineHeight: 'taller',
            color: mode(`text.primary`, 'text.tertiary')(props),
            fontSize: props?.fontSize || 'sm'
        },
        icon: {
            flexShrink: 0,
            marginEnd: 3,
            w: 5,
            h: 6
        }
    };
};
