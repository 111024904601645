import { useColorModeValue as mode } from '../..';
export const formLabelBaseStyle = () => {
    return {
        fontSize: 'xs',
        color: mode('text.secondary', 'text.tertiary'),
        mt: 6,
        marginEnd: 3,
        mb: 2,
        fontWeight: 'medium',
        transitionProperty: 'common',
        transitionDuration: 'normal',
        opacity: 1,
        _disabled: {
            opacity: 0.4
        }
    };
};
