export { Kbd as KBD } from '@chakra-ui/react';
export const kbdBaseStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'whiteAlpha' : 'gray.100',
        borderRadius: 'md',
        borderWidth: '1px',
        borderBottomWidth: '3px',
        fontSize: '0.8em',
        fontWeight: 'bold',
        lineHeight: 'normal',
        px: '0.4em',
        whiteSpace: 'nowrap'
    };
};
export const kbdPrimaryStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'whiteAlpha' : 'gray.50',
        color: colorMode === 'dark' ? 'text.tertiary' : 'text.primary',
        bgGradient: colorMode === 'dark' ? 'none' : 'linear(#FFFFFF, #FAFAFC)',
        borderRadius: 'base',
        borderWidth: '1px',
        borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.300',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        fontSize: '0.8em',
        fontWeight: 'normal',
        lineHeight: 'taller',
        px: '0.4em',
        pb: '0.1em',
        whiteSpace: 'nowrap',
        boxShadow: colorMode === 'dark' ? 'inset 0px -1.7px 0.5px #535159' : 'inset 0px -1.7px 0.5px #807E83'
    };
};
