import config from '../_server/config'

interface INotification<T> {
  body?: string
  data?: T & { messageID: string }
  notificationHandler?: () => void
  title: string
}

export interface IBrowserNotificationService {
  /**
   * Generates a browser notification based on the provided event
   *
   * @param notification
   */
  create<T>(notification: INotification<T>): void
}

/**
 * Handles the creation and display of browser notifications
 */
export class BrowserNotificationService implements IBrowserNotificationService {
  notificationSound: HTMLAudioElement

  constructor() {
    this.notificationSound = new Audio(config.app.BROWSER_NOTIFICATION_SOUND)
  }

  create<T>(notification: INotification<T>): void {
    const { title, body, data, notificationHandler } = notification

    // Checking if the Notification object is supported
    if ('Notification' in window && window.Notification && Notification.permission === 'granted') {
      // Show the notification
      const newNotification = new Notification(title, {
        body: body || '',
        data: data,
        icon: config.app.BROWSER_NOTIFICATION_ICON || '',
        tag: data?.messageID || ''
      })

      if (notificationHandler) {
        newNotification.onclick = () => {
          window.focus()
          notificationHandler()
          newNotification.close()
        }
      }

      // Play the sound
      if (this.notificationSound && new AudioContext().state === 'running') {
        this.notificationSound.play()
      }
    } else {
      console.error('Browser notifications not supported')

      // Older versions of browsers may lack proper support for the Notification API, especially Internet Explorer or outdated versions of Safari.
      // Notification support on Safari for iOS devices may be more restrictive due to Apple's privacy measures.
      // Users need to grant explicit permission, and even then, notifications might have limitations.
    }
  }
}
