import { Box, Button, ButtonGroup, Container, Divider, IconButton, SimpleGrid, Stack, Text } from '@chaine/keychaine';
import { FaGithub } from '@react-icons/all-files/fa/FaGithub';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { FOOTER_ROUTES } from '../_routes';
import { LogoText as Logo } from '../_shared/logo';
export const Footer = () => {
    return (React.createElement(Box, { bg: "bg-surface" },
        React.createElement(Container, { as: "footer", role: "contentinfo", px: 16 },
            React.createElement(Stack, { justify: "space-between", direction: { base: 'column', lg: 'column', xl: 'row' }, py: { base: '12', md: '16' }, spacing: "8" },
                React.createElement(Stack, { spacing: { base: '6', md: '8' }, align: "start" },
                    React.createElement(NavLink, { to: '/' },
                        React.createElement(Logo, null)),
                    React.createElement(Text, { color: "muted" }, "AI-powered freight booking and visibility.")),
                React.createElement(SimpleGrid, { columns: { base: 2, md: 4 }, gap: "8" }, FOOTER_ROUTES.map((group, idx) => (React.createElement(Stack, { key: idx, spacing: "4", minW: { lg: '40' } },
                    React.createElement(Text, { fontSize: "sm", fontWeight: "bold", color: "subtle" }, group.title),
                    React.createElement(Stack, { spacing: "3", shouldWrapChildren: true }, group.links.map((link, i) => link.isExternalLink ? (React.createElement(Button, { key: `${i}-navLink-button`, as: "a", variant: "link", href: link.href, target: link.target || undefined }, link.label)) : (React.createElement(Button, { key: `${i}-navLink-button`, variant: "link" },
                        React.createElement(NavLink, { key: `${i}-navLink`, to: link.href || '/' }, link.label)))))))))),
            React.createElement(Divider, null),
            React.createElement(Stack, { pt: "8", pb: "12", justify: "space-between", direction: { base: 'column-reverse', md: 'row' }, align: "center" },
                React.createElement(Text, { fontSize: "sm", color: "subtle" },
                    "Copyright \u00A9 ",
                    new Date().getFullYear(),
                    ", Chaine, Inc. All rights reserved."),
                React.createElement(ButtonGroup, { variant: "ghost" },
                    React.createElement(IconButton, { as: "a", href: "https://www.linkedin.com/company/chaineapp", target: "_blank", "aria-label": "LinkedIn", icon: React.createElement(FaLinkedin, { fontSize: "1.25rem" }) }),
                    React.createElement(IconButton, { as: "a", href: "https://github.com/chaineapp", target: "_blank", "aria-label": "GitHub", icon: React.createElement(FaGithub, { fontSize: "1.25rem" }) }),
                    React.createElement(IconButton, { as: "a", href: "https://twitter.com/chaineapp", target: "_blank", "aria-label": "Twitter", icon: React.createElement(FaTwitter, { fontSize: "1.25rem" }) }))))));
};
