export { Text } from '@chakra-ui/react';
import { useColorModeValue as mode } from '..';
export const bodyText = () => ({
    color: mode('text.primary', 'text.secondary')
});
export const grayDark = () => ({
    color: mode('gray.900', 'gray.200')
});
export const gray = () => ({
    color: mode('gray.700', 'gray.400')
});
export const inactive = () => ({
    color: mode('gray.500', 'gray.500')
});
export const blue = () => ({
    color: mode('blue.600', 'blue.700')
});
export const red = () => ({
    color: mode('red.800', 'red.900')
});
export const white = () => ({
    color: mode('white', 'gray.50')
});
