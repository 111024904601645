import { createIcon, IconButton } from '@chakra-ui/react';
import React from 'react';
export { createIcon };
export const MenuIcon = createIcon({
    displayName: 'MenuHamburgerIcon',
    viewBox: '0 0 32 32',
    path: (React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9 10.75C9 10.5511 9.07902 10.3603 9.21967 10.2197C9.36032 10.079 9.55109 10 9.75 10H22.25C22.4489 10 22.6397 10.079 22.7803 10.2197C22.921 10.3603 23 10.5511 23 10.75C23 10.9489 22.921 11.1397 22.7803 11.2803C22.6397 11.421 22.4489 11.5 22.25 11.5H9.75C9.55109 11.5 9.36032 11.421 9.21967 11.2803C9.07902 11.1397 9 10.9489 9 10.75ZM9 15.75C9 15.5511 9.07902 15.3603 9.21967 15.2197C9.36032 15.079 9.55109 15 9.75 15H22.25C22.4489 15 22.6397 15.079 22.7803 15.2197C22.921 15.3603 23 15.5511 23 15.75C23 15.9489 22.921 16.1397 22.7803 16.2803C22.6397 16.421 22.4489 16.5 22.25 16.5H9.75C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75ZM9.75 20C9.55109 20 9.36032 20.079 9.21967 20.2197C9.07902 20.3603 9 20.5511 9 20.75C9 20.9489 9.07902 21.1397 9.21967 21.2803C9.36032 21.421 9.55109 21.5 9.75 21.5H22.25C22.4489 21.5 22.6397 21.421 22.7803 21.2803C22.921 21.1397 23 20.9489 23 20.75C23 20.5511 22.921 20.3603 22.7803 20.2197C22.6397 20.079 22.4489 20 22.25 20H9.75Z", fill: "currentColor" }))
});
export const MenuIconExpanded = createIcon({
    displayName: 'MenuIconExpanded',
    viewBox: '0 0 32 32',
    path: (React.createElement("path", { fill: "currentColor", d: "M12.7244 10.2197C12.5837 10.3603 12.5047 10.5511 12.5047 10.75C12.5047 10.9489 12.5837 11.1397 12.7244 11.2803C12.865 11.421 13.0558 11.5 13.2547 11.5H25.7547C25.9536 11.5 26.1444 11.421 26.285 11.2803C26.4257 11.1397 26.5047 10.9489 26.5047 10.75C26.5047 10.5511 26.4257 10.3603 26.285 10.2197C26.1444 10.079 25.9536 10 25.7547 10H13.2547C13.0558 10 12.865 10.079 12.7244 10.2197ZM12.7244 15.2197C12.5837 15.3603 12.5047 15.5511 12.5047 15.75C12.5047 15.9489 12.5837 16.1397 12.7244 16.2803C12.865 16.421 13.0558 16.5 13.2547 16.5H25.7547C25.9536 16.5 26.1444 16.421 26.285 16.2803C26.4257 16.1397 26.5047 15.9489 26.5047 15.75C26.5047 15.5511 26.4257 15.3603 26.285 15.2197C26.1444 15.079 25.9536 15 25.7547 15H13.2547C13.0558 15 12.865 15.079 12.7244 15.2197ZM12.7244 20.2197C12.865 20.079 13.0558 20 13.2547 20H25.7547C25.9536 20 26.1444 20.079 26.285 20.2197C26.4257 20.3603 26.5047 20.5511 26.5047 20.75C26.5047 20.9489 26.4257 21.1397 26.285 21.2803C26.1444 21.421 25.9536 21.5 25.7547 21.5H13.2547C13.0558 21.5 12.865 21.421 12.7244 21.2803C12.5837 21.1397 12.5047 20.9489 12.5047 20.75C12.5047 20.5511 12.5837 20.3603 12.7244 20.2197ZM9.43565 13.4481C9.68314 13.2075 9.68872 12.8118 9.4481 12.5643C9.20748 12.3168 8.8118 12.3113 8.5643 12.5519L5.76018 15.2781C5.39179 15.6363 5.40982 16.2333 5.79916 16.5685L8.59215 18.9736C8.85372 19.1988 9.24835 19.1694 9.47358 18.9078C9.69882 18.6463 9.66937 18.2516 9.40781 18.0264L6.92533 15.8887L9.43565 13.4481Z" }))
});
export const MenuIconButton = (props) => {
    return React.createElement(IconButton, { ...props, icon: React.createElement(MenuIcon, { boxSize: 8 }) });
};
export const MenuIconExpandedButton = (props) => {
    return React.createElement(IconButton, { ...props, icon: React.createElement(MenuIconExpanded, { boxSize: 8 }) });
};
