import React, { memo, useEffect, useRef } from 'react';
import { Box, keychaine } from '../..';
const ToggleComponent = memo(({ switchConfig, checked: defaultChecked, checked, onChange, ...rest }) => {
    const { darkIcon, darkIconStyle, lightIcon, lightIconStyle } = switchConfig;
    const inputRef = useRef(null);
    const colorMode = checked ? 'dark' : 'light';
    return (React.createElement(Box, { role: "group", p: rest.p || 1, ...rest },
        React.createElement(Box, { position: "relative", cursor: "pointer", userSelect: "none", _hover: {
                boxShadow: '0px 0px 2px 3px var(--ifm-color-primary)'
            }, _focus: {
                boxShadow: '0px 0px 2px 3px var(--ifm-color-primary)'
            } },
            React.createElement(Box, { position: "relative", w: "50px", height: "24px", borderRadius: "30px", backgroundColor: "#4d4d4d", transition: "all 0.2s ease", role: "button", tabIndex: -1, onClick: () => inputRef.current?.click() },
                React.createElement(Box, { position: "absolute", width: "14px", height: "10px", top: "0px", bottom: "0px", margin: "auto 0", left: "8px", opacity: colorMode === 'dark' ? 1 : 0, transition: colorMode === 'dark' ? 'opacity 0.25s ease' : 'opacity 0.25s ease' },
                    React.createElement(keychaine.span, { alignItems: "center", display: "flex", height: "10px", justifyContent: "center", width: "10px", style: darkIconStyle }, darkIcon)),
                React.createElement(Box, { position: "absolute", width: "10px", height: "10px", top: "0px", bottom: "0px", margin: "auto 0", right: "10px", opacity: colorMode === 'dark' ? 0 : 1, transition: "opacity 0.25s ease" },
                    React.createElement(keychaine.span, { alignItems: "center", display: "flex", height: "10px", justifyContent: "center", width: "10px", style: lightIconStyle }, lightIcon)),
                React.createElement(keychaine.div, { position: "absolute", top: "1px", left: colorMode === 'dark' ? '27px' : '1px', width: "22px", height: "22px", border: "1px solid #4d4d4d", borderRadius: "50%", backgroundColor: "#fafafa", transition: "all 0.25s ease", boxShadow: "0px 0px 2px 3px var(--ifm-color-primary)", _groupHover: {
                        boxShadow: '0px 0px 2px 3px #007AFF'
                    }, _groupFocus: {
                        boxShadow: '0px 0px 2px 3px #007AFF'
                    }, _groupActive: {
                        boxShadow: '0px 0px 5px 5px #007AFF'
                    } })),
            React.createElement(keychaine.input, { ref: inputRef, checked: checked, type: "checkbox", border: 0, css: { clip: 'rect(0 0 0 0)' }, height: "1px", margin: "-1px", overflow: "hidden", position: "absolute", width: "1px", "aria-label": "Switch between dark and light mode", onChange: onChange, onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        inputRef.current?.click();
                    }
                } }))));
});
export function ColorModeToggle(props) {
    const { mode } = props;
    const isDark = mode === 'dark';
    const switchConfig = {
        darkIcon: '🌜',
        darkIconStyle: {},
        lightIcon: '🌞',
        lightIconStyle: {}
    };
    useEffect(() => { });
    return (React.createElement(ToggleComponent, { switchConfig: switchConfig, checked: isDark, onChange: () => props.updateColorModePreference?.(), disabled: false }));
}
ToggleComponent.displayName = 'ToggleComponent';
export { ToggleComponent };
