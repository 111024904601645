import { Box, Flex, useBreakpointValue } from '@chaine/keychaine'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { useAuth } from '../auth/data/use-auth'
import { SideMenu } from './components/side-menu'

export const BookingPageLayout = () => {
  const { isAuthenticated, userDetails } = useAuth()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false })

  return (
    <Flex h="full" w="full" justifyContent="center">
      {isAuthenticated && !isMobile && userDetails?.status === 'Active' && <SideMenu />}
      <Box w="full" h="full" overflow="auto">
        <Flex justifyContent="center" alignContent="center" h="full" w="full" px={{ base: 4, lg: 6 }}>
          <Box h="full" w="full" pt={{ base: 3, lg: 6 }}>
            <Outlet />
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}
